import React from 'react'




function DescriptionChip({ title, description }) {




    return (
        <>
            <div className='flex-col-0'>
                <span className='zp-weight-l' style={{ color: 'rgb(54, 63, 73)', fontSize: '18px', lineHeight: 1.6, paddingLeft: '10px', borderLeft: '2px solid var(--brand-color-1)' }}>
                    {title}
                </span>
                <span style={{ color: 'rgb(54, 63, 73)', fontSize: '16px', lineHeight: 1.6, paddingLeft: '12px' }}>
                    {description}
                </span>
            </div>
        </>
    )
}

export default DescriptionChip