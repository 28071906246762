import { StateDropdownMenu } from 'index';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function GenericBusinessDetails({ label }) {
    const [focusStates, setFocusStates] = useState({});
    const [formValues, setFormValues] = useState({
        0: 'Unit 2',
        1: '2-4 Glen Street',
        2: 'Marrickville',
        3: '2204'
    });
    
    const [warnings, setWarnings] = useState([]);
    const [errors, setErrors] = useState({}); // State to track errors for each field
    const [showWarn, setShowWarn] = useState(false);

    useEffect(() => {
        // console.log(warnings);
    }, [warnings]);

    const InputLabels = ['Address Line 1', 'Address Line 2', 'City', 'Postal Code (####)'];

    const InputStyles = (isFocused, hasError) => ({
        border: hasError ? '1px solid red' : isFocused ? '1px solid var(--brand-color-1)' : '1px solid #ccc',
        padding: '8px 12px',
        outline: 'none',
        transition: 'border 0.15s ease-in',
        borderRadius: '4px',
        width: '100%',
    });

    const LabelInputStyle = {
        fontSize: '14px',
        fontFamily: 'var(--font-family-1)',
        fontWeight: 500,
        marginBottom: '5px',
    };

    const WarningStyle = {
        color: 'red',
        padding: '5px 0px',
        fontSize: '14px',
        fontFamily: 'var(--font-family-1)',
    };

    const handleFocus = (index, isFocused) => {
        setFocusStates((prev) => ({
            ...prev,
            [index]: isFocused,
        }));
    };

    const handleChange = (index, value) => {
        setFormValues((prev) => ({
            ...prev,
            [index]: value,
        }));
    };

    useEffect(() => {
        // console.log(formValues);
        validateForm();
    }, [formValues]);

    const validateForm = () => {
        const newWarnings = [];
        const newErrors = {}; // Track errors for specific fields
        const fieldValues = Object.values(formValues);

        InputLabels.forEach((label, index) => {
            if (!fieldValues[index]?.trim()) {
                newWarnings.push(`${label} is required.`);
                newErrors[index] = true; // Mark this field as having an error
            }
        });

        const postalCode = formValues[3]?.trim();
        if (postalCode && (!/^\d+$/.test(postalCode) || parseInt(postalCode, 10) < 1000 || parseInt(postalCode, 10) > 9999)) {
            newWarnings.push('Postal Code must be a numeric value of exactly 4 digits.');
            newErrors[3] = true; // Mark the postal code field as having an error
        }

        setWarnings(newWarnings);
        setErrors(newErrors); // Update the errors state
        setShowWarn(newWarnings.length > 0);
    };

    return (
        <>
            <div style={{ marginBottom: showWarn ? '0px' : '29px', maxWidth: '400px' }}>
                <div style={LabelInputStyle}>{label}</div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {[...Array(2)].map((_, index) => (
                        <input
                            key={index}
                            defaultValue={formValues[index]}
                            style={InputStyles(focusStates[index], errors[index])}
                            onFocus={() => handleFocus(index, true)}
                            onBlur={() => handleFocus(index, false)}
                            onChange={(e) => handleChange(index, e.target.value)}
                            placeholder={InputLabels[index]}
                        />
                    ))}
                    <StateDropdownMenu />
                    {[...Array(2)].map((_, index) => (
                        <input
                            key={index + 2}
                            defaultValue={formValues[index+2]}
                            style={InputStyles(focusStates[index + 2], errors[index + 2])}
                            onFocus={() => handleFocus(index + 2, true)}
                            onBlur={() => handleFocus(index + 2, false)}
                            onChange={(e) => handleChange(index + 2, e.target.value)}
                            placeholder={InputLabels[index + 2]}
                        />
                    ))}
                </div>
                {showWarn && warnings.length > 0 && (
                    <div style={WarningStyle}>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{ backgroundColor: 'white' }} />{' '}
                        {warnings[0]}
                    </div>
                )}
            </div>
        </>
    );
}

export default GenericBusinessDetails;
