import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
//import './GenericCheckbox.css';

const GenericCheckbox = ({ label, ...props }) => {
  return (
    <div>
      <input type="checkbox" className="checkbox-input" {...props} id={props.id || label} />
      <label className="checkbox-input-label" htmlFor={props.id || label}>
        <span className="checkbox-icon" {...props}>
          <FontAwesomeIcon icon={faCheck} style={{ padding: 0, height: '10px', width: '10px', color: 'white' }} />
        </span>
        {label}
      </label>
    </div>
  );
};

export default GenericCheckbox;
