import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const NumberInput = ({ Quantity, handleQuantityChange, decrementQuantity, incrementQuantity, width, height }) => {

  const buttonStyle = {
    backgroundColor: "white",
    color: "#6C7688",
    border: "1px solid #ccc",
    borderRadius: "4px",
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    padding: "0px",
    height: height,
    width: width,
    cursor: "pointer",
    textAlign: "center"
  };

  return (
    <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100%' }}>
      <style>
        {`
          input[type=number]::-webkit-outer-spin-button,
          input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        
          input[type=number] {
            -moz-appearance: textfield;
          }
        `}
      </style>
      
      <button 
        onClick={decrementQuantity} 
        style={{ 
          ...buttonStyle, 
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0
        }}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
     
      <input
        type="number"
        value={Quantity}
        onChange={handleQuantityChange}
        style={{
          color: '#353A44',
          textAlign: 'center',
          width: width,
          height: height,
          fontSize: '12px',
          padding: '0px',
          border: '1px solid #ccc',
          outline: 'none',
          borderLeft: 'none',
          borderRight: 'none',
        }}
      />

      <button 
        onClick={incrementQuantity} 
        style={{ 
          ...buttonStyle, 
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0
        }}
      >
        <FontAwesomeIcon icon={faPlus}  />
      </button>
    </div>
  );
};

export default NumberInput;
