import { createContext, useContext, useState, useEffect, useMemo, useRef } from "react";
import { useAuth } from 'index'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useCookies } from 'react-cookie';
import { AlertProvider, AlertContext, noContent } from 'index.js'

export const FormDataContext = createContext(null);
export const StripeContext = createContext(null);
export const AutocompleteContext = createContext(null);
export const PartImageContext = createContext(null);
export const CartDataContext = createContext(null);

const apiUrl = process.env.REACT_APP_API_URL;
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);

export const FormDataProvider = ({ children }) => {
    const { user } = useAuth()

    const [shippingMethod, setShippingMethod] = useState('Express Shipping')

    const [formData, setFormData] = useState({
        given_name: user.given_name,
        family_name: user.family_name,
        company: user.company,
        abn: user.abn,
        contact_number: user.contact_number,
        suite: '',
        location: '',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: '',
        //latitude: '',
        //longitude: '',

    });

    const [formData2, setFormData2] = useState({
        given_name: user.given_name,
        family_name: user.family_name,
        company: user.company,
        abn: user.abn,
        contact_number: user.contact_number,
        suite: '',
        location: '',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: '',
        //latitude: toString(''),
        //longitude: '',

    });

    const [prevFormData] = useState({
        given_name: user.given_name,
        family_name: user.family_name,
        company: user.company,
        abn: user.abn,
        contact_number: user.contact_number,
        suite: user.previous_delivery.address.line2,
        location: user.previous_delivery.address.line1,
        locality: user.previous_delivery.address.city,
        administrative_area_level_1: user.previous_delivery.address.state,
        postal_code: user.previous_delivery.address.postal_code,
        country: user.previous_delivery.address.country,
        //latitude: user.previous_delivery.geolocation.latitude,
        //longitude: user.previous_delivery.geolocation.longitude,
    })

    const [prevFormData2] = useState({
        given_name: user.given_name,
        family_name: user.family_name,
        company: user.company,
        abn: user.abn,
        contact_number: user.contact_number,
        suite: user.previous_billing.address.line2,
        location: user.previous_billing.address.line1,
        locality: user.previous_billing.address.city,
        administrative_area_level_1: user.previous_billing.address.state,
        postal_code: user.previous_billing.address.postal_code,
        country: user.previous_billing.address.country,
        //latitude: user.previous_billing.geolocation.latitude,
        //longitude: user.previous_billing.geolocation.longitude,
    })

    const handleUseAddressClick = (checked) => {
        if (user && user.previous_delivery) {
            if (checked) {
                updateFormData({
                    suite: user.previous_delivery.address.line2,
                    location: user.previous_delivery.address.line1,
                    locality: user.previous_delivery.address.city,
                    administrative_area_level_1: user.previous_delivery.address.state,
                    postal_code: user.previous_delivery.address.postal_code,
                    country: user.previous_delivery.address.country,
                    //latitude: user.previous_delivery.geolocation.latitude,
                    //longitude: user.previous_delivery.geolocation.longitude,
                });
            } else {
                updateFormData({
                    suite: '',
                    location: '',
                    locality: '',
                    administrative_area_level_1: '',
                    postal_code: '',
                    country: '',
                    //latitude: '',
                    //longitude: '',
                });
            }

        }
    };

    const handleUseBillingClick = (checked) => {
        if (user && user.previous_billing) {
            if (checked) {
                updateFormData2({
                    suite: user.previous_billing.address.line2,
                    location: user.previous_billing.address.line1,
                    locality: user.previous_billing.address.city,
                    administrative_area_level_1: user.previous_billing.address.state,
                    postal_code: user.previous_billing.address.postal_code,
                    country: user.previous_billing.address.country,
                    //latitude: user.previous_billing.geolocation.latitude,
                    //longitude: user.previous_billing.geolocation.longitude,
                });
            } else {
                updateFormData2({
                    suite: '',
                    location: '',
                    locality: '',
                    administrative_area_level_1: '',
                    postal_code: '',
                    country: '',
                    //latitude: '',
                    //longitude: '',
                });
            }
        }
    };

    const handleSameDeliveryClick = (checked) => {
        if (checked) {
            updateFormData2({
                suite: formData.suite,
                location: formData.location,
                locality: formData.locality,
                administrative_area_level_1: formData.administrative_area_level_1,
                postal_code: formData.postal_code,
                country: formData.country,
                //latitude: formData.latitude,
                //longitude: formData.longitude,
            });
        } else {
            updateFormData2({
                suite: '',
                location: '',
                locality: '',
                administrative_area_level_1: '',
                postal_code: '',
                country: '',
                //latitude: '',
                //longitude: '',
            });
        }

    };

    const updateFormData = (newData) => {
        setFormData(prev => ({ ...prev, ...newData }));
    };

    const updateFormData2 = (newData) => {
        setFormData2(prev => ({ ...prev, ...newData }));
    };



    useEffect(() => {
        // console.log(formData);
    }, [formData])

    const [isPrevDelivery, setIsPrevDelivery] = useState(false)
    const [isPrevBilling, setIsPrevBilling] = useState(false)
    const [isDeliverySame, setIsDeliverySame] = useState(false)



    const [paymentTerm, setPaymentTerm] = useState('Pay immediately')

    return (
        <FormDataContext.Provider value={{
            formData,
            updateFormData,
            formData2,
            updateFormData2,
            handleUseAddressClick,
            handleUseBillingClick,
            isPrevDelivery,
            setIsPrevDelivery,
            isPrevBilling,
            setIsPrevBilling,
            isDeliverySame,
            setIsDeliverySame,
            handleSameDeliveryClick,
            shippingMethod,
            setShippingMethod,
            prevFormData,
            prevFormData2,
            paymentTerm,
            setPaymentTerm
        }}>
            {children}
        </FormDataContext.Provider>
    );
}

export const StripeContextProvider = ({ children }) => {
    const { formData, formData2 } = useContext(FormDataContext);
    const { showAlertMessage } = useContext(AlertContext);
    const { user } = useAuth();

    const [clientSecret, setClientSecret] = useState('');
    const [cookies, setCookies] = useCookies(['cart_cookie', 'csrf_token', 'user']);
    const [stripe, setStripe] = useState(null);
    const [elements, setElements] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSetStripeElements = (stripeInstance, elementsInstance) => {
        setStripe(stripeInstance);
        setElements(elementsInstance)
    }

    useEffect(() => {
        // Fetch the client secret from the server
        const fetchClientSecret = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/create-setup-intent`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': cookies.csrf_token,
                    },
                    //body: JSON.stringify({ amount: 1000 }),
                    credentials: 'include',
                });
                const { clientSecret } = await response.json();
                setClientSecret(clientSecret);
            } catch (error) {
                console.error('Failed to fetch client secret:', error);
            }
        };
        fetchClientSecret();
    }, []);

    const handleOrderSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        const [zephyrSuccess, orderNumber] = await handleZephyrSubmit();
        if (!zephyrSuccess) {
            // Stop the process if handleZephyrSubmit fails
            // console.log('zephyr success failed');
            return;
        }
        await updateSetupIntentMetadata(clientSecret, orderNumber)
        const setupIntent = await handleSetupIntent(e);
        setIsLoading(false);
        //goToNextStep()
    }

    const updateSetupIntentMetadata = async (clientSecret, orderNumber) => {
        try {
            const response = await fetch(`${apiUrl}/api/update-setup-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies.csrf_token,
                },
                credentials: 'include',
                body: JSON.stringify({
                    clientSecret,
                    metadata: { orderid: orderNumber }
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update SetupIntent metadata');
            }
        } catch (error) {
            console.error('Error updating SetupIntent metadata:', error);
        }
    };

    const handleSetupIntent = async (e) => {
        try {
            if (!stripe || !elements) {
                console.error("Stripe or Elements not initialized.");
                return;
            }
            // console.log("here????");
            const { error, setupIntent } = await stripe.confirmSetup({
                elements,
                redirect: 'if_required',
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            name: 'Test name',
                            email: 'Testemail@test.com'
                        }
                    }
                }
            });
            // console.log("here?", setupIntent);

            if (error) {
                // console.log("Error during setup intent confirmation:", error.message);
                // Optionally, handle the error in the UI (e.g., show a message to the user)
                return;
            }

            // Successfully obtained setupIntent
            return setupIntent;
        } catch (err) {
            console.error("Unexpected error occurred:", err);
            // Optionally, handle the error in the UI (e.g., show a message to the user)
        }
    };

    let stripeItems = [];
    if (cookies.cart_cookie !== null && cookies.cart_cookie !== undefined) {
        stripeItems = cookies.cart_cookie.map(item => ({
            id: item.id,
            company_name: item.company_name,
            part_type: item.part_type,
            quantity: item.quantity,
            configurations: item.configurations
        }));
    }

    const handleZephyrSubmit = async () => {
        if (!cookies.cart_cookie || cookies.cart_cookie.length < 1) {
            // console.log("need to add items to cart");
            showAlertMessage('warning', 'You need to add items to your cart!')
            setIsLoading(false);
            return [false, null]
        }
        const deliveryDetails = {
            given_name: `${formData.given_name}`,
            family_name: `${formData.family_name}`,
            email: `${user.email}`,
            phone: `${formData.contact_number}`,
            address: {
                city: `${formData.locality}`,
                country: `${formData.country}`,
                line2: `${formData.suite}`,
                line1: `${formData.location}`,
                postal_code: `${formData.postal_code}`,
                state: `${formData.administrative_area_level_1}`,
            },
            geolocation: {
                latitude: `${formData.latitude}`,
                longitude: `${formData.longitude}`
            },
            shipping_info: {
                delivery_method: 'shippingData.delivery_method',
                shipping_type: 'shippingData.shipping_type' //fix later
            }
        }
        const billingDetails = {
            given_name: `${formData2.given_name}`,
            family_name: `${formData2.family_name}`,
            email: `${user.email}`,
            phone: `${formData2.contact_number}`,
            address: {
                city: `${formData2.locality}`,
                country: `${formData2.country}`,
                line2: `${formData2.suite}`,
                line1: `${formData2.location}`,
                postal_code: `${formData2.postal_code}`,
                state: `${formData2.administrative_area_level_1}`,
            },
            geolocation: {
                latitude: `${formData2.latitude}`,
                longitude: `${formData2.longitude}`
            }
        }
        try {
            const response = await fetch(`${apiUrl}/api/create-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies.csrf_token
                },
                body: JSON.stringify({
                    delivery_details: deliveryDetails,
                    billing_details: billingDetails,
                    user_details: user,
                    items: stripeItems
                })
            });
            const responseData = await response.json();
            if (responseData.success) {
                // Payment succeeded without additional actions
                //setOrderNumber(responseData.orderNumber)
                setCookies('cart_cookie', [], {
                    path: '/',
                    expires: new Date(Date.now() + 259200000),
                    secure: true,
                    httpOnly: false,
                    sameSite: 'lax'
                });

                return [true, responseData.orderNumber]

            } else {
                // Payment failed
                showAlertMessage('warning', responseData.error)
                return [false, null]
            }
        } catch (error) {
            console.error('Error:', error);
            //reset the setup intent here
            showAlertMessage('warning', 'An error occured processing your order.')
            return [false, null]
        } finally {

        }
    }

    const stripeOptions = useMemo(() => ({
        clientSecret,
    }), [clientSecret]);

    return (
        <>
            <StripeContext.Provider value={{ stripePromise, stripeOptions, clientSecret, handleOrderSubmit, handleSetStripeElements, isLoading, stripe, elements }}>
                {children}
            </StripeContext.Provider>
        </>
    )
}

export const AutocompleteProvider = ({ children }) => {
    const autocompleteInitialized = useRef(false);

    return (
        <AutocompleteContext.Provider value={autocompleteInitialized}>
            {children}
        </AutocompleteContext.Provider>
    )
}

export const CartDataProvider = ({ children }) => {
    const [cookies, setCookies] = useCookies(['cart_cookie', 'csrf_token', 'user']);
    const { setUser } = useAuth()
    const { shippingMethod } = useFormDataContext()

    const [checkoutData, setCheckoutData] = useState({
        cartItems: {},
        grandTotal: 0,
        GST: 0,
        subtotal: 0,
        postage: 0,
        fees: 0
    })

    const [cartLoading, setCartLoading] = useState(false)

    useEffect(() => {
        // console.log(checkoutData);
    },[checkoutData])

    const [shipmentData] = useState({
        delivery_method: shippingMethod,
        shipping_type: 'Single shipments'
    })

    useEffect(() => {
        if (cookies.cart_cookie) {
            // console.log(shipmentData);
            const cartItems = cookies.cart_cookie;
            fetchData(cartItems, shipmentData)
        } else {
            setCookies('cart_cookie', [], {
                path: '/',
                expires: new Date(Date.now() + 259200000),
                secure: true,
                httpOnly: false,
                sameSite: 'lax'
            })
        }
    }, [cookies]);

    const fetchData = async (cartItems, shippingData) => {
        setCartLoading(true)
        try {

            const response = await fetch(`${apiUrl}/api/paymentPortalRequest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies.csrf_token,
                },
                body: JSON.stringify({
                    cartItems: cartItems,
                    shippingData: shippingData
                }),
                credentials: 'include',
            });
            if (response.status === 400) {
                setUser(null);
                return;
            }
            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
                console.error("Unexpected response format:", response);
                return;
            }
            const data = await response.json();
            if (data.success === true) {
                // console.log(data.items);
                setCheckoutData({
                    cartItems: data.items,
                    grandTotal: data.grandTotal,
                    gst: data.gst,
                    subtotal: data.subTotal,
                    postage: data.postage,
                    fees: data.fees
                })
                if (data.removeItems.length > 0) {
                    //remove the items from the cookie
                    const updatedCartItems = cartItems.filter(
                        item => !data.removeItems.includes(item.id)
                    );
                    setCookies('cart_cookie', updatedCartItems, {
                        path: '/',
                        expires: new Date(Date.now() + 259200000),
                        secure: true,
                        httpOnly: false,
                        sameSite: 'lax'
                    });
                }
            } else {
                console.error("Data success is false:", data);
            }
            return data;
        } catch (error) {
            if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
                console.error('Fetch failed. Possible network error or CORS issue:', error);
            } else {
                console.error('An unexpected error occurred:', error);
            }
        } finally {
            setCartLoading(false)
        }
    };

    return (
        <CartDataContext.Provider value={{ checkoutData, cartLoading }}>
            {children}
        </CartDataContext.Provider>
    );


}

export const PartImageProvider = ({ children }) => {
    const [images, setImages] = useState({});
    const [cookies] = useCookies(['cart_cookie', 'csrf_token', 'user']);

    const addImages = (newImages) => {
        setImages((prevImages) => ({
            ...prevImages,
            ...newImages,
        }));
    };

    useEffect(() => {
        // console.log(images);
    }, [images])

    useEffect(() => {
        const fetchImages = async () => {
            const uniqueImageNames = Array.from(new Set(
                cookies.cart_cookie.map(row => row.imagename).filter(name => name && !images[name])
            ));
            if (uniqueImageNames.length > 0) {
                try {
                    const response = await fetch(`${apiUrl}/api/get-images`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': cookies.csrf_token,
                        },
                        body: JSON.stringify({ image_names: uniqueImageNames }),
                        credentials: 'include'
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    addImages(data);
                } catch (error) {
                    console.error('Error fetching images:', error);
                }
            }
        };
        if (cookies.cart_cookie && Array.isArray(cookies.cart_cookie)) {
            fetchImages();
        }
    }, []);

    return (
        <PartImageContext.Provider value={{ images, addImages }}>
            {children}
        </PartImageContext.Provider>
    );
};

export const CheckoutProvider2 = ({ children }) => {
    return (
        <AlertProvider>
            <AutocompleteProvider>
                <FormDataProvider>
                    <StripeContextProvider>
                        <PartImageProvider>
                            <CartDataProvider>
                                <>{children}</>
                            </CartDataProvider>
                        </PartImageProvider>
                    </StripeContextProvider>
                </FormDataProvider>
            </AutocompleteProvider>
        </AlertProvider>
    )
};

export const useFormDataContext = () => {
    const context = useContext(FormDataContext);
    if (!context) throw new Error("useCheckout must be used within a CheckoutProvider2");
    return context;
};

export const useStripeContext = () => {
    const context = useContext(StripeContext)
    if (!context) throw new Error("useStripeContext must be used within a CheckoutProvider2");
    return context;
}

export const useAutocompleteContext = () => {
    const context = useContext(AutocompleteContext);
    if (!context) throw new Error("useAutocompleteContext must be used within a CheckoutProvider2");
    return context;
};

export const useCartDataContext = () => {
    return useContext(CartDataContext);
}

export const useCheckoutImageContext = () => {
    return useContext(PartImageContext);
};

export default CheckoutProvider2