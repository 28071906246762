


import React, { useState } from 'react';







function ConfigSelection() {
    return (
        <div style={{ border: '1px #ccc solid', borderRadius: '4px', padding: '4px', fontSize: '12px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <div style={{ border: 'solid 1px #ccc', borderRadius: '4px', width: '30px', boxSizing: 'border-box' }}>
                    <input
                        placeholder="###"
                        style={{
                            resize: 'none',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                            boxSizing: 'border-box',
                            outline: 'none',
                            border: 'none',
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            height: '20px'
                        }}
                    />
                </div>
                <div style={{ border: 'solid 1px #ccc', borderRadius: '4px', width: '100%', boxSizing: 'border-box' }}>
                    <input
                        placeholder="selection"
                        style={{
                            resize: 'none',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                            boxSizing: 'border-box',
                            outline: 'none',
                            border: 'none',
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            height: '20px'
                        }}
                    />
                </div>
            </div>
        </div>
    );
}


export default ConfigSelection