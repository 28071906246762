import React from 'react';
import styles from './css/RadioGroup.module.css'

const RadioGroup = ({ options, name, selectedValue, onChange }) => {



    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {options.map((option) => {
                
                return (
                    <div key={option.value} style={{ padding: "5px 0px" }}>
                        <input
                            id={option.value}
                            type="radio"
                            name={name}
                            className={styles.hiddenRadio}
                            value={option.value}
                            checked={selectedValue === option.value}
                            onChange={() => onChange(option.value)}
                        />
                        <label htmlFor={option.value} className={`${styles.inputRadioLabel} zp-font-m`}>
                            <span
                                className={`${styles.inputRadio}`}
                                style={{
                                    borderWidth: selectedValue === option.value ? "7px" : "1px",
                                }}
                            />
                            <span className=''>
                                {option.label}
                            </span>

                        </label>

                    </div>
                )

            })}
        </div>
    );
};

export default RadioGroup;