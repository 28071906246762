import { useState, useEffect, useMemo, useCallback } from "react";
import { useCookies } from "react-cookie";

const useFetch = (url, payload = {}, method = "POST", customHeaders = {}, autoFetch = false) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies(["csrf_token"]); // Ensure cookies are available
  const apiUrl = process.env.REACT_APP_API_URL;

  // Memoize headers to prevent unnecessary re-fetching
  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      "X-CSRFToken": cookies.csrf_token || "",
      ...customHeaders, // Allow custom headers to be merged
    }),
    [cookies.csrf_token, JSON.stringify(customHeaders)]
  );

  // Memoize payload to prevent unnecessary re-fetching
  const memoizedPayload = useMemo(() => JSON.stringify(payload), [payload]);

  // Function to manually fetch data
  const fetchData = useCallback(async () => {
    if (!url) return;

    const controller = new AbortController(); // Create an abort controller
    const signal = controller.signal;

    setLoading(true);
    setError(null);

    try {
      const options = {
        method,
        headers,
        body: method !== "GET" ? memoizedPayload : undefined,
        signal, // Attach the abort signal
      };

      const response = await fetch(`${apiUrl}${url}`, options);

      if (!response.ok) {
        let errorMessage = `Error ${response.status}: ${response.statusText}`;

        if (response.status >= 400 && response.status < 500) {
          errorMessage = "Client Error: Please check your request.";
        } else if (response.status >= 500) {
          errorMessage = "Server Error: Please try again later.";
        }

        const errorBody = await response.text();
        throw new Error(`${errorMessage}\nDetails: ${errorBody}`);
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      if (err.name !== "AbortError") {
        setError(err.message || "An unknown error occurred.");
      }
    } finally {
      setLoading(false);
    }

    return () => controller.abort(); // Cleanup: Abort the fetch if needed
  }, [url, method, headers, memoizedPayload, apiUrl]);

  // Auto-fetch if enabled
  useEffect(() => {
    if (autoFetch) {
      fetchData();
    }
  }, [fetchData, autoFetch]);

  return { data, error, loading, fetchData };
};

export default useFetch;
