import React, {useState,useEffect} from 'react'
import { useNavigate, useLocation  } from 'react-router-dom';
import { useAuth, useToggle } from 'index.js'
import SettingsPanelButton from './SettingsPanelButton.js'
import './settings.css'
import { faUser, faFolder } from '@fortawesome/free-regular-svg-icons';
import { faShop, faShieldHalved } from '@fortawesome/free-solid-svg-icons'


function Settings() {
    const { user, setUser } = useAuth();
    const { isToggled } = useToggle();
    const location = useLocation();
    const navigate = useNavigate()

    const [globalNavType, setGlobalNavType] = useState(null)

    useEffect(() => {
        if (user && user.userType === 'supplier') {
            setGlobalNavType('/supplier/settings')
        } else if (user && user.userType === 'customer') {
            setGlobalNavType('/customer/settings')
        }
    },[])

    function handleMobileNavClick(navType) {
        let targetPath = '';
        
        if (user && user.userType === 'supplier') {
          targetPath = `/supplier/settings${navType}`;
          navigate(targetPath);
        } else if (user && user.userType === 'customer') {
          targetPath = `/customer/settings${navType}`;
          navigate(targetPath);
        }
      }

    return (
        <>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <div style={{fontSize: '16px', fontWeight: 700}}>Personal Settings</div>
                    <div className='settings-nav-grid'>
                       <SettingsPanelButton
                           globalNavType={globalNavType}
                           NavLocation={"/profile"}
                           Label={"Profile"}
                           handleMobileNavClick={handleMobileNavClick}
                           description={'Personal Details.'}
                           icon={faUser}
                       />
                    </div>
                    <div style={{fontSize: '16px', fontWeight: 700}}>Account Settings</div>
                    <div className='settings-nav-grid'>
                        <SettingsPanelButton
                            globalNavType={globalNavType}
                            NavLocation={"/account"}
                            Label={"Business"}
                            handleMobileNavClick={handleMobileNavClick}
                            description={'Account details, business details, management and ownership, public details.'}
                            icon={faShop}
                        />
                        <SettingsPanelButton
                            globalNavType={globalNavType}
                            NavLocation={"/security"}
                            Label={"Team and Security"}
                            handleMobileNavClick={handleMobileNavClick}
                            description={'Team members, roles, account management.'}
                            icon={faShieldHalved}
                        />
                        <SettingsPanelButton
                            globalNavType={globalNavType}
                            NavLocation={"/plans"}
                            Label={"Your Plans"}
                            handleMobileNavClick={handleMobileNavClick}
                            description={'Manage how you pay for Zephyr services.'}
                            icon={faFolder}
                        />
                    </div>
                </div>
            


        

        </>
    )
}


export default Settings