import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTargetRoute } from './TargetRoute';


const ProtectedRoute = ({ children, allowedUserTypes }) => {
  const { user } = useAuth();
  const location = useLocation();
  const { setTargetRoute } = useTargetRoute();

  // Check if the user's type is in the allowed types

  // console.log("allowed user type,",allowedUserTypes);
  
  if (user) {
    // console.log(user.userType);
  }
  

  if (!user || !allowedUserTypes.includes(user.userType)) {
    setTargetRoute(location.pathname)
    return <Navigate to="/login" replace />;
  }

  return children; // If conditions are met, render the children components
};

export default ProtectedRoute;