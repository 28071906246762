import react, { useContext, useEffect, useState, useRef } from 'react'
import styles from './GenericFooter.module.css'
import { StartNow } from 'index'




function GenericFooter() {


    return (
        <>
            <section className={`justify-center `} style={{ zIndex: 1, position: 'relative', display: 'flex', width: '100%', background: '#dae1f4' }}>
                <div style={{ width: '100%', maxWidth: '1080px', borderLeft: 'solid 1px #eee', borderRight: 'solid 1px #eee' }}>
                    <div className={`flex-col-20 align-center`} style={{ padding: '32px 0px' }}>
                        <div className='justify-center zp-font-xl zp-weight-m' style={{ width: '100%', padding: '0px 20px', maxWidth: '400px' }}>
                            Sign up with Zephyr today!
                        </div>
                        <div className='justify-center' style={{ borderBottom: `1px solid #999`, width: '100%', padding: '0px 20px', maxWidth: '400px' }}>
                            <StartNow />

                        </div>
                        <div className='justify-between zp-font-m' style={{ width: '100%', maxWidth: '500px', padding: '0px 20px' }}>
                            <span>© Copyright 2025</span>
                            <span className='flex-row-10'>
                                <span>Privacy</span>
                                <span>Terms and conditions</span>
                            </span>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}



export default GenericFooter