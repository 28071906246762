import React, { useState, useEffect } from 'react'
import './smallCheckout.css'
import { isMobile } from "react-device-detect";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NumberPicker, NoContent } from 'index.js';
import { useCartDataContext, useCheckoutImageContext } from 'index.js'
function SmallCheckout() {

    const { checkoutData } = useCartDataContext()
    const { images } = useCheckoutImageContext()

    useEffect(() => {
        console.log(checkoutData, images)
    }, [checkoutData, images])


    const cartItems = Object.values(checkoutData.cartItems ?? {})

    const groupedData = cartItems.reduce((acc, item) => {
        if (!acc[item.company]) {
            acc[item.company] = [];
        }
        acc[item.company].push(item);
        return acc;
    }, {});

    const [isExpanded, setIsExpanded] = useState(false)
    const [closeAll, setCloseAll] = useState(false)
    const [overflow, setOverflow] = useState("hidden");

    useEffect(() => {
        if (isExpanded) {
            const timer = setTimeout(() => {
                setOverflow("auto");
            }, 400); // Adjust delay as needed
            return () => clearTimeout(timer);
        } else {
            setOverflow("hidden");
        }
    }, [isExpanded]);


    useEffect(() => {
        if (!isExpanded) {
            setCloseAll(true)
        }
    }, [isExpanded])

    useEffect(() => {
        // console.log(groupedData);
    }, [groupedData])

    return (
        <>
            <div className='small-checkout-wrapper'>
                <div className='checkout-right-panel-small flex-col-0' >
                    <div style={{
                        borderBottom: '1px solid transparent', // Initial transparent border
                        borderBottomColor: isExpanded ? '#ccc' : 'transparent',
                        transition: 'border-bottom-color 0.3s ease-in-out '
                    }}>
                        <div className=' justify-between zp-font-s hidden-content-hover' onClick={() => { setIsExpanded(!isExpanded) }} style={{ width: '100%', padding: '16px' }}>
                            <div className='flex-row-10' >
                                <span>Order Summary</span>
                                <span className='zp-font-s ' style={{ transition: 'transform 0.3s ease-in-out', transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }}>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </span>
                            </div>
                            <div className='zp-font-s'>
                                ${checkoutData.grandTotal ? checkoutData.grandTotal : '0.00'}
                            </div>
                        </div>
                    </div>
                    <div className={`hidden-content ${isExpanded ? "show" : ""} flex-col-0`} >
                        <div style={{ paddingTop: '16px', borderBottom: '1px solid #ccc', overflow: overflow, scrollbarWidth: 'thin', scrollbarGutter: 'stable' }}>

                            {Object.entries(groupedData).map(([companyName, items], index) => (
                                <CheckoutFragment key={index} companyName={companyName} items={items} closeAll={closeAll} resetCloseAll={() => setCloseAll(false)} images={images} checkoutData={checkoutData} />
                            ))}


                        </div>
                        <div className='zp-font-m flex-col-5' style={{ padding: '16px' }}>
                            <div className='flex-row-10'>
                                <span className='zp-font-s' style={{ width: '70%' }}>Subtotal (ex GST)</span>
                                <span className='zp-font-s  justify-end' style={{ width: '30%' }}>${checkoutData.subtotal ? checkoutData.subtotal : '0.00'}</span>
                            </div>
                            <div className='flex-row-10'>
                                <span className='zp-font-s' style={{ width: '70%' }}>Postage</span>
                                <span className='zp-font-s  justify-end' style={{ width: '30%' }}>${checkoutData.postage ? checkoutData.postage : '0.00'}</span>
                            </div>
                            <div className='flex-row-10'>
                                <span className='zp-font-s' style={{ width: '70%' }}>GST</span>
                                <span className='zp-font-s  justify-end' style={{ width: '30%' }}>${checkoutData.gst ? checkoutData.gst : '0.00'}</span>
                            </div>
                            <div className='flex-row-10'>
                                <span className='zp-font-s' style={{ width: '70%' }}>Fees</span>
                                <span className='zp-font-s  justify-end' style={{ width: '30%' }}>${checkoutData.fees ? checkoutData.fees : '0.00'}</span>
                            </div>
                            <div className='flex-row-10'>
                                <span className='zp-font-s zp-weight-m' style={{ width: '70%' }}>Total</span>
                                <span className='zp-font-s zp-weight-m justify-end' style={{ width: '30%' }}>${checkoutData.grandTotal ? checkoutData.grandTotal : '0.00'}</span>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default SmallCheckout

export function CheckoutFragment({ index, companyName, items, closeAll, resetCloseAll, images, checkoutData }) {
    const [isExpanded, setIsExpanded] = useState(false)
    const [closeAll2, setCloseAll2] = useState(false)

    const sumSubtotals = (items) => {
        return items.reduce((acc, item) => acc + parseFloat(item.subtotal), 0);
    };

    const companyTotal = sumSubtotals(items);

    useEffect(() => {
        if (closeAll) {
            setIsExpanded(false)
            resetCloseAll()
        }
    }, [closeAll])

    useEffect(() => {
        if (!isExpanded) {
            setCloseAll2(true)
        }
    }, [isExpanded])

    return (
        <>
            <div style={{ padding: isMobile ? '0px 16px 16px 16px' : '0px 5px 16px 16px' }}>
                <div key={index} className='hidden-content-show zp-font-s justify-between' onClick={() => { setIsExpanded(!isExpanded) }} style={{ width: '100%' }}>
                    <span className='zp-weight-m flex-row-5' style={{ cursor: 'pointer' }}>
                        <span style={{ transition: 'transform 0.3s ease-in-out', transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }}> <FontAwesomeIcon icon={faChevronDown} /></span>
                        <span>{companyName}</span>
                    </span>
                    <span className='zp-weight-m'>${parseFloat(companyTotal).toFixed(2)}</span>
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""} flex-col-10`}>
                    <CheckoutFragmentChild index={index} items={items} closeAll={closeAll} resetCloseAll={resetCloseAll} closeAll2={closeAll2} resetCloseAll2={() => setCloseAll2(false)} images={images} />
                </div>
            </div>

        </>
    )
}

export function CheckoutFragmentChild({ items, closeAll, resetCloseAll, closeAll2, resetCloseAll2, images }) {
    const [expandedItems, setExpandedItems] = useState({});

    const toggleItem = (part_number_supplier) => {
        setExpandedItems((prev) => ({
            ...prev,
            [part_number_supplier]: !prev[part_number_supplier], // Toggle individual item
        }));
    };

    useEffect(() => {
        if (closeAll || closeAll2) {
            setExpandedItems({})
            resetCloseAll()
            resetCloseAll2()
        }
    }, [closeAll, closeAll2])

    return (
        <>
            <div className='flex-col-5' style={{ padding: '5px 0px', }}>
                {items.map((item, index) => (
                    <div className='flex-row-5' key={item.part_number_supplier} >
                        <div key={index} style={{ position: "relative", height: "50px", width: "50px", display: "flex", alignItems: "center" }}>
                            <div className='image-container' style={{ height: '100%', width: '100%' }}>
                                {item.image_name && images?.[item.image_name] ? (
                                    <img
                                        src={`data:image/jpeg;base64,${images[item.image_name]}`}
                                        alt="Product"
                                        style={{ maxHeight: "100%", border: "solid 1px #ccc", borderRadius: '10px', overflow: 'hidden' }}
                                    />
                                ) : (
                                    <div style={{ height: '50px', width: '50px' }}>
                                        <NoContent label="" borderRadius="0px" />
                                    </div>

                                )}
                            </div>
                            <span className='remove-part-container justify-center align-center flex-row-5' style={{ cursor: 'pointer' }}>

                                <FontAwesomeIcon icon={faXmark} fontSize={'20px'} />

                            </span>
                            <span
                                className='justify-center align-center zp-font-s'
                                style={{
                                    borderTopRightRadius: '10px',
                                    borderTop: item.image_name ? '1px solid #ccc' : '2px dashed #ccc',
                                    borderRight: item.image_name ? '1px solid #ccc' : '2px dashed #ccc',
                                    background: '#EBF5FF',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '20px',
                                    height: '20px'
                                }}>
                                {item.configurations.length > 0 && <>{item.quantity}</>}
                            </span>
                        </div>
                        <div style={{ width: '100%', minWidth: '0px', maxWidth: '100%' }}>
                            <div className='flex-row-10 zp-font-s justify-between' onClick={() => toggleItem(item.part_number_supplier)} >
                                <div className='flex-row-5' style={{ width: '100%', minWidth: 0 }}>
                                    <span className='zp-font-s ' style={{ transition: 'transform 0.3s ease-in-out', transform: expandedItems[item.part_number_supplier] ? "rotate(180deg)" : "rotate(0deg)" }}>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </span>
                                    <span style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        display: 'inline-block',
                                        maxWidth: '100%',  // Adjust width as needed
                                        cursor: 'pointer'

                                    }}>
                                        {item.part_number_supplier} {item.part_name}
                                    </span>

                                </div>
                                <div className='flex-row-10' >


                                    {expandedItems[item.part_number_supplier] ? (
                                        <span className='zp-font-s'>
                                            ${parseFloat(item.price * item.quantity).toFixed(2)}
                                        </span>
                                    ) : (
                                        <span className='zp-font-s'>
                                            ${parseFloat(item.subtotal).toFixed(2)}
                                        </span>
                                    )}

                                </div>

                            </div>

                            <div className={`hidden-content ${expandedItems[item.part_number_supplier] ? "show" : ""} flex-col-0`}>
                                <CheckoutFragmentChild2 configurations={item.configurations} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export function CheckoutFragmentChild2({ configurations }) {

    console.log(configurations)

    return (
        <>
            {configurations.map((config, index) => (
                <div key={index} className="zp-font-s flex-row-10 justify-between" style={{ paddingBottom: '2px', width: '100%' }}>

                    {/* Left Section: Number Picker + Configuration Name */}
                    <div className="flex-row-5" style={{ flex: '1', minWidth: '0' }}>
                        <span style={{ cursor: 'pointer', color: '#ccc' }}><FontAwesomeIcon icon={faXmark} fontSize={'14px'} /></span>
                        <NumberPicker
                            Quantity={config.quantity}
                            width={'16px'}
                            height={'16px'}
                        />

                        {/* Wrapping text inside a div for ellipsis to work */}
                        <div style={{ flex: '1', minWidth: '0', overflow: 'hidden' }}>
                            <span
                                className='zp-font-s'
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    display: 'block',  // Use block to ensure it respects width
                                    maxWidth: '100%'
                                }}>
                                {Object.entries(config.configuration)
                                    .map(([key, value]) => value) // Extract values only
                                    .join('-')}
                            </span>
                        </div>
                    </div>

                    {/* Right Section: Price */}
                    <div className="flex-row-10 zp-font-s">
                        <span>${parseFloat(config.price).toFixed(2)}</span>
                    </div>
                </div>
            ))}



        </>
    );
}