import React, { useState, useRef, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import './css/Chip.css';
import ChipOperatingConditions from './ChipOperatingConditions.tsx';
import ChipMeasuringConditions from './ChipMeasuringConditions.tsx';
import ChipConnectionData from './ChipConnectionData.tsx';
import ChipDefaultData from './ChipDefaultData.tsx'

import { useEquipment } from '../../../customerBody/CombinedContext.js';
import { useChipContext } from './context/ChipContext.js';
import { useChipFilterContext } from './context/ChipFilterContext.tsx';

interface ChipProps {
  type: string;
  filter: object;
  style?: React.CSSProperties; // Add this to allow passing styles from parent
  onVisibilityChange?: (type: string, isVisible: boolean) => void;
  chipIndex?: number;
}

const Chip = React.forwardRef<HTMLSpanElement, ChipProps>(({ type, filter, style, onVisibilityChange, chipIndex }, ref) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalPosition, setModalPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [isTransitioning, setTransitioning] = useState<boolean>(false);
  const chipRef = useRef<HTMLSpanElement | null>(null); // Internal reference for the chip
  const modalRef = useRef<HTMLDivElement | null>(null);

  //const [filterState, setFilterState] = useState(filter || {});
  const { filterStates, setFilterState, totalFilterStateLength } = useChipFilterContext();
  const currentFilterState = filterStates[type] || filter || {};

  const handleFilterStateChange = (newState: object) => {
    setFilterState(type, newState);
  };
  
  const [filterStateLength, setFilterStateLength] = useState(0);
  const [isVisible, setIsVisible] = useState(true); 

  const [chipWidth, setChipWidth] = useState(0);
  const [chipLeft, setChipLeft] = useState(0);

  const { hiddenChips, chipFiltersLength } = useChipContext();

  useEffect(() => {
    if (filterStates[type]) {
      // Get the specific state for this type
      const typeState = filterStates[type] as Record<string, any>; // Adjust the type as needed
  
      // Count the number of non-empty objects within the specific type state
      const filteredLength = Object.values(typeState).filter((value) => {
        // Check if the value is an object and has non-empty properties
        return (
          value && typeof value === 'object' && 
          Object.values(value).some((innerValue) => innerValue !== '' && innerValue !== null)
        );
      }).length;
  
      setFilterStateLength(filteredLength);
      // console.log('Filtered Length for type', type, ':', filteredLength);
    } else {
      // If there's no state for this type, set length to 0
      setFilterStateLength(0);
    }
  }, [filterStates, isVisible, onVisibilityChange, type]);

  useEffect(() => {
    if (onVisibilityChange) {
      onVisibilityChange(type, isVisible);
    }
  }, [isVisible]);

  const labels = {
    defaultdata: 'Part Details',
    operatingconditions: 'Operating Conditions',
    measuringdata: 'Measuring Data',
    connectiondata: 'Process Connections',
    morefilters: 'More Filters',
    
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target instanceof HTMLDivElement && e.target.classList.contains('modal-overlay')) {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleCloseModal();
    }
  };

  const handleChipClick = () => {
    if (isModalOpen) {
      handleCloseModal();
    } else {
      if (chipRef.current) {
        const chipRect = chipRef.current.getBoundingClientRect();
        let windowWidth = window.innerWidth;
        if (windowWidth + 91 < 900) {
          windowWidth = windowWidth + 250
        }

        // console.log(windowWidth);
        


        const newLeft = chipRect.left + 75 + 150 + 20
        if (windowWidth + 91 > 900) {

        }
        
        if (newLeft > windowWidth) {
          setModalPosition({
            top: chipRect.bottom + window.scrollY + 8,
            left: windowWidth - 300 - 20,
          });
        } else {
          setModalPosition({
            top: chipRect.bottom + window.scrollY + 8,
            left: chipRect.left + window.scrollX + chipRect.width / 2 - 150 
          });
        }
        
      }
      setModalOpen(!isModalOpen);
      setTransitioning(true);
    }
  };

  useEffect(() => {
    if (chipRef.current) {
      const currentWidth = ((hiddenChips.length > 0) && (chipIndex !== chipFiltersLength)) ? chipRef.current.offsetWidth + 20 + 106 : chipRef.current.offsetWidth + 20;
      let currentChipLeft = chipRef.current.getBoundingClientRect().left;
      let windowWidth = window.innerWidth;
      // console.log(windowWidth);
      if (windowWidth < 900) {
        currentChipLeft = currentChipLeft + 250
    
      } 
      setChipWidth(currentWidth);
      setChipLeft(currentChipLeft)
    }
  },[])

  useEffect(() => {
    const handleResize = () => {
      if (modalRef.current && isModalOpen) {
        let windowWidth = window.innerWidth - 91;
        if (windowWidth + 91 < 900) {
          windowWidth = windowWidth + 250
        }
        
        const newModalLeft = windowWidth - 300 - 20
        const totalChipWidth = chipLeft + chipWidth;
        const totalModalLeft = totalChipWidth + 65
        if (totalModalLeft > windowWidth) {
          setModalPosition(prevPosition => ({
            ...prevPosition,
            left: newModalLeft
          }));
        }
        
      }
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[isModalOpen, modalRef])

  useEffect(() => {
    if (chipRef.current) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  },[chipRef])
  

  useEffect(() => {
    const handleResize = () => {
      if (chipRef.current) {
        let windowWidth = window.innerWidth - 91;
        if (windowWidth + 91 < 900) {
          windowWidth = windowWidth + 250
        }
        const totalChipWidth = chipLeft + chipWidth;
        let adjustedChipWidth = ((hiddenChips.length > 0) && (chipIndex !== chipFiltersLength)) ? totalChipWidth + 106: totalChipWidth;
        adjustedChipWidth = ((totalFilterStateLength > 0) ? adjustedChipWidth + 20 + 120: adjustedChipWidth)
        
        if (adjustedChipWidth > windowWidth) {
          setIsVisible(false);
          setModalOpen(false);
        } else {
          setIsVisible(true);
        }
      }
    };

    handleResize(); // Calculate on initial render
    window.addEventListener('resize', handleResize); // Recalculate on window resize

    return () => window.removeEventListener('resize', handleResize);
  }, [isVisible, chipWidth, hiddenChips, totalFilterStateLength]); // Dependencies on isVisible and chipWidth

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);


  const setRefs = (node: HTMLSpanElement) => {
    chipRef.current = node; // Set internal ref
    if (typeof ref === 'function') {
      ref(node); // Call the forwarded ref if it's a function
    } else if (ref) {
      (ref as React.MutableRefObject<HTMLSpanElement | null>).current = node; // Set the forwarded ref
    }
  };

  useEffect(() => {
    // console.log("parent: ",isVisible);
  },[isVisible])

  const filtersStyle = {
    border: '1px solid #d8dee4'
  }

  const cogStyle = {
    color: "#339AF0"
  }

  // Forward the ref to the chip element
  return (
    <span aria-hidden="false" style={{ display: isVisible ? 'inline-flex' : 'none' }}>
      <span className="filter-chip" 
        ref={setRefs} 
        onClick={handleChipClick}
        style={filterStateLength > 0 ? filtersStyle : {}}
      >
        <FontAwesomeIcon icon={faGear} width={12} height={12} id="gear-icon" style={filterStateLength > 0 ? cogStyle : {}}/>
        {labels[type]}
        {filterStateLength > 0 && (
            <>
                {" | "}
                <span style={{color: '#339AF0', fontWeight: 'bold'}}>{filterStateLength}</span>
            </>
        )}
        
      </span>
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div
            className={`modal-content ${isTransitioning ? 'show' : ''}`}
            onClick={(e) => e.stopPropagation()}
            //style={{ top: modalPosition.top, left: modalPosition.left }}
            style={{position: 'relative'}}
            ref={modalRef}
          >
            



            {type === 'defaultdata' && (
              <ChipDefaultData
                defaultData={currentFilterState}
                setDefaultData={handleFilterStateChange}
              />
            )}
            {type === 'operatingconditions' && (
              <ChipOperatingConditions
                operatingConditionsData={currentFilterState}
                setOperatingConditionsData={handleFilterStateChange}
              />
            )}
            {type === 'measuringdata' && (
              <ChipMeasuringConditions 
              measuringRangeData={currentFilterState}
              setMeasuringRangeData={handleFilterStateChange}
              />
            )}
            {type === 'connectiondata' && (
              <ChipConnectionData 
                connectionData={currentFilterState}
                setConnectionData={handleFilterStateChange}
                
              />
            )}
            
            
          </div>
        </div>
      )}
    </span>
  );
});

export default Chip;
