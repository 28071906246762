
import React, { useEffect, useState, useContext } from 'react'


import TestPartComponent from './../../../header/customerHeader/TestPartComponent.js';
import CustomerHeaderComponent from './../../../header/customerHeader/HeaderComponent.js'
import SupplierEquipmentBody from './SupplierEntrypoint.js'
import SupplierNavigationPanel from './SupplierNavigationPanel.js';

import useIdleActivity from './../../../useIdleActivity.js'; // Import the custom hook

import { useAuth, useToggle, CheckoutProvider2, AlertContext, CustomAlert, Checkout, History } from 'index.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import SupplierDashWrapper from './../supplierDash/SupplierDashWrapper.js';
import SettingsEntrypointWrapper from 'pages/settings/SettingsEntrypointWrapper.js';

function SupplierEntrypointWrapper() {

    const { isToggled } = useToggle();
    const { setUser } = useAuth();
    const { alerts } = useContext(AlertContext);

    const [sessionWarn, setSessionWarn] = useState(false)

    const handleLogout = () => {
        setUser(null);
        //// console.log('logged out yes yes yes');
        //navigate('/login')
        // Optionally, you can also remove cookies or perform other logout actions here
    };

    const handleSessionWarn = () => {
        if (sessionWarn === false) {
            setSessionWarn(true)
            // console.log("test session warn");
        }
    }

    useIdleActivity(handleLogout, handleSessionWarn);

    const modalStyle = {
        zIndex: 1000,
        position: 'absolute',
        backgroundColor: 'var(--modal-background-color)',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
    }
    const modalContent = {
        zindex: 1001,
        position: 'absolute',
        border: '1px solid white',
        backgroundColor: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        width: '400px',
        borderRadius: '8px',
        padding: '16px'
    }
    const modalWrapper = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }

    const modalButtons = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 'auto',
        gap: '10px',
        padding: '8px 0px',
        marginTop: '24px'
    }

    function handleModalContentClick(event) {
        // Prevent click event from propagating to the parent
        event.stopPropagation();
    }

    return (
        <>
            <div>
                {isToggled && (
                    <TestPartComponent />
                )}
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <div className='navigation-wrapper' style={{ height: isToggled ? 'calc(100vh - 44px)' : 'var(--vh)', borderRight: '1px #ccc solid' }}>
                    <SupplierNavigationPanel />
                </div>
                <div style={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
                    <CustomerHeaderComponent />
                    <Routes>
                        <Route path="" element={
                            <SupplierDashWrapper />
                        } />
                        <Route path="equipment" element={
                            <SupplierEquipmentBody />
                        } />
                        <Route path="settings/*" element={
                            <SettingsEntrypointWrapper />
                        } />
                        <Route path="checkout" element={
                            <CheckoutProvider2>
                                <CustomAlert />
                                <Checkout />
                            </CheckoutProvider2>
                        } />
                        <Route path="orders/*" element={
                            <>
                                <History/>
                            </>
                        } />

                    </Routes>

                </div>
            </div>
            {sessionWarn && (
                <div style={modalStyle}>
                    <div style={modalContent} onClick={handleModalContentClick}>
                        <div style={modalWrapper}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <div className='modal-header'>Session Expiry</div>
                                <div className='modal-text-content' style={{ fontSize: '14px', fontStyle: 'Arial' }}>Your session has expired, please login again to continue.</div>
                            </div>
                            <div style={modalButtons}>
                                <span className='cancel-button' onClick={handleLogout}>Logout</span>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SupplierEntrypointWrapper

//<span className='delete-button' onClick={() => {setSessionWarn(false)}}>Continue</span>
//
//
//
