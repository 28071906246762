import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faRightToBracket } from '@fortawesome/free-solid-svg-icons';

function FilterBox({inputValue, setInputValue, width}) {

    const [isFocused, setIsFocused]  = useState(false)
    
   

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        // console.log(event.target.value);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          handleRightIconClick();
        }
    };

    const handleRightIconClick = () => {
        // console.log('Right icon clicked');
        //triggerSearch()
        //this should search for items
    };

    const filterByInputWrapper = {
        display: 'flex', 
        flexDirection: 'row', 
        gap: '5px', 
        //border: "1px solid #d8dee4",
        padding: "4px 8px 4px 4px",
        borderRadius: "4px",
        minHeight: "30px",
        width: width ? width : "300px",
        border: `1px solid ${isFocused ? 'var(--brand-color-1)' : '#d8dee4'}`,
        transition: 'border-color 0.2s ease',
    }

    const filterByIdInputStyle = {
        
        
        fontSize: "14px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        outline: "none",
        border: 'none'
    };

    const filterByInputContainer = {
        width: '100%',
    }


    return (
        <>
        <div style={filterByInputWrapper}>
            <div style={{minWidth: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    width={12}
                    height={12}
                    //id="magnifying-glass"
                />
            </div>
            <div style={filterByInputContainer}>
                <input
                  style={filterByIdInputStyle}
                  placeholder="Find by name or email..."
                  value={inputValue}
                  onChange={handleInputChange}
                  
                  onKeyDown={handleKeyDown}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
            </div>
            
            <div style={{}}>
                {inputValue && (
                  <FontAwesomeIcon
                    icon={faRightToBracket}
                    width={16}
                    height={16}
                    color={'#7e8285'}
                    onClick={handleRightIconClick}
                  />
                )}
            </div>
        </div>
        
        
        </>
    )
}

export default FilterBox