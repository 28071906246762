import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import './App.css';
import CustomerEquipmentComponent from './components/body/customerBody/CustomerEquipmentComponent.js';
import NavigationComponent from './components/navigation/NavigationComponent.js'
import CustomerHeaderComponent from './components/header/customerHeader/HeaderComponent.js';
import SupplierHeaderComponent from './components/header/supplierHeader/SupplierHeaderComponent.js'
import { ScrollProvider } from './components/body/customerBody/scrollContext.js';
import CustomerNavigationComponent from './components/profile/customer/CustomerNavigationComponent.js';
import LandingPageComponent from './components/landingPage/LandingPageComponent.js';
import RouteWatcher from './components/RouteWatcher.js';
import CheckoutComponent from './components/checkout/checkoutComponent.js';
import { FormDataProvider } from './components/checkout/context/FormDataContext.js';
import { BreadCrumbProvider } from './components/checkout/context/BreadCrumbContext';
//import LoginComponent from './components/loginComponent.js'
import LoginComponent from './pages/login/LoginComponent.js'
import OrdersComponent from './components/orders/OrdersComponent.js'
//import ZephyrSort from './components/zephyrSort/zephyrSortComponent.js';

import { TargetRouteProvider } from './components/TargetRoute';

import { WebSocketProvider } from './components/WebSocketContext';

import { EquipmentProvider } from './components/body/customerBody/CombinedContext.js';

import { OrdersProvider } from './components/orders/OrdersContext';
import { ShipmentDataProvider } from './components/checkout/context/ShipmentDataContext';

//import { CheckoutProvider } from './contexts/CheckoutContext.js';

import { AlertProvider } from './components/body/customerBody/AlertContext.js'
import CustomAlert from './components/CustomAlertComponent';

import { ImageProvider } from './components/body/customerBody/ImageContext.js';

import { StripeContextProvider } from './components/checkout/context/StripeContext.js';


import { CheckoutImageProvider } from './components/checkout/context/CheckoutImageContext.js'
import SupplierEquipmentBody from './components/body/supplierBody/supplierEquipment/SupplierEntrypoint.js';
import { ChipProvider } from './components/body/supplierBody/supplierEquipment/ChipComponents/context/ChipContext.js';
import { ChipFilterProvider } from './components/body/supplierBody/supplierEquipment/ChipComponents/context/ChipFilterContext.tsx';


import { ToggleProvider } from './contexts/ToggleContext.js';


import TwoDModellingComponent from './components/modules/2DModellingComponent/2DModellingComponent.js'


//import { useToggle } from './components/header/customerHeader/ToggleContext.js';

import SupplierEntrypointWrapper from './components/body/supplierBody/supplierEquipment/SupplierEntrypointWrapper.js';
import Contact from 'pages/contact/Contact.js';


import { ContactDetailsProvider } from 'index'

function App() {
  const { user } = useAuth();


  return (
    <TargetRouteProvider>
      <Router>
        <AuthProvider>
          <RouteWatcher />
          <Routes>
            <Route
              element={
                <ContactDetailsProvider>
                  <Outlet />
                </ContactDetailsProvider>
              }
            >
              <Route path="/" element={!user ? <LandingPageComponent /> : null} />
              <Route path="/contact" element={
                <AlertProvider>
                  <CustomAlert />
                  <Contact />
                </AlertProvider>
              }
              />
              <Route path="/login" element={
                <AlertProvider>
                  <CustomAlert />
                  <LoginComponent />
                </AlertProvider>
              } />
            </Route>
            <Route path="/customer/*" element={
              <AlertProvider>
                <ToggleProvider>
                  <ProtectedRoute allowedUserTypes={['customer']}>
                    <ChipFilterProvider>
                      <ChipProvider>
                        <SupplierEntrypointWrapper />
                      </ChipProvider>
                    </ChipFilterProvider>
                  </ProtectedRoute>
                </ToggleProvider>
              </AlertProvider>
            } />

            <Route path="/supplier/*" element={
              <AlertProvider>
                <ToggleProvider>
                  <ProtectedRoute allowedUserTypes={['supplier']}>
                    <ChipFilterProvider>
                      <ChipProvider>
                        <SupplierEntrypointWrapper />
                      </ChipProvider>
                    </ChipFilterProvider>
                  </ProtectedRoute>
                </ToggleProvider>
              </AlertProvider>
            } />

          </Routes>
        </AuthProvider>
      </Router>
    </TargetRouteProvider>
  );
}

export default App;





//<div style={{ minWidth: "100%", overflow: "hidden" }}>
//                    <CustomerHeaderComponent/>
//                      {customerEquipmentComponent && (
//                        <ScrollProvider>
//                          <CustomerEquipmentComponent
//                            //hiddenColumns={[]}
//                            refreshData={refreshData}
//                            newRefreshData={setRefreshData}
//                          />
//                        </ScrollProvider>
//                      )}
//                    {customerProfileComponent && (
//                      <CustomerNavigationComponent />
//                    )}
//
//                  </div>

//

//<div style={{display: 'flex', flexDirection: 'row'}}>
//<NavigationComponent/>



//<Route path="/customer/*" element={
//              <AlertProvider>
//                <ToggleProvider>
//                  <CustomAlert />
//                  <EquipmentProvider>
//                    <ImageProvider>
//                      <CheckoutImageProvider>
//                        <OrdersProvider>
//                          <WebSocketProvider>
//                            <FormDataProvider>
//                              <ShipmentDataProvider>
//                                <Routes>
//                                  <Route path="" element={
//                                    <ProtectedRoute allowedUserTypes={['customer']}>
//                                      <ChipFilterProvider>
//                                        <ChipProvider>
//                                          <CustomerHeaderComponent />
//                                          <SupplierEquipmentBody />
//                                        </ChipProvider>
//                                      </ChipFilterProvider>
//                                    </ProtectedRoute>
//                                  } />
//                                  <Route path="profile" element={
//                                    <ProtectedRoute allowedUserTypes={['customer']}>
//                                      <div style={{ height: '100vh', overflow: 'hidden' }}>
//                                        <CustomerHeaderComponent />
//                                        <CustomerNavigationComponent />
//                                      </div>
//                                    </ProtectedRoute>
//                                  } />
//                                  <Route path="checkout" element={
//                                    <ProtectedRoute allowedUserTypes={['customer']}//>

//                                      <BreadCrumbProvider>
//                                        <div style={{ height: '100vh', overflow: 'hidden' }}>
//                                          <CustomerHeaderComponent />
//                                          <StripeContextProvider>
//                                            <CheckoutComponent />
//                                          </StripeContextProvider>
//                                        </div>
//                                      </BreadCrumbProvider//>

//                                    </ProtectedRoute>
//                                  } />
//                                  <Route path="orders" element={
//                                    <ProtectedRoute allowedUserTypes={['customer']}>
//                                      <CustomerHeaderComponent />
//                                      <OrdersComponent />
//                                    </ProtectedRoute>
//                                  } />
//                                  <Route path="2DModelling" element={
//                                    <ProtectedRoute allowedUserTypes={['customer']}//>

//                                      <CustomerHeaderComponent />
//                                      <TwoDModellingComponent ///>

//                                    </ProtectedRoute>
//                                  } />
//                                </Routes>
//                              </ShipmentDataProvider>
//                            </FormDataProvider>
//                          </WebSocketProvider>
//                        </OrdersProvider>
//                      </CheckoutImageProvider>
//                    </ImageProvider>
//                  </EquipmentProvider>
//                </ToggleProvider>
//              </AlertProvider>
//            } />