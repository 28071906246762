


import React from 'react'
import { useNavigate, useLocation  } from 'react-router-dom';

function NavigationPanelButton({globalNavType, Label, NavLocation, handleMobileNavClick}) {
 

    const location = useLocation();


    return (
        <>
            <li className="mb-1 tw-li-style">
                <a className="header-element" 
                    style={{
                        backgroundColor: location.pathname === `${globalNavType}${NavLocation}` ? '#eff6ff' : '', 
                        color:           location.pathname === `${globalNavType}${NavLocation}` ? '#2563eb' : ''
                    }} 
                    onClick={() => handleMobileNavClick(NavLocation)}>
                        {Label}
                </a>
            </li>
        </>
    )
}

export default NavigationPanelButton