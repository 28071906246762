


import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import GoogleLoginButton from './GoogleLoginButton'
import OutlookLoginButton from './OutlookLoginButton'
import ZephyrInnovaitionsLogo2 from './../../assets/Zephyr Innovations Logo 2 (5).svg';


import { Button1 } from 'index.js'

import './css/LoginComponent.css'
function LoginComponent() {

    const navigate = useNavigate();

    return (
        <>
            <div className='justify-center' style={{ width: '100%', height: 'var(--vh)', overflowY: 'auto', scrollbarWidth: 'thin', backgroundColor: 'rgb(246, 249, 252)' }}>






                <div style={{
                    height: 'var(--vh)', maxWidth: '1080px',
                    width: '100%'
                }}>


                    <div
                        className='flex-col-10 justify-center align-center'
                        style={{
                            maxWidth: '1080px',
                            width: '100%',
                            borderLeft: '1px solid #ddd',
                            borderRight: '1px solid #ddd',

                            height: '100%',
                            minHeight: '700px',

                        }}>


                        <div
                            className='flex-col-10 justify-center'
                            style={{ height: '100%', width: '100%', alignItems: 'center', position: 'relative', padding: '0px 20px' }}
                        >
                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', overflow: 'hidden' }}>
                                <div
                                    style={{ width: '100%', height: '68px', padding: '20px 10px' }}
                                    onClick={() => { navigate('/') }}
                                >
                                    <img style={{ height: '20px' }} src={ZephyrInnovaitionsLogo2}></img>
                                </div>
                            </div>
                            <div className='justify-center flex-col-10' style={{
                                backgroundColor: 'white', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'

                                , borderRadius: '10px',
                                maxWidth: '550px',
                                width: '100%'


                            }}>
                                <div className='flex-col-5' style={{ padding: '40px 40px 20px 40px' }}>
                                    <span className='zp-font-xlarge zp-weight-m'>Sign in to your account</span>
                                    <span className='zp-font-medium'>Zephyr supports login methods through secure channels, please login through one of the two methods below.</span>
                                </div>

                                <div className='flex-col-5 justify-center' style={{ padding: '0px 40px', paddingBottom: '50px' }}>
                                    <GoogleLoginButton />
                                    <OutlookLoginButton />
                                </div>
                                <div className='zp-font-medium justify-center align-center' style={{ padding: '20px 15px', margin: '4px', backgroundColor: 'rgb(246, 249, 252)', borderRadius: '10px' }}>
                                    <span style={{ paddingRight: '5px' }}>New to Zephyr? </span>
                                    <Button1 label={'Contact us'} onClick={() => {navigate('/contact')}}/>
                                     <span>for an account</span>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default LoginComponent


///<span className='zp-weight-m' style={{ color: 'var(--brand-color-1)', paddingRight: '5px' }} onClick={() => {navigate('/contact')}}>Contact us</span>
                                   