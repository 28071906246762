import React, { useState, useEffect, useRef } from 'react'
import { TabNavigation, FitlerBox, useAuth, useToggle, Button5, ModalWrapper1, Button4, Button4_1, ModalWrapper2, GenericCheckbox, RadioGroup } from 'index.js'
import './security.css'
import { faPlus, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation } from 'react-router-dom';
import MultiEmailInput from './MultiEmail';

function Security() {
    const navigate = useNavigate()
    const { isToggled } = useToggle();
    const { user } = useAuth()
    const [newMemberModal, setNewMemberModal] = useState(false)

    function handleDeleteModal() {
        setNewMemberModal(false)
    }


    function handleNavClick() {
        let targetPath = '';
        if (user && user.userType === 'supplier') {
            targetPath = `/supplier/settings`;
            navigate(targetPath);
        } else if (user && user.userType === 'customer') {
            targetPath = `/customer/settings`;
            navigate(targetPath);
        }
    }

    useEffect(() => {
        // console.log(user);
    }, [user])

    const tabs = ['Team']

    function handleTabClick() {
        // console.log("tab");
    }

    const teamData = [
        {
            "member": {
                "name": "Declan Price",
                "email": "declan.price@zephyrinnovations.com.au"
            },
            "roles": "Super Administrator, Administrator (Owner)",
            "auth": "2-Step",
            "lastLogin": "Dec 18, 2024, 2:38 PM"
        },
        {
            "member": {
                "name": "Kale Johns",
                "email": "kale.johns@zephyrinnovations.com.au"
            },
            "roles": "Super Administrator, Administrator (Owner)",
            "auth": "2-Step",
            "lastLogin": "Dec 18, 2024, 2:38 PM"
        },
        {
            "member": {
                "email": "example@zephyrinnovations.com.au"
            },
            "roles": "View Only",
            "lastLogin": "Invitation Sent"
        },
    ]

    const [inputValue, setInputValue] = useState('')

    const filteredData = teamData.filter((teamMember) => {
        const name = teamMember.member.name || ""; // Default to empty string if name is missing
        const email = teamMember.member.email || "";
        return (
            name.toLowerCase().includes(inputValue.toLowerCase()) ||
            email.toLowerCase().includes(inputValue.toLowerCase())
        );
    });


    return (
        <>
            <div
                style={{ display: 'flex', fontSize: '14px', fontWeight: 700, fontFamily: 'var(--font-family-1)', color: '#339AF0' }}

            >
                <div onClick={handleNavClick} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faChevronLeft} color={'#545969'} />
                    {' '}Settings
                </div>

            </div>
            <div style={{ fontSize: '24px', fontWeight: 700, marginBottom: '10px' }}>Team and security</div>
            <TabNavigation
                tabs={tabs}
                onTabClick={handleTabClick}
                fontSize='14px'
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
                <FitlerBox
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
                <div className='zp-font-s'>
                    <Button5
                        label={'New member'}
                        icon={faPlus}
                        onClick={() => { setNewMemberModal(!newMemberModal) }}
                    />
                </div>


            </div>
            <div style={{ marginTop: '20px', marginBottom: '20px', overflowY: 'auto', maxHeight: isToggled ? 'calc(var(--vh) - 330px)' : 'calc(var(--vh) - 286px', scrollbarWidth: 'thin' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
                    <thead style={{ borderTop: '1px solid #ddd' }}>
                        <tr>
                            <td className='team-table-header'>MEMBER</td>
                            <td className='team-table-header'>ROLES</td>
                            <td className='team-table-header'>AUTH</td>
                            <td className='team-table-header'>LAST LOGIN</td>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((teamMember, index) => (
                            <tr key={index}>
                                <td className='team-table-td'>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'start' }}>
                                            <div style={{ fontWeight: 500 }}>{teamMember.member.name}</div>
                                            {user.email === teamMember.member.email && (
                                                <div style={{ textWrap: 'nowrap', textAlign: 'left', fontSize: '12px', padding: '1px 6px', color: '#0055BC', backgroundColor: '#CFF5F6', fontWeight: 500 }}>You</div>
                                            )}
                                        </div>
                                        <div>{teamMember.member.email}</div>
                                    </div>
                                </td>
                                <td className='team-table-td'>
                                    {teamMember.roles}
                                </td>
                                <td className='team-table-td'>
                                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                                        {teamMember.auth && (
                                            <div style={{ textWrap: 'nowrap', textAlign: 'center', fontSize: '12px', padding: '1px 6px', color: '#0055BC', backgroundColor: '#CFF5F6', fontWeight: 500 }}>
                                                {teamMember.auth}
                                            </div>
                                        )}

                                    </div>
                                </td>
                                <td className='team-table-td'>
                                    {teamMember.lastLogin === 'Invitation Sent' ? (
                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                            <div style={{ textWrap: 'nowrap', textAlign: 'center', fontSize: '12px', padding: '1px 6px', color: '#545969', backgroundColor: '#eee', fontWeight: 500 }}>
                                                {teamMember.lastLogin}
                                            </div>
                                        </div>

                                    ) : (
                                        <div>
                                            {teamMember.lastLogin}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{ fontFamily: 'var(--font-family-1)', fontSize: '14px', color: '#353A44', marginTop: '20px' }}>
                {filteredData.length} members
            </div>
            {newMemberModal && (
                <ModalWrapper2
                    children={
                        <NewMemberContent
                            handleDeleteModal={handleDeleteModal}
                        />
                    }
                    handleCloseModal={handleDeleteModal}
                    width='100%'
                    height='100%'
                    maxWidth='1350px'
                    overflow='hidden'
                    padding='0px'
                />
            )}
        </>
    )
}


export default Security


export function NewMemberContent({ handleDeleteModal, handleDelete }) {

    const modalWrapper = {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(var(--vh) - 2*40px)'

    }
    const modalButtons = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        gap: '10px',
        padding: '8px 16px',
        marginTop: '24px'
    }

    const [selectedRoles, setSelectedRoles] = useState({});



    return (
        <>
            <div style={modalWrapper}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '16px 20px' }}>
                    <span className=' zp-font-l zp-weight-m' >Invite team members</span>
                </div>
                <div className='flex-row-0 hide-750' style={{ width: '100%', height: 'calc(var(--vh) * 0.75)', maxHeight: 'calc(var(--vh) - 2* 40px - 53px - 59px)', borderTop: '1px solid #ccc' }}>
                    <NewMemberLeft selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles} />
                    <NewMemberRight selectedRoles={selectedRoles} />
                </div>
                <div className='zp-font-s flex-row-10 justify-end' style={{ borderTop: '1px solid #ccc', padding: '16px 20px' }}>
                    <Button4 onClick={handleDeleteModal} label={'Cancel'} />
                    <Button4_1 label={'Send Invites'} />
                </div>
            </div>
        </>
    )
}

export function NewMemberLeft({ selectedRoles, setSelectedRoles }) {

    const [inputValue, setInputValue] = useState('')

    const handleCheckboxChange = (id) => {
        setSelectedRoles(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    useEffect(() => {
        console.log(selectedRoles)
    }, [selectedRoles])

    const roles = {
        "Admin roles": [
            { id: "superAdmin", label: "Super Administrator" },
            { id: "administrator", label: "Administrator" },
            { id: "iamAdmin", label: "IAM Administrator" }
        ],
        "Developer roles": [
            { id: "developer", label: "Developer" }
        ],
        "View only roles": [
            { id: "viewOnly", label: "View Only" }
        ]
    };

    return (
        <>
            <div className='zp-w-100 zp-h-100 new-member-left' style={{overflowY: 'auto', scrollbarWidth: 'thin' }}>
                <div className='flex-col-0'>
                    <div className='flex-col-20'>
                        <span className='zp-font-m zp-weight-m'>Enter team member email addresses</span>
                        <MultiEmailInput />
                        <span className='zp-font-m zp-weight-m'>Select team member roles</span>
                        <FitlerBox inputValue={inputValue} setInputValue={setInputValue} width={'100%'}/>

                        <div>
                            {Object.entries(roles).map(([groupTitle, checkboxes]) => {
                                const filteredCheckboxes = checkboxes.filter(({ label }) =>
                                    label.toLowerCase().includes(inputValue.toLowerCase())
                                );
                                return filteredCheckboxes.length > 0 ? (
                                    <div className='flex-col-0' key={groupTitle}>
                                        <span className='zp-font-m zp-weight-m' style={{ padding: '8px 16px', backgroundColor: '#f5f6f8', borderBottom: '1px solid #ebeef1' }}>
                                            {groupTitle}
                                        </span>
                                        <span className='zp-font-m flex-col-0'>
                                            {filteredCheckboxes.map(({ id, label }) => (
                                                <span key={id} style={{ padding: '8px 24px', borderBottom: '1px solid #ebeef1' }}>
                                                    <GenericCheckbox
                                                        id={id}
                                                        label={label}
                                                        checked={!!selectedRoles[id]}
                                                        onChange={() => handleCheckboxChange(id)}
                                                        style={{ borderRadius: '4px' }}
                                                    />
                                                </span>
                                            ))}
                                        </span>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export function NewMemberRight({ selectedRoles }) {
    const RoleLabels = {
        superAdmin: 'Super Administrator',
        administrator: 'Administrator',
        iamAdmin: 'IAM Administrator',
        developer: 'Developer',
        viewOnly: 'View Only'
    };

    const RoleDescriptions = {
        superAdmin: 'A Super Administrator is a special type of Administrator that can perform all actions, including closing the account. The creator of a business account is automatically assigned as a Super Administrator. Only a Super Administrator can assign the Super Administrator role to other team members.',
        administrator: 'This role is for people who need similar access as the account owner. This role can see and manage almost everything.',
        iamAdmin: 'The Identity and Access Management (IAM) Admin role is people who need to invite team members and assign roles.',
        developer: 'This role is for developers who need to set up a Zephyr integration. This role has access to the secret key which grants access to almost all Zephyr resources.',
        viewOnly: `This role is for people who need to view payments, balance, and connected accounts, but can't edit any of them. This role can also export data and download reports.`
    };

    return (
        <>
            <div className='zp-w-100 zp-h-100 new-member-right' style={{ padding: '20px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                <div className='flex-col-10' style={{ paddingTop: '12px', paddingBottom: '32px' }}>
                    <span className='zp-weight-m zp-font-l'>Role Descriptions</span>

                    {Object.keys(selectedRoles).length === 0 || Object.values(selectedRoles).every(value => !value) ? (
                        <span className='zp-font-m' style={{ color: '#6D6E78' }}>Choose roles to see their descriptions…</span>
                    ) :
                        Object.keys(selectedRoles)
                            .filter(role => selectedRoles[role]) // Only get selected roles
                            .sort((a, b) => a.localeCompare(b))
                            .map(role => (
                                <div key={role} className='flex-col-0' style={{ borderRadius: '4px' }}>
                                    <span className='zp-font-m zp-weight-m'>{RoleLabels[role]}</span>
                                    <span className='zp-font-m'>{RoleDescriptions[role] || 'No description available.'}</span>
                                </div>
                            ))
                    }
                </div>
            </div>
        </>
    );
}


//<span className='cancel-button' onClick={handleDeleteModal}>Cancel</span>
//                    <span className='delete-button' onClick={handleDelete}>Delete</span>