import React from 'react';
import { useToggle } from '../../../contexts/ToggleContext';
import './css/ToggleSwitch.css';

const ToggleSwitch = () => {
  const { isToggled, toggle } = useToggle();

  return (
    <div className="toggle-container" onClick={toggle}>
      <label className="toggle-label">Test mode</label>
      <div className="toggle-switch">
        <input
          type="checkbox"
          id="switch"
          checked={isToggled}
          onChange={toggle}
        />
        <label htmlFor="switch" className="slider"></label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
