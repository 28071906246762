import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth from your AuthContext

const RouteWatcher = () => {
    const location = useLocation();
    const { user, setUser } = useAuth();

    useEffect(() => {
        // Reset certain states when navigating back to '/'
        if (location.pathname === '/') {  
        }
    }, [location, user, setUser]);

    return null; // This component does not render anything
};

export default RouteWatcher;
