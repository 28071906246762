import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';



const apiUrl = process.env.REACT_APP_API_URL;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function PaymentDetails(props) {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [stripe, setStripe] = useState(null);
    const [elements, setElements] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [bankAccountBSB, setBankAccountBSB] = useState('');
    
    
    const isValidAustralianPostcode = (postcode) => {
        const postcodePattern = /^[0-9]{4}$/; // Basic Australian postcode pattern
        return postcodePattern.test(postcode);
    }


//test
    const handleCardPayment = async () => {
        if (!stripe || !elements) {
            // console.log('Stripe has not loaded');
            return;
        }

        setIsLoading(true);
        const cardElement = elements.getElement('cardNumber');
        // console.log(cardElement);
        const postcodeElement = elements.getElement('postalCode');
        
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                address: {
                    postal_code: postcodeElement.value
                }
            }
        });
    
        if (error) {
            console.error('Error:', error);
            setMessage(error.message);
            setIsLoading(false);
        } else {
            // console.log('PaymentMethod:', paymentMethod);
            

            if (!isValidAustralianPostcode(paymentMethod.billing_details.address.postal_code)) {
                console.error('Invalid Australian postcode');
                setMessage('Please enter a valid Australian postcode');
                setIsLoading(false);
                return;
            }

            // Send PaymentMethod ID to the backend
            try {
                const response = await fetch(`${apiUrl}/api/create-payment-intent`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        paymentMethodId: paymentMethod.id,
                        items: [{ id: "xl-tshirt" }] // Replace with your items
                    }),
                });
    
                const responseData = await response.json();
                if (responseData.success) {
                    // console.log("Successful payment", responseData);
                } else {
                    console.error("Payment failed", responseData.error);
                    setMessage('Payment failed: ' + responseData.error);
                }
            } catch (error) {
                console.error('Error:', error);
                setMessage('An error occurred while processing your payment.');
            } finally {
                setIsLoading(false);
            }
        }
    };
    

    ///second test
    const user_agent = navigator.userAgent;

    const initiateAUBecsDebitPayment = () => {
        // Fetch IP address from a service
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => handleAUBecsDebitPayment(data.ip, user_agent))
            .catch(error => console.error('Error fetching IP:', error));
    };

    const handleAUBecsDebitPayment = async (ipAddress, userAgent) => {
        if (!stripe || !elements) {
            // console.log('Stripe has not loaded');
            return;
        }

        const mandateAgreement = document.getElementById('mandate-agreement').checked;
        if (!mandateAgreement) {
            alert("You must agree to the Direct Debit Request service agreement.");
            return;
        }
    
        setIsLoading(true);
        const bankAccountElement = elements.getElement('auBankAccount');
    
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'au_becs_debit',
            au_becs_debit: bankAccountElement,
            billing_details: {
                // Include billing details as necessary
                name: 'Jane Doe',
                email: 'jane.doe@example.com',
            },
            
            
        });

        if (error) {
            console.error('Error:', error);
            setMessage(error.message);
            setIsLoading(false);
        } else {
            // console.log('PaymentMethod:', paymentMethod);
            // Send PaymentMethod ID to the backend and handle the response
            // ...
            try {
                const response = await fetch(`${apiUrl}/api/create-payment-intent`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        paymentMethodId: paymentMethod.id,
                        mandateData: {
                            customer_acceptance: {
                                type: 'online',
                                online: {
                                    ip_address: ipAddress, // Customer's IP address
                                    user_agent: userAgent, // Customer's user agent
                                }
                            }
                        },
                        items: [{ id: "xl-tshirt" }] // Replace with your items
                    }),
                });
    
                const responseData = await response.json();
                if (responseData.success) {
                    // console.log("Successful payment", responseData);
                } else {
                    console.error("Payment failed", responseData.error);
                    setMessage('Payment failed: ' + responseData.error);
                }
            } catch (error) {
                console.error('Error:', error);
                setMessage('An error occurred while processing your payment.');
            } finally {
                setIsLoading(false);
            }
        }
    };
    

    const generatePurchaseOrder = () => {
    }

    useEffect(() => {
        const initializeStripe = async () => {
            const stripe = await stripePromise;
            const elements = stripe.elements();
            setStripe(stripe);
            setElements(elements);

            // Mount Stripe elements
            const cardNumberElement = elements.create('cardNumber');
            cardNumberElement.mount('#card-number-element-container');

            const cardCvvElement = elements.create('cardCvc');
            cardCvvElement.mount('#card-cvv-element-container');

            const cardExpiryElement = elements.create('cardExpiry');
            cardExpiryElement.mount('#card-expiry-element-container');

            const cardPostalElement = elements.create('postalCode');
            cardPostalElement.mount('#card-postal-element-container');


            const bankAccountElement = elements.create('auBankAccount');
            bankAccountElement.mount('#au-bank-account-element');
        }

        initializeStripe();
    }, []);

    const selectPaymentMethod = (paymentMethod) => {
        setSelectedPaymentMethod(paymentMethod === selectedPaymentMethod ? null : paymentMethod);
    };
    return (
    <div>
        <h2>Payment Details</h2>
        <div className="payment-options-container">
            <button 
                className={`payment-option ${selectedPaymentMethod === 'au_becs_debit' ? 'selected' : ''}`} 
                onClick={() => selectPaymentMethod('au_becs_debit')} 
                data-payment-method="au_becs_debit">
                <span className="radio-button"></span>
                Direct Debit
            </button>
            <button 
                className={`payment-option ${selectedPaymentMethod === 'pm_card_au' ? 'selected' : ''}`} 
                onClick={() => selectPaymentMethod('pm_card_au')} 
                data-payment-method="pm_card_au">
                <span className="radio-button"></span>
                Visa / Mastercard
            </button>
            <button 
                className={`payment-option ${selectedPaymentMethod === 'purchase-order' ? 'selected' : ''}`} 
                onClick={() => selectPaymentMethod('purchase-order')} 
                data-payment-method="purchase-order">
                <span className="radio-button"></span>
                Purchase Order
            </button>
        </div>
        <div className="payment-options">
            <div id="payment-form-au_becs_debit" style={{ display: selectedPaymentMethod === 'au_becs_debit' ? 'block' : 'none' }}>
                <div className="form-group">
                    <label htmlFor="au-bank-account-element">Bank Account</label>
                    <div id="au-bank-account-element" className="stripe-element form-group"></div>
                </div>
                <div>
                    <input type="checkbox" id="mandate-agreement" />
                    <label htmlFor="mandate-agreement">
                        I agree to the <a href="your-mandate-link">Direct Debit Request service agreement</a> and acknowledge that my account will be debited in accordance with it.
                    </label>
                </div>
                <div>
                    <input type="hidden" id="token" name="token"></input>
                    <button id="card-button" className="submit-button" onClick={initiateAUBecsDebitPayment}>Submit Payment test</button>
                </div>
            </div>
        </div>
        <div className="payment-options">
            <div id="payment-form-card" style={{ display: selectedPaymentMethod === 'pm_card_au' ? 'block' : 'none' }}>
                <div className="form-group">
                    <label>Card Number</label>
                    <div id="card-number-element-container"></div>
                </div>
                <div style={{'display': 'flex', 'width': '100%', 'gap': '10px'}}>
                    <div className="form-group" style={{'width': '100%'}}>
                        <label>CVV Number</label>
                        <div id="card-cvv-element-container"></div>
                    </div>
                    <div className="form-group" style={{'width': '100%'}}>
                        <label>Expiry Date</label>
                        <div id="card-expiry-element-container"></div>
                    </div>
                    <div className="form-group" style={{'width': '100%'}}>
                        <label>Post Code</label>
                        <div id="card-postal-element-container"></div>
                    </div>
                </div>
                <div>
                    <input type="hidden" id="token" name="token"></input>
                    <button id="card-button" className="submit-button" onClick={handleCardPayment}>Submit Payment</button>
                </div>
            </div>
        </div>
        <div className="payment-options">
            <div id="payment-form-purchase-order" style={{ display: selectedPaymentMethod === 'purchase-order' ? 'block' : 'none' }}>
                <div className="form-group">
                    <label htmlFor="client_purchase_order_number">Purchase Order Number</label>
                    <input type="text" id="purchase-order-number" placeholder="Enter ABN Number"></input>
                </div>
                <div className="form-group">
                    <label htmlFor="company_abn">ABN Number</label>
                    <input type="text" id="abnnumber" placeholder="Enter ABN Number"></input>
                </div>
                <div className="form-group">
                    <label htmlFor="delivery_instructions">Delivery Instructions</label>
                    <input type="text" id="delivery-instructions" placeholder="Additional Delivery Instructions"></input>
                </div>
                <div className="form-group">
                    <form id="purchase-order-form" method="POST">
                        <input type="hidden" name="item_names"></input>
                        <button id="generate-po-button" className="submit-button" onClick={generatePurchaseOrder}>Generate PO</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}
export default PaymentDetails




//<div className="form-group">
//                    <label htmlFor="bsbNumber">BSB Number</label>
//                    <input type="text" id="bsbNumber" placeholder="Enter BSB Number"></input>
//                </div>
//                <div className="form-group">
//                    <label value={bankAccountBSB} onChange={e => setBankAccountBSB(e.target.value)} htmlFor="accountNumber">Account Number</label>
//                    <input value={bankAccountNumber} onChange={e => setBankAccountNumber(e.target.value)} type="text" id="accountNumber" placeholder="Enter Account Number"></input>
//                </div>
