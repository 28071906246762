import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useFormDataContext, useStripeContext, useAuth, Button1 } from 'index';

function CheckoutConfirmation({ label, setFragment }) {
    const { user } = useAuth()
    const { formData, formData2, shippingMethod } = useFormDataContext()
    const [focusStates, setFocusStates] = useState({});
    const [formValues, setFormValues] = useState({
        0: `${user.email}`,
        1: `${formData.location} ${formData.locality} ${formData.administrative_area_level_1} ${formData.postal_code} ${formData.country}`,
        2: `${formData2.location} ${formData2.locality} ${formData2.administrative_area_level_1} ${formData2.postal_code} ${formData2.country}`,
        3: `${shippingMethod}`
    });

    const [fragmentIndexes] = useState({
        0: 0,
        1: 1,
        2: 2,
        3: 1,
    })

    const [warnings, setWarnings] = useState([]);
    const [errors, setErrors] = useState({}); // State to track errors for each field
    const [showWarn, setShowWarn] = useState(false);

    useEffect(() => {
        // console.log(warnings);
    }, [warnings]);

    const InputLabels = ['Email address', 'Delivery details', 'Billing details', 'Shipping choice'];

    const InputStyles = (isFocused, hasError) => ({
        //border: hasError ? '1px solid red' : isFocused ? '1px solid var(--brand-color-1)' : '1px solid #ccc',
        border: 'none',
        background: '#eee',
        padding: '8px 12px',
        outline: 'none',
        //transition: 'border 0.15s ease-in',
        //borderRadius: '4px',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'

    });

    const InputWrapperStyles = (isFocused, hasError) => ({
        border: hasError ? '1px solid red' : isFocused ? '1px solid var(--brand-color-1)' : '1px solid #ccc',
        overflow: 'hidden',
        background: '#eee',
        borderRadius: '4px',
        width: '100%',
    })

    const LabelInputStyle = {
        fontSize: '14px',
        fontFamily: 'var(--font-family-1)',
        fontWeight: 500,
        marginBottom: '5px',
    };

    const WarningStyle = {
        color: 'red',
        padding: '5px 0px',
        fontSize: '14px',
        fontFamily: 'var(--font-family-1)',
    };

    const handleFocus = (index, isFocused) => {
        setFocusStates((prev) => ({
            ...prev,
            [index]: isFocused,
        }));
    };

    const handleChange = (index, value) => {
        setFormValues((prev) => ({
            ...prev,
            [index]: value,
        }));
    };

    useEffect(() => {
        // console.log(formValues);
        validateForm();
    }, [formValues]);

    const validateForm = () => {
        const newWarnings = [];
        const newErrors = {}; // Track errors for specific fields
        const fieldValues = Object.values(formValues);

        InputLabels.forEach((label, index) => {
            if (!fieldValues[index]?.trim()) {
                newWarnings.push(`${label} is required.`);
                newErrors[index] = true; // Mark this field as having an error
            }
        });
        setWarnings(newWarnings);
        setErrors(newErrors); // Update the errors state
        setShowWarn(newWarnings.length > 0);
    };

    return (
        <>
            <div style={{ marginBottom: showWarn ? '0px' : '29px' }}>
                <div style={LabelInputStyle}>{label}</div>
                <div className='flex-col-5'>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {[...Array(InputLabels.length)].map((_, index) => (
                            <div className='flex-row-5' style={InputWrapperStyles(focusStates[index], errors[index])}>
                                <input
                                    key={index}
                                    defaultValue={formValues[index]}
                                    style={InputStyles(focusStates[index], errors[index])}
                                    onFocus={() => handleFocus(index, true)}
                                    onBlur={() => handleFocus(index, false)}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    placeholder={InputLabels[index]}
                                    disabled={true}
                                />
                                <div className='align-center'>
                                    {index !== 0 && (
                                        <span className='zp-font-m'>
                                            <Button1 label={'Change'} onClick={() => {
                                                setFragment(fragmentIndexes[index])
                                                //setSpecifyFragment(true)
                                            }} />
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {showWarn && warnings.length > 0 && (
                    <div style={WarningStyle}>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{ backgroundColor: 'white' }} />{' '}
                        {warnings[0]}
                    </div>
                )}
            </div>
        </>
    );
}

export default CheckoutConfirmation;
