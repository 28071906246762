import { useEffect, useState } from 'react';

export function useOverflow(ref: React.RefObject<HTMLElement>): {
  isOverflowingX: boolean;
  isOverflowingY: boolean;
} {
  const [isOverflowingX, setIsOverflowingX] = useState(false);
  const [isOverflowingY, setIsOverflowingY] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    const checkOverflow = () => {
      setIsOverflowingX(el.scrollWidth > el.clientWidth);
      setIsOverflowingY(el.scrollHeight > el.clientHeight);
    };

    checkOverflow(); // Initial check

    const resizeObserver = new ResizeObserver(checkOverflow);
    resizeObserver.observe(el);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return { isOverflowingX, isOverflowingY };
}
