import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ZephyrInnovaitionsLogo2 from './../../assets/Zephyr Innovations Logo 2 (5).svg';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import './css/Contact.css'

import { GenericInputBox2, useContactDetails } from 'index.js'
import { Button1, Button2, Button3, DropdownMenu } from 'index.js'
import { useFetch } from 'index.js';

export function Fragment0({ setDirection, formData, updateFormData }) {

    const navigate = useNavigate();

    return (
        <>
            <div className='justify-center flex-col-10' style={{ backgroundColor: 'white', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px', borderRadius: '10px', width: '100%' }}>
                <div className='flex-col-10' style={{ padding: '40px 40px 20px 40px' }}>
                    <span className='zp-font-xlarge zp-weight-m'>Let's get you to the right place</span>
                    <span className='zp-font-medium'>We just need a couple of details from you</span>
                </div>
                <div className='flex-col-5 justify-center' style={{ padding: '0px 40px', paddingBottom: '50px' }}>
                    <GenericInputBox2 label={'Work Email'} placeholder={'jane.doe@example.com'} defaultValue={formData.email} onChange={(newValue) => updateFormData({ email: newValue })} />
                </div>
                <div className='justify-end' style={{ padding: '0px 40px' }}>
                    <Button1 label={'Next'} onClick={() => { setDirection('next') }} />
                </div>
                <div className='zp-font-medium justify-center align-center' style={{ padding: '20px 15px', margin: '4px', backgroundColor: 'rgb(246, 249, 252)', borderRadius: '10px' }}>
                    <span style={{ paddingRight: '5px' }}>Already have an account? </span>
                    <Button1 label={'Login here'} onClick={() => { navigate('/login') }} />


                </div>
            </div>
        </>
    )
}

export function Fragment1({ setDirection, formOptions, updateFormOptions }) {

    const navigate = useNavigate();

    const labels = [
        'None, just getting started',
        'Less than A$150,000',
        'A$150,000 to A$1 million',
        'A$1 million to A$1.5 million',
        'A$1.5 million to A$2.5 million',
        'A$2.5 million to A$4 million',
        'A$4 million to A$15 million',
        'A$15 million to A$1.5 billion',
        'More than A$1.5 billion',
    ]


    function handleOptionClick(index, label) {
        updateFormOptions({ annualRevenue: labels[index] })
        setDirection('next')
    }


    return (
        <>
            <div className='justify-center flex-col-10' style={{ backgroundColor: 'white', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px', borderRadius: '10px', width: '100%' }}>
                <div className='flex-col-10' style={{ padding: '40px 40px 20px 40px' }}>
                    <span className='zp-font-xlarge zp-weight-m'>Whats your annual revenue?</span>
                    <span className='zp-font-medium'>Select the most accurate option for your annual business revenue.</span>
                </div>
                <div className='flex-col-5 justify-center' style={{ padding: '0px 40px', paddingBottom: '50px' }}>
                    {labels.map((label, index) => (
                        <Button2 key={index} onClick={() => { handleOptionClick(index, label) }} label={labels[index]} padding={'22px 16px'} />
                    ))}
                </div>
                <div className='justify-between' style={{ width: '100%', padding: '0px 40px' }} >
                    <Button1 label={'Back'} onClick={() => { setDirection('prev') }} />
                </div>
                <div className='zp-font-medium justify-center align-center' style={{ padding: '20px 15px', margin: '4px', backgroundColor: 'rgb(246, 249, 252)', borderRadius: '10px' }}>
                    <span style={{ paddingRight: '5px' }}>Already have an account? </span>
                    <Button1 label={'Login here'} onClick={() => { navigate('/login') }} />


                </div>
            </div>
        </>
    )
}

export function Fragment2({ setDirection, formOptions, updateFormOptions }) {

    const navigate = useNavigate();

    useEffect(() => {
        // console.log(formOptions);
    }, [formOptions])

    const labels = [
        'Fewer than 100',
        'Between 100 and 1,000',
        'More than 1,000',
    ]

    function handleOptionClick(index, label) {
        updateFormOptions({ headcount: labels[index] })
        setDirection('next')
    }

    return (
        <>
            <div className='justify-center flex-col-10' style={{ backgroundColor: 'white', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px', borderRadius: '10px', width: '100%' }}>
                <div className='flex-col-10' style={{ padding: '40px 40px 20px 40px' }}>
                    <span className='zp-font-xlarge zp-weight-m'>How many people work at your company?</span>
                    <span className='zp-font-medium'>Select the most accurate option for your current company size.</span>
                </div>
                <div className='flex-col-5 justify-center' style={{ padding: '0px 40px', paddingBottom: '50px' }}>

                    {labels.map((label, index) => (
                        <Button2 key={index} onClick={() => { handleOptionClick(index, label) }} label={labels[index]} padding={'22px 16px'} />

                    ))}

                </div>

                <div className='justify-between' style={{ width: '100%', padding: '0px 40px' }} >
                    <Button1 label={'Back'} onClick={() => { setDirection('prev') }} />

                </div>
                <div className='zp-font-medium justify-center align-center' style={{ padding: '20px 15px', margin: '4px', backgroundColor: 'rgb(246, 249, 252)', borderRadius: '10px' }}>
                    <span style={{ paddingRight: '5px' }}>Already have an account? </span>
                    <Button1 label={'Login here'} onClick={() => { navigate('/login') }} />


                </div>
            </div>
        </>
    )
}

export function Fragment3({ setDirection, formData, updateFormData, formOptions, updateFormOptions }) {
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(formOptions);
    }, [formOptions])

    const companyTypes = [
        "Equipment supplier",
        "Equipment manufacturer",
        "Engineering procurement construction firm",
        "Engineering consultancy",
        "Construction firm",
        "Fabricator"
    ]

    const jobFunctions = [
        "C-Suite",
        "Engineering & Technical",
        "Finance",
        "Education",
        "Customer Service",
        "Human Resources",
        "Information Technology",
        "Legal",
        "Marketing",
        "Operations",
        "Product",
        "Research",
        "Real Estate",
        "Recruiting",
        "Sales"
    ];

    const jobLevels = [
        "C-level",
        "VP/SVP/EVP",
        "Director",
        "Manager",
        "Non-Manager"
    ]

    const { data, error, loading, fetchData } = useFetch(
        "/api/testURL",
        {
            formData: formData,
            formOptions: formOptions
        },
        "POST"
    );

    function handleFormSubmission() {
        fetchData();
    }

    useEffect(() => {
        if (data || error || loading) {
            console.log("Data:", data);
            console.log("Error:", error);
            console.log("Loading:", loading);

        }
    }, [data, error]);

    return (
        <>
            <div className='justify-center flex-col-10' style={{ backgroundColor: 'white', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px', borderRadius: '10px', width: '100%' }}>
                <div className='flex-col-10' style={{ padding: '40px 40px 20px 40px' }}>
                    <span className='zp-font-xlarge zp-weight-m'>How can we reach you?</span>
                    <span className='zp-font-medium'>Please provide your contact information</span>
                </div>
                <div className='flex-col-0 justify-center' style={{ padding: '0px 40px', paddingBottom: '50px' }}>
                    <GenericInputBox2 label={'First name'} labelPosition={'left'} placeholder={'Jane'} onChange={(newValue) => updateFormData({ given_name: newValue })} defaultValue={formData.given_name} />
                    <GenericInputBox2 label={'Last name'} labelPosition={'left'} placeholder={'Doe'} onChange={(newValue) => updateFormData({ family_name: newValue })} defaultValue={formData.family_name} />
                    <GenericInputBox2 label={'Work phone'} labelPosition={'left'} placeholder={'Enter your phone number'} onChange={(newValue) => updateFormData({ contact_number: newValue })} defaultValue={formData.contact_number} />
                    <GenericInputBox2 label={'Company website'} labelPosition={'left'} placeholder={'example.com'} onChange={(newValue) => updateFormData({ company_website: newValue })} defaultValue={formData.company_website} />
                    <DropdownMenu options={companyTypes} placeholder={'Select a company type'} selectedOption={formOptions.companyType} setSelectedOption={(newValue) => updateFormOptions({ companyType: newValue })} labelPosition={'left'} label={'Company type'} />
                    <DropdownMenu options={jobFunctions} placeholder={'Select a job function'} selectedOption={formOptions.jobFunction} setSelectedOption={(newValue) => updateFormOptions({ jobFunction: newValue })} labelPosition={'left'} label={'Job function'} />
                    <DropdownMenu options={jobLevels} placeholder={'Select a job level'} selectedOption={formOptions.jobLevel} setSelectedOption={(newValue) => updateFormOptions({ jobLevel: newValue })} labelPosition={'left'} label={'Job level'} />
                </div>

                <div className='justify-between' style={{ width: '100%', padding: '0px 40px' }} >
                    <Button1 label={'Back'} onClick={() => { setDirection('prev') }} />
                    <Button3
                        label={'Submit'} onClick={() => { handleFormSubmission() }} padding={'5px 20px'} borderRadius={'20px'} />
                </div>
                <div className='zp-font-medium justify-center align-center' style={{ padding: '20px 15px', margin: '4px', backgroundColor: 'rgb(246, 249, 252)', borderRadius: '10px' }}>
                    <span style={{ paddingRight: '5px' }}>Already have an account? </span>
                    <Button1 label={'Login here'} onClick={() => { navigate('/login') }} />


                </div>
            </div>
        </>
    )
}

function Contact() {

    const navigate = useNavigate();

    //const [fragmentIndex, setFragmentIndex] = useState(0);
    const [direction, setDirection] = useState('')


    useEffect(() => {
        if (direction === 'next') {
            setFragmentIndex(fragmentIndex + 1)


            const timeout = setTimeout(() => {
                setDirection('')
            }, 800);
            return () => clearTimeout(timeout);
        } else if (direction === 'prev') {
            setFragmentIndex(fragmentIndex - 1)
            const timeout = setTimeout(() => {
                setDirection('')
            }, 800);
            return () => clearTimeout(timeout);
        }
    }, [direction])

    const { formData, updateFormData, fragmentIndex, setFragmentIndex, formOptions, updateFormOptions } = useContactDetails();
    useEffect(() => {
        // console.log(formData);
    }, [formData])

    const fragments = [
        <Fragment0 setDirection={setDirection} key={0} formData={formData} updateFormData={updateFormData} />,
        <Fragment1 setDirection={setDirection} key={1} formOptions={formOptions} updateFormOptions={updateFormOptions} />,
        <Fragment2 setDirection={setDirection} key={2} formOptions={formOptions} updateFormOptions={updateFormOptions} />,
        <Fragment3 setDirection={setDirection} key={3} formData={formData} updateFormData={updateFormData} formOptions={formOptions} updateFormOptions={updateFormOptions} />
    ];

    return (
        <>
            <div className='justify-center' style={{ width: '100%', height: 'var(--vh)', overflowY: 'auto', scrollbarWidth: 'thin', backgroundColor: 'rgb(246, 249, 252)' }}>
                <div style={{ height: 'var(--vh)', maxWidth: '1080px', width: '100%' }}>
                    <div className='flex-col-10 justify-center align-center' style={{ maxWidth: '1080px', width: '100%', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd', height: '100%', minHeight: fragmentIndex === 1 ? '1100px' : '700px' }}>
                        <div className='contacts-wrapper flex-col-10 justify-start ' style={{ height: '100%', width: '100%', alignItems: 'center', position: 'relative' }}>
                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', overflow: 'hidden' }}>
                                <div style={{ width: '100%', height: '68px', padding: '20px 10px' }} onClick={() => { navigate('/') }}>
                                    <img style={{ height: '20px' }} src={ZephyrInnovaitionsLogo2}></img>
                                </div>
                            </div>
                            <div className='flex-row-0' style={{ width: '100%', padding: '0px 20px' }}>
                                <div className='flex-col-40 contacts-left-panel' style={{ padding: '0px 112px 0px 16px', width: '100%' }}>
                                    <div style={{ fontFamily: 'var(--font-family-1)', fontSize: '56px', letterSpacing: '-0.02em', fontWeight: 500 }}>Help us route your enquiry</div>
                                    <div className='zp-font-large' style={{}}>Tell us about your business and we'll get you to the right place. If your business purchases a high volume of engineering equipment and has advanced integration needs, our sales team would be happy to guide you. </div>
                                    <div className='zp-font-large' style={{}}>If your business supplies engineering equipment to customers, we would love to talk to you! </div>
                                </div>
                                <div className='align-start' style={{ width: '100%', position: 'relative', height: '300px' }}>
                                    <TransitionGroup component={null}>
                                        <CSSTransition
                                            key={`${fragmentIndex}`}
                                            classNames={`fragment-transition-${direction}`}

                                            timeout={{
                                                enter: 800, // Delay for incoming fragment
                                                exit: 300, // Animation duration for outgoing fragment
                                            }}
                                        >
                                            {fragments[fragmentIndex]}
                                        </CSSTransition>
                                    </TransitionGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Contact


// <span className='zp-weight-m' style={{ color: 'var(--brand-color-1)', paddingRight: '5px' }} onClick={() => { navigate('/login') }}>Login here</span>




//<Button2 onClick={() => { setDirection('next') }} label={'None, just getting started'} padding={'22px 16px'} />
//<Button2 onClick={() => { setDirection('next') }} label={'Less than A$150,000'} padding={'22px 16px'} />
//<Button2 onClick={() => { setDirection('next') }} label={'A$150,000 to A$1 million'} padding={'22px 16px'} />
//<Button2 onClick={() => { setDirection('next') }} label={'A$1 million to A$1.5 million'} padding={'22px 16px'} />
//<Button2 onClick={() => { setDirection('next') }} label={'A$1.5 million to A$2.5 million'} padding={'22px 16px'} />
//<Button2 onClick={() => { setDirection('next') }} label={'A$2.5 million to A$4 million'} padding={'22px 16px'} />
//<Button2 onClick={() => { setDirection('next') }} label={'A$4 million to A$15 million'} padding={'22px 16px'} />
//<Button2 onClick={() => { setDirection('next') }} label={'A$15 million to A$1.5 billion'} padding={'22px 16px'} />
//<Button2 onClick={() => { setDirection('next') }} label={'More than A$1.5 billion'} padding={'22px 16px'} />//
//
//

//<Button2 onClick={() => { setDirection('next') }} label={'Fewer than 100'} padding={'22px 16px'} />
//                    <Button2 onClick={() => { setDirection('next') }} label={'Between 100 and 1,000'} padding={'22px 16px'} />
//                    <Button2 onClick={() => { setDirection('next') }} label={'More than 1,000'} padding={'22px 16px'} />