import React, {useState, useEffect, useRef, useMemo, } from 'react'
import './css/deleteparts.css'
function DuplicateParts({partNumbers, setSelection}) {
    
    const modalStyle = {
        zIndex: 998,
        position: 'absolute',
        backgroundColor: 'var(--modal-background-color)',
        height: '100%',
        width: '100%',
        top : 0,
        left: 0
    }
    const modalContent = {
        zindex: 999,
        position: 'absolute',
        border: '1px solid white',
        backgroundColor: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
       
        width: '400px',
        borderRadius: '8px',
        padding: '16px'
    }
    const modalWrapper = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
    const modalButtons = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        gap: '10px',
        padding: '8px 16px',
        marginTop: '24px'
    }
    function handleDeleteModal() {
        setSelection('')
    }
    function handleModalContentClick(event) {
        // Prevent click event from propagating to the parent
        event.stopPropagation();
    }
    function handleDelete() {
        // console.log('delete');
    }

    return (
        <>   
            <div style={modalStyle} onClick={handleDeleteModal}>
                <div style={modalContent} onClick={handleModalContentClick}>
                    <div style={modalWrapper}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                            <div className='modal-header'>Duplicate {partNumbers > 1 ? 'these' : 'this'} part{partNumbers > 1 ? 's' : ''}?</div>
                            <div className='modal-text-content'>This will also copy attached documents, images and part details.</div>
                        </div>
                        <div style={modalButtons}>  
                            <span className='cancel-button' onClick={handleDeleteModal}>Cancel</span>
                            <span className='delete-button' onClick={handleDelete}>Duplicate</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default DuplicateParts
