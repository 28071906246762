import React, {useState, useEffect, useRef, useMemo, } from 'react'
import './css/deleteparts.css'
import './css/UploadComponent.css'
import NoContent from './NoContent.tsx'
import DragAndDropZone from './DragAndDropZone.jsx'
import DragAndDropZone2 from './DragAndDropZone2.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faFile as faFileRegular } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

function UploadDocuments({partNumbers, setSelection2}) {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredRow2, setHoveredRow2] = useState(null);
  const [hoveredDiv, setHoveredDiv] = useState(false);

  const MAX_FILE_SIZE = 3 * 1024 * 1024;

  const modalStyle = {
      zIndex: 998,
      position: 'absolute',
      backgroundColor: 'var(--modal-background-color)',
      height: '100%',
      width: '100%',
      top : 0,
      left: 0
  }
  const modalContent = {
      zindex: 999,
      position: 'absolute',
      border: '1px solid white',
      backgroundColor: 'white',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      width: '400px',
      borderRadius: '8px',
      padding: '16px'
  }
  const modalWrapper = {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
  }
  const modalButtons = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 'auto',
      gap: '10px',
      padding: '8px 0px',
      marginTop: '24px'
  }
  function handleDeleteModal() {
      setSelection2('')
  }
  function handleModalContentClick(event) {
      // Prevent click event from propagating to the parent
      event.stopPropagation();
  }
  function handleDelete() {
      // console.log('delete');
  }

  const [droppedFiles, setDroppedFiles] = useState([]);
  const [droppedFiles2, setDroppedFiles2] = useState([]);

  useEffect(() => {
    // console.log(droppedFiles);
  },[droppedFiles])

  useEffect(() => {
    // console.log(droppedFiles2);
  },[droppedFiles2])


  function handleRemoveFile(filelist, index) {
    if (filelist === 'files2') {
      setDroppedFiles2((prevDroppedFiles) => 
        prevDroppedFiles.filter((_, i) => i !== index)
      );
    }
    if (filelist === 'files1') {
      setDroppedFiles((prevDroppedFiles) => 
        prevDroppedFiles.filter((_, i) => i !== index)
      );
    }
  }
  
    return (
        <>
          <div style={modalStyle} onClick={handleDeleteModal}>
              <div style={modalContent} onClick={handleModalContentClick}>
                  <div style={modalWrapper}>
                      <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div className='modal-header'>Attach documents to part{partNumbers > 1 ? 's' : ''}</div>
                        <div className='modal-text-content' style={{fontSize: '14px', fontStyle: 'Arial'}}>Upload your files here. They will be attached to all the parts selected.</div>




                        <div style={{borderBottom: '1px #d8dee4 solid', fontSize: '14px', fontStyle: 'Arial'}}>Upload Technical Documents</div>

                        <div style={{display: droppedFiles.length === 0 ? 'none':''}}>
                          <div 
                            style={{border: '1px #ccc solid', borderBottom: 'none', borderRadius: '8px 8px 0px 0px', height: '115px', overflowY: hoveredDiv ? 'auto': 'hidden', scrollbarWidth:'thin', padding: '4px', display: droppedFiles.length === 0 ? 'none':''}}
                            onMouseEnter={() => setHoveredDiv(true)}
                            onMouseLeave={() => setHoveredDiv(false)}
                          >
                            {droppedFiles.length > 0 && droppedFiles.map((item, index) => (
                              <>
                                <div key={index} className='dropped-files-row'
                                  onMouseEnter={() => setHoveredRow(index)}
                                  onMouseLeave={() => setHoveredRow(null)}
                                >
                                  <span style={{display:'flex',alignItems:'center',justifyContent: 'center'}}>
                                  {hoveredRow === index ? (
                                    <FontAwesomeIcon icon={faXmark} onClick={() => {handleRemoveFile('files1',index)}}/>
                                  ) : (
                                    <FontAwesomeIcon icon={faFileRegular} />
                                  )}
                                  </span>
                                  <span style={{fontSize: '14px', fontStyle: 'Arial',overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis',width:'100%'}}>{item.name}</span>
                                  <span style={{fontSize: '14px', fontStyle: 'Arial',whiteSpace: 'nowrap', minWidth: '50px', justifyContent: 'right', display:'flex'}}>
                                    {item.size / 1000 < 100 ? (
                                      `${(item.size / 1000).toFixed(2)} kB`
                                    ) : (
                                      `${(item.size / 1000000).toFixed(2)} MB`
                                    )}
                                  </span>
                                  
                                </div>
                              </>
                            ))}
                          </div>
                          <div>
                            {droppedFiles.length > 0 && (
                              <DragAndDropZone2
                                onDrop={(files) => setDroppedFiles((prev) => [...prev, ...files])}
                                hoveringText="Drop your Technical files here!"
                                defaultText="Add more files here"
                                maxFileSize={MAX_FILE_SIZE}
                                height={'35px'}
                              />
                            )}
                          </div>
                        </div>
                        {droppedFiles.length === 0 && (
                          <DragAndDropZone
                            onDrop={(files) => setDroppedFiles((prev) => [...prev, ...files])}
                            hoveringText="Drop your Technical files here!"
                            defaultText="Drag & Drop Technical files here or click to upload"
                            maxFileSize={MAX_FILE_SIZE}
                            height={'150px'}
                          />
                        )}
                              





                        <div style={{borderBottom: '1px #d8dee4 solid', fontSize: '14px', fontStyle: 'Arial'}}>Upload CAD Files</div>
                        
                        
                        
                        <div style={{display: droppedFiles2.length === 0 ? 'none':''}}>
                          <div 
                            style={{border: '1px #ccc solid', borderBottom: 'none', borderRadius: '8px 8px 0px 0px', height: '115px', overflowY: hoveredDiv ? 'auto': 'hidden', scrollbarWidth:'thin', padding: '4px', display: droppedFiles2.length === 0 ? 'none':''}}
                            onMouseEnter={() => setHoveredDiv(true)}
                            onMouseLeave={() => setHoveredDiv(false)}
                          >
                            {droppedFiles2.length > 0 && droppedFiles2.map((item, index) => (
                              <>
                                <div key={index} className='dropped-files-row'
                                  onMouseEnter={() => setHoveredRow2(index)}
                                  onMouseLeave={() => setHoveredRow2(null)}
                                >
                                  <span style={{display:'flex',alignItems:'center',justifyContent: 'center'}}>
                                  {hoveredRow2 === index ? (
                                    <FontAwesomeIcon icon={faXmark} onClick={() => {handleRemoveFile('files2',index)}}/>
                                  ) : (
                                    <FontAwesomeIcon icon={faFileRegular} />
                                  )}
                                  </span>
                                  <span style={{fontSize: '14px', fontStyle: 'Arial',overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis',width:'100%'}}>{item.name}</span>
                                  <span style={{fontSize: '14px', fontStyle: 'Arial',whiteSpace: 'nowrap', minWidth: '50px', justifyContent: 'right', display:'flex'}}>
                                    {item.size / 1000 < 100 ? (
                                      `${(item.size / 1000).toFixed(2)} kB`
                                    ) : (
                                      `${(item.size / 1000000).toFixed(2)} MB`
                                    )}
                                  </span>
                                  
                                </div>
                              </>
                            ))}
                          </div>
                        <div>
                        {droppedFiles2.length > 0 && (
                          <DragAndDropZone2
                            onDrop={(files) => setDroppedFiles2((prev) => [...prev, ...files])}
                            hoveringText="Drop your CAD files here!"
                            defaultText="Add more files here"
                            maxFileSize={MAX_FILE_SIZE}
                            height={'35px'}
                          />
                        )}
                        </div>
                        </div>
                        {droppedFiles2.length === 0 && (
                          <DragAndDropZone
                            onDrop={(files) => setDroppedFiles2((prev) => [...prev, ...files])}
                            hoveringText="Drop your CAD files here!"
                            defaultText="Drag & Drop CAD files here or click to upload"
                            maxFileSize={MAX_FILE_SIZE}
                            height={'150px'}
                          />
                        )}
                        

                      </div>
                      <div style={modalButtons}>  
                          <span className='cancel-button' onClick={handleDeleteModal}>Cancel</span>
                          <span className='delete-button' onClick={handleDelete}>Upload</span>
                      </div>
                  </div>
              </div>
          </div>
        </>
    )

}

export default UploadDocuments