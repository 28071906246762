import React, {useEffect, useState, useCallback} from 'react'

//import ChipInputBoxWithSelector from './ChipInputBoxWithSelectorComponent.jsx'

import InputBoxComponent from './ChipInputBoxComponent.tsx'
import InputBoxCompoennt2 from './ChipInputBoxComponent2.tsx'
import InputBoxComponentWithSelector from './ChipInputBoxWithSelectorComponent.tsx'

function ModifyTechnicalDetails({setSelection2, partNumbers, filterRows, headers, setSelection}) {

    useEffect(() => {
        setSelection('')

    },[])
    
    const modalStyle = {
        zIndex: 998,
        position: 'absolute',
        backgroundColor: 'var(--modal-background-color)',
        height: '100%',
        width: '100%',
        top : 0,
        left: 0,
    }
    const modalContent = {
        zindex: 999,
        position: 'absolute',
        border: '1px solid white',
        backgroundColor: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        width: '400px',
        height: '600px',
        borderRadius: '8px',
        padding: '16px'
    }
    const modalWrapper = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
    const modalButtons = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        gap: '10px',
        padding: '8px 0px',
        marginTop: '24px'
    }

    function handleDeleteModal(event) {
        //if (isFocused) {
        //    event.stopPropagation()
        ////} else {
            setSelection2('')
       // }
        
    }

    function handleModalContentClick(event) {
        // Prevent click event from propagating to the parent
        event.stopPropagation();
    }

    function handleDelete() {
        // console.log('delete');
    }




    const [values, setValues] = useState({});
    const [units, setUnits] = useState({});


    const handleValueChange = useCallback(
        (key) => (value) => {
          setValues((prevValues) => ({ ...prevValues, [key]: value }));
        },
        []
      );
    
    const handleUnitChange = useCallback(
      (key) => (unit) => {
        setUnits((prevUnits) => ({ ...prevUnits, [key]: unit }));
      },
      []
    );

    useEffect(() => {
        // console.log(values);
    },[values])


    return (
        <>

        <div style={modalStyle} onClick={(e) => {handleDeleteModal(e)}}>
            <div style={modalContent} onClick={handleModalContentClick}>
                <div style={modalWrapper}>         
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px', height: '100%', overflow: 'auto'}}>
                        <div className='modal-header'>Modify part{partNumbers > 1 ? 's' : ''} configuration{partNumbers > 1 ? 's' : ''}</div>
                        <div className='modal-text-content' style={{fontSize: '14px', fontStyle: 'Arial'}}>Modify part configurations here. They will be applied to all the parts selected.</div>


                        <div>
                        {headers.map((item, index) => {
                            switch (item) {
                                case 'operatingconditions':
                                    // console.log(filterRows[index]);
                                    return Object.entries(filterRows[index]).map(([key, selector], index2) => {
                                        if (selector) {
                                            // console.log(selector.units, selector.label, selector.selectors);
                                            return (

                                                ///the problem is this component
                                                <InputBoxComponentWithSelector
                                                    key={index2}
                                                    value={values[key] || ''}       // Adjust this according to your data
                                                    setValue={handleValueChange(key)}    // Provide a function to set value
                                                    unit={units[key] || ''}        // Assuming 'units' is in your data
                                                    setUnit={handleUnitChange(key)}    // Provide a function to set unit
                                                    label={selector.label || ''}       // Use label for display
                                                    selectors={selector.selectors || ''}
                                                />
                                            );
                                            
                                        }
                                        return null;
                                    })
                                }
                            })}
                        </div>




                    </div>
                    <div style={modalButtons}>  
                      <span className='cancel-button' onClick={handleDeleteModal}>Cancel</span>
                      <span className='delete-button' onClick={handleDelete}>Save</span>
                    </div>
                    </div>
                    </div>
                    </div>

        </>
    )


}


export default ModifyTechnicalDetails