import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton';
import styles from './css/PulseSkeleton.module.css';
import { SkeletonTheme } from 'react-loading-skeleton';
function Skeletons() {

}


export function Skeleton1() {


    return (
        <>
            <tr style={{ borderBottom: '1px solid #d8dee4' }}>
                <div style={{ backgroundColor: '#fff', padding: '8px', maxHeight: '52px', height: '52px' }}>
                    <Skeleton width={'100%'} height={'100%'} />
                </div>
            </tr>
        </>
    )
}

export function Skeleton2() {
    return (
        <>
            <div style={{width: '100px', height: '100px'}}>
                <Skeleton width={'100%'} height={'100%'} />
            </div>
        </>
    )
}

export function Skeleton3() {
    return (
        <>
            <div style={{width: '100%', height: '100%', fontSize: '12px'}}>
                <Skeleton count={6} width={'100%'} height={'100%'} />
            </div>
        </>
    )
}

export default Skeletons