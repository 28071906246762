import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//Components
export { default as ToggleSwitch } from './components/header/customerHeader/ToggleSwitch.jsx';



//Navigation
export * from './pages/pagesindex.js';
export * from './generalComponents/generalcomponentsindex.js'

//General Components
export { default as SparkLine } from './generalComponents/SparkLine.js'
export { default as Time } from './generalComponents/Time.js'
export { default as DatePicker } from './generalComponents/DatePicker.js'
export { default as DateRangePicker } from './generalComponents/DateRangePicker.js'
export { default as TabNavigation } from './generalComponents/TabNavigation.tsx'
export { default as FitlerBox } from './generalComponents/FilterBox'
export { default as GenericInputBox2 } from './generalComponents/GenericInputBox2'
export { default as GenericBusinessDetails } from './generalComponents/GenericBusinessDetails.js'
export { default as StateDropdownMenu } from './generalComponents/StateDropdownMenu'
export { default as NumberPicker } from './components/body/supplierBody/supplierEquipment/GenericComponents/NumberInputComponent.jsx'
export { default as NoContent} from './components/body/supplierBody/supplierEquipment/GenericComponents/NoContent.tsx'


//Contexts
export { AuthProvider, useAuth } from './contexts/AuthContext.js';
export { useToggle } from './contexts/ToggleContext.js';
export { CheckoutProvider2, useFormDataContext, useStripeContext, useAutocompleteContext, useCheckoutImageContext, useCartDataContext } from './contexts/CheckoutContext.js'
export { AlertProvider, AlertContext } from './components/body/customerBody/AlertContext.js'
export { useContactDetails, ContactDetailsProvider } from './contexts/ContactDetailsContext.js'

//Buttons

export { default as StartNow } from './components/landingPage/StartNow.js'

//Hooks
export { useGoogleMaps } from './components/checkout/hook/useGoogleMaps.js'
export { default as useFetch } from './hooks/useFetch.jsx'
export { useOverflow } from './hooks/useOverflow.tsx'

//Other
export { default as CustomAlert } from './components/CustomAlertComponent.js'

//Footer
export { default as GenericFooter } from './generalComponents/Footers/GenericFooters.js'



// Set CSS variable for viewport height
const updateHeight = () => {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight}px`);
};

// Set the initial height before rendering
updateHeight();
window.addEventListener("resize", updateHeight);

// Cleanup event listener when the page unloads
window.addEventListener("beforeunload", () => {
  window.removeEventListener("resize", updateHeight);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <App />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

