import React, { useState, useEffect } from 'react'
import styles from './tables.module.css'

function Tables() {
    return (
        <>
        </>
    )
}

export function Table1({ children, children_small, ref }) {
    return (
        <>
            <table ref={ref ? ref : null} className={styles['table-style']}>
                {children}
            </table>
            <div ref={ref ? ref : null} className={styles['table-style-small']}>
                {children_small}
            </div>
        </>
    )
}

export default Tables