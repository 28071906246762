import React, { useState, useEffect, useRef } from 'react'
//import './App.css'
import './OrderHistory.css'
import './OrderDetail.css'
import { DivWrapper2, Table1, useOverflow, useAuth } from 'index'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faMoneyBills } from '@fortawesome/free-solid-svg-icons';

import { isMobile } from "react-device-detect";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NumberPicker, NoContent } from 'index.js';
//import { useCartDataContext, useCheckoutImageContext } from 'index.js'

function OrderHistory() {

    return (
        <>
            <Routes>
                <Route path="" element={<DivWrapper2 children={<OrderHistoryWrapper />} />} />
                <Route path=":orderId" element={<DivWrapper2 children={<OrderDetail />} />} />
            </Routes>

        </>
    )
}

export default OrderHistory

export function GreenChip({ label }) {
    return (
        <>

            <span
                className='zp-weight-m'
                style={{
                    textWrap: 'nowrap',
                    textAlign: 'left',
                    fontSize: '12px',
                    padding: '1px 6px',
                    color: 'green',
                    backgroundColor: 'rgb(207, 245, 246)'
                }}>
                {label}
            </span>

        </>
    )
}

export function BlueChip({ label }) {
    return (
        <>
            <span
                className='zp-weight-m'
                style={{
                    textWrap: 'nowrap',
                    textAlign: 'left',
                    fontSize: '12px',
                    padding: '1px 6px',
                    color: 'rgb(0, 85, 188)',
                    backgroundColor: 'rgb(207, 245, 246)'
                }}>
                {label}
            </span>


        </>
    )
}

export function OrderHistoryWrapper({ }) {
    const containerRef = useRef(null)
    const { isOverflowingX, isOverflowingY } = useOverflow(containerRef);
    useEffect(() => {
        console.log(isOverflowingX, isOverflowingY)
    }, [isOverflowingX, isOverflowingY])

    return (
        <>

            <div className='zp-w-100 zp-h-100' style={{ padding: '16px', borderTop: '1px solid #ccc' }}>
                <div ref={containerRef} className='zp-w-100 flex-col-10  zp-h-100' style={{ margin: '0px auto', maxWidth: '1300px' }}>
                    <div>
                        <span className='zp-font-xxxl zp-weight-l'>Order History</span>
                    </div>
                    <div>
                        <div className='flex-row-10 order-history-navigation'>
                            <OrderHistoryButton label='All' value='1200' />
                            <OrderHistoryButton label='Succeeded' value='1100' />
                            <OrderHistoryButton label='Pending' value='100' />
                            <OrderHistoryButton label='Failed' value='100' />
                        </div>
                    </div>
                    <div>
                        <div className='order-history-table'>
                            <CustomerOrderHistoryTable isOverflowingY={isOverflowingY} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function OrderHistoryButton({ label, value }) {

    return (
        <>
            <div className='order-history-button'>
                <div className='flex-col-10'>
                    <span className='zp-font-m'>{label}</span>
                    <span className='zp-font-l zp-weight-l'>{value}</span>
                </div>
            </div>
        </>
    )
}

export function CustomerOrderHistoryTable({ isOverflowingY }) {
    // Generate 20 rows of fake data
    const navigate = useNavigate()
    const { user } = useAuth()
    function handleNavClick(orderId) {
        let targetPath = '';
        if (user && user.userType === 'supplier') {
            targetPath = `/supplier/orders/${orderId}`;
            navigate(targetPath);
        } else if (user && user.userType === 'customer') {
            targetPath = `/customer/orders/${orderId}`;
            navigate(targetPath);
        }
    }

    const formatDate = (date) =>
        date
            .toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })
            .replace('AM', 'am')
            .replace('PM', 'pm');

    // Generate 20 rows of fake data
    const fakeData = Array.from({ length: 20 }, (_, idx) => {
        // Generate a UUID for the order ID
        const orderId = `oid_${uuidv4()}`;

        // Create fixed dates
        const orderDate = formatDate(new Date(2024, 3, 9, 14, 20)); // April 9, 2024, 2:20pm
        const paymentDueDate = formatDate(new Date(2024, 4, 8, 14, 20)); // May 8, 2024, 2:20pm

        return {
            amount: `$${(Math.random() * 10000).toFixed(2)}`,
            currency: "AUD",
            status: "Succeeded",
            orderId,
            customer: `customer${idx + 1}@example.com`,
            orderDate,
            paymentTerms: "30 Days",
            paymentDueDate,
        };
    });

    return (
        <>
            <div style={{ overflowX: 'auto', overflowY: 'auto', scrollbarWidth: 'thin', }}>
                <Table1
                    children={
                        <>
                            <thead>
                                <tr>
                                    <td>AMOUNT</td>
                                    <td></td>
                                    <td></td>
                                    <td><span>ORDER ID</span></td>
                                    <td><span>CUSTOMER</span></td>
                                    <td><span>ORDER DATE</span></td>
                                    <td><span>PAYMENT TERMS</span></td>
                                    <td><span>PAYMENT DUE DATE</span></td>
                                </tr>
                            </thead>
                            <tbody>
                                {fakeData.map((order, index) => (
                                    <tr key={index} onClick={() => { handleNavClick(order.orderId) }}>
                                        <td>{order.amount}</td>
                                        <td>{order.currency}</td>
                                        <td>
                                            <GreenChip label={order.status} />
                                        </td>
                                        <td>{order.orderId}</td>
                                        <td>{order.customer}</td>
                                        <td>{order.orderDate}</td>
                                        <td className='justify-center'>
                                            <BlueChip label={order.paymentTerms} />
                                        </td>
                                        <td>{order.paymentDueDate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </>
                    }
                    children_small={
                        <>
                            {fakeData.map((order, index) => (
                                <div key={index} className='flex-col-5' style={{ padding: '8px', }} onClick={() => { handleNavClick(order.orderId) }}>
                                    <div className='justify-between zp-w-100'>
                                        <span className='flex-row-5 zp-font-l'>
                                            <span className='zp-weight-m'>{order.amount}</span>
                                            <span>{order.currency}</span>
                                        </span>
                                        <span>
                                            <GreenChip label={order.status} />
                                        </span>
                                    </div>
                                    <div className='zp-font-s flex-col-5'>
                                        <div className='flex-row-5'>
                                            <span className='ts-th nowrap'>ORDER ID:</span>
                                            <span className='ellipsis'>{order.orderId}</span>
                                        </div>
                                        <div className='flex-row-5'>
                                            <span className='ts-th nowrap'>CUSTOMER:</span>
                                            <span className='ellipsis'>{order.customer}</span>
                                        </div>
                                        <div className='flex-row-5'>
                                            <span className='ts-th nowrap'>ORDER DATE:</span>
                                            <span className='ellipsis'>{order.orderDate}</span>
                                        </div>
                                        <div className='flex-row-5'>
                                            <span className='ts-th nowrap'>DUE DATE:</span>
                                            <span className='ellipsis'>{order.paymentDueDate}</span>
                                        </div>
                                        <div className='flex-row-5'>
                                            <span className='ts-th nowrap'>PAYMENT TERMS:</span>
                                            <span className='ellipsis'> <BlueChip label={order.paymentTerms} /></span>
                                        </div>
                                        <div className='flex-row-5'>
                                            <span className='ts-th nowrap'></span>
                                            <span className='ellipsis'></span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                >
                </Table1>
            </div>
            <div
                className='zp-font-m'
                style={{
                    position: 'sticky',
                    bottom: '0px',
                    padding: '8px 0px',
                    backgroundColor: '#ffffff',
                    borderTop: '1px #ddd solid',
                    //
                }}
            >
                Viewing <span className='zp-weight-m'>{fakeData.length}</span> of{' '}
                <span className='zp-weight-m'>{fakeData.length}</span> results
            </div>
        </>
    );
}

export function OrderDetail({ }) {
    const { orderId } = useParams();

    const order = {
        "amount": "$5834.27",
        "currency": "AUD",
        "status": "Succeeded",
        "orderId": "oid_3f1c9bfe-9c2a-4a2a-8f5a-7d9e4f2c3d1b",
        "customer": "customer42@example.com",
        "orderDate": "April 9, 2024, 2:20pm",
        "paymentTerms": "30 Days",
        "paymentDueDate": "May 8, 2024, 2:20pm",
        "paymentId": "oid_3f1c9bfe-9asdfa-7d9e4f2c3d1b",
    }


    return (
        <>
            <div className='zp-w-100 zp-h-100' style={{ padding: '16px', borderTop: '1px solid #ccc' }}>
                <div className='zp-w-100 flex-col-5  zp-h-100' style={{ margin: '0px auto', maxWidth: '1300px' }}>
                    <div className='flex-row-5 align-center'>
                        <span className='align-center'><FontAwesomeIcon icon={faMoneyBills} fontSize={'11px'} color='#687385' /></span>
                        <span className='zp-font-s zp-weight-m' style={{ color: '#687385' }}>ORDER</span>
                    </div>
                    <div className='zp-w-100 flex-row-5 align-center'>
                        <span className='zp-font-xxl zp-weight-l'>{order.amount}</span>
                        <span className='zp-font-xxl'>{order.currency}</span>
                        <span>
                            <GreenChip label={order.status} />
                        </span>
                    </div>
                    <div className='order-detail-grid'>
                        <div className='recent-activity'>
                            <div className='flex-col-10'>
                                <span className='zp-weight-l zp-font-xl' style={{ borderBottom: '1px solid #ddd' }}>Recent Activity</span>
                                <div className='flex-row-40'>
                                    <div className='flex-col-20'>
                                        <ActivityStatus status={'Order started'} date={'Apr 9, 2024, 2:25 PM'} />
                                        <ActivityStatus status={'Order confirmed'} date={'Apr 9, 2024, 2:25 PM'} />
                                    </div>
                                    <div className='flex-col-20'>
                                        <ActivityStatus status={'Payment started'} date={'Apr 9, 2024, 2:25 PM'} />
                                        <ActivityStatus status={'Payment confirmed'} date={'Apr 9, 2024, 2:25 PM'} paymentTerms={order.paymentTerms} />
                                        <ActivityStatus status={'Payment succeeded'} date={'Apr 9, 2024, 2:25 PM'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='details'>
                            <div className='flex-col-10'>
                                <span className='zp-weight-l zp-font-xl' style={{ borderBottom: '1px solid #ddd' }}>Details</span>
                                <div className='flex-col-20 '>
                                    <div className='flex-col-5'>
                                        <span className='zp-weight-m zp-font-m'>Payment ID</span>
                                        <span className='zp-font-m ellipsis'>{order.paymentId}</span>
                                    </div>
                                    <div className='flex-col-5'>
                                        <span className='zp-weight-m zp-font-m'>Order ID</span>
                                        <span className='zp-font-m ellipsis'>{order.orderId}</span>
                                    </div>
                                    <div className='flex-col-5'>
                                        <span className='zp-weight-m zp-font-m'>Invoice</span>
                                        <span className='zp-font-m  download-link'>
                                            <a href='#' download>
                                                <FontAwesomeIcon icon={faFile} />
                                                <span className='ellipsis'>{order.orderId}</span>
                                            </a>
                                        </span>
                                    </div>
                                    <div className='flex-col-5'>
                                        <span className='zp-weight-m zp-font-m'>Last Updated</span>
                                        <span className='zp-font-m ellipsis'>{order.orderDate}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='payment-breakdown'>
                            <div className='flex-col-10'>
                                <span className='zp-weight-l zp-font-xl' style={{ borderBottom: '1px solid #ddd' }}>Payment Breakdown</span>
                                <div className='breakdown-grid-2'>
                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>Subtotal</span>
                                    <span className='zp-font-m' style={{ color: '#687385' }}>$9000.00</span>

                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>Postage</span>
                                    <span className='zp-font-m' style={{ color: '#687385' }}>$0.00</span>

                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>GST</span>
                                    <span className='zp-font-m' style={{ color: '#687385' }}>$900.00</span>
                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>Fees</span>
                                    <span className='zp-font-m' style={{ color: '#687385' }}>$0.00</span>
                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>Total</span>
                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>$9900.00</span>

                                </div>
                            </div>
                        </div>
                        <div className='order-breakdown'>
                            <div className='flex-col-10'>
                                <span className='zp-weight-l zp-font-xl' style={{ borderBottom: '1px solid #ddd' }}>Order Breakdown</span>
                                <div className='breakdown-grid'>
                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>Owner</span>
                                    <span className='zp-font-m' style={{ color: '#687385' }}>John Doe</span>

                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>Owner email</span>
                                    <span className='zp-font-m' style={{ color: '#687385' }}>example@example.com</span>

                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>Delivery address</span>
                                    <div className=' flex-col-5'>
                                        <div className='zp-font-m' style={{ color: '#687385' }}>Unit 1, 23 Example St</div>
                                        <div className='zp-font-m' style={{ color: '#687385' }}>Suburb, City, 4567</div>
                                    </div>
                                    <span className='zp-font-m zp-weight-m' style={{ color: '#687385' }}>Billing address</span>
                                    <div className=' flex-col-5'>
                                        <div className='zp-font-m' style={{ color: '#687385' }}>Unit 1, 23 Example St</div>
                                        <div className='zp-font-m' style={{ color: '#687385' }}>Suburb, City, 4567</div>
                                    </div>
                                </div>
                                <SmallCheckout />
                            </div>
                        </div>
                    </div>
                    {/* Render additional order details */}
                </div>
            </div>
        </>
    )
}







export function SmallCheckout() {

    // const { checkoutData } = useCartDataContext()
    //const { images } = useCheckoutImageContext()
    const images = {}

    const checkoutData = {
        cartItems: [
            {
                available_quantity: 398526,
                company: "IFM",
                configurations: [
                    {
                        configuration: {
                            "001": "C1",
                            "002": "C1",
                            "004": "A",
                            "005": "A",
                            "010": "AA",
                            "020": "A",
                            "030": "A"
                        },
                        price: 2050.75,
                        quantity: 4
                    },
                    {
                        configuration: {
                            "001": "C1",
                            "002": "C1",
                            "004": "A",
                            "005": "A",
                            "010": "AA",
                            "020": "A",
                            "030": "A"
                        },
                        price: 2050.75,
                        quantity: 4
                    },
                    {
                        configuration: {
                            "001": "C1",
                            "002": "C1",
                            "004": "A",
                            "005": "A",
                            "010": "AA",
                            "020": "A",
                            "030": "A"
                        },
                        price: 2050.75,
                        quantity: 4
                    },
                    {
                        configuration: {
                            "001": "C1",
                            "002": "C1",
                            "004": "A",
                            "005": "A",
                            "010": "AA",
                            "020": "A",
                            "030": "A"
                        },
                        price: 2050.75,
                        quantity: 4
                    },
                    {
                        configuration: {
                            "001": "C1",
                            "002": "C1",
                            "004": "B",
                            "005": "B",
                            "010": "BG",
                            "020": "A",
                            "030": "A"
                        },
                        price: 1880.5,
                        quantity: 4
                    },
                    {
                        configuration: {
                            "001": "C1",
                            "002": "C1",
                            "004": "B",
                            "005": "B",
                            "010": "BG",
                            "020": "A",
                            "030": "A"
                        },
                        price: 1880.5,
                        quantity: 4
                    }
                ],
                image_name: "ff4287c7-58f4-4873-aff9-d33c1752d950",
                part_name: "Pressure transducer with display",
                part_number_supplier: "PN0002",
                price: "7000",
                quantity: 24,
                subtotal: "215856.00",
                weight: "10"
            },
            {
                available_quantity: 48,
                company: "WIKA",
                configurations: [
                    {
                        configuration: {
                            "001": "C1",
                            "002": "C2",
                            "004": "B",
                            "005": "A",
                            "010": "AA",
                            "020": "A",
                            "030": "A"
                        },
                        price: 1800.75,
                        quantity: 4
                    },
                    {
                        configuration: {
                            "001": "C1",
                            "002": "C1",
                            "004": "B",
                            "005": "A",
                            "010": "AA",
                            "020": "A",
                            "030": "A"
                        },
                        price: 1750.75,
                        quantity: 4
                    }
                ],
                part_name: "Pressure transducer with display",
                part_number_supplier: "PN0003",
                price: "7000",
                quantity: 8,
                subtotal: "70206.00",
                weight: "10"
            }
        ],
        fees: "738.50",
        grandTotal: "315406.70",
        gst: "28606.20",
        postage: "0.00",
        subtotal: "286062.00"
    };

    const cartItems = Object.values(checkoutData.cartItems ?? {})

    const groupedData = cartItems.reduce((acc, item) => {
        if (!acc[item.company]) {
            acc[item.company] = [];
        }
        acc[item.company].push(item);
        return acc;
    }, {});

    const [isExpanded, setIsExpanded] = useState(false)
    const [closeAll, setCloseAll] = useState(false)
    const [overflow, setOverflow] = useState("hidden");

    useEffect(() => {
        if (isExpanded) {
            const timer = setTimeout(() => {
                setOverflow("auto");
            }, 400); // Adjust delay as needed
            return () => clearTimeout(timer);
        } else {
            setOverflow("hidden");
        }
    }, [isExpanded]);


    useEffect(() => {
        if (!isExpanded) {
            setCloseAll(true)
        }
    }, [isExpanded])

    useEffect(() => {
        // console.log(groupedData);
    }, [groupedData])

    return (
        <>
            <div className='small-checkout-wrapper'>
                <div className='checkout-right-panel-small flex-col-0' >

                    <div className=' justify-between zp-font-s hidden-content-hover' onClick={() => { setIsExpanded(!isExpanded) }} style={{ width: '100%', padding: '16px' }}>
                        <div className='flex-row-10' >
                            <span className='zp-font-s ' style={{ transition: 'transform 0.3s ease-in-out', transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }}>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                            <span className='zp-font-s zp-weight-m'>Item Summary</span>

                        </div>
                    </div>

                    <div className={`hidden-content ${isExpanded ? "show" : ""} flex-col-0`} >
                        <div style={{ paddingTop: '16px', borderTop: '1px solid #ccc', overflow: overflow, scrollbarWidth: 'thin', scrollbarGutter: 'stable' }}>

                            {Object.entries(groupedData).map(([companyName, items], index) => (
                                <CheckoutFragment key={index} companyName={companyName} items={items} closeAll={closeAll} resetCloseAll={() => setCloseAll(false)} images={images} checkoutData={checkoutData} />
                            ))}


                        </div>


                    </div>

                </div>
            </div>
        </>
    )
}

export function CheckoutFragment({ index, companyName, items, closeAll, resetCloseAll, images, checkoutData }) {
    const [isExpanded, setIsExpanded] = useState(false)
    const [closeAll2, setCloseAll2] = useState(false)

    const sumSubtotals = (items) => {
        return items.reduce((acc, item) => acc + parseFloat(item.subtotal), 0);
    };

    const companyTotal = sumSubtotals(items);

    useEffect(() => {
        if (closeAll) {
            setIsExpanded(false)
            resetCloseAll()
        }
    }, [closeAll])

    useEffect(() => {
        if (!isExpanded) {
            setCloseAll2(true)
        }
    }, [isExpanded])

    return (
        <>
            <div style={{ padding: isMobile ? '0px 16px 16px 16px' : '0px 5px 16px 16px' }}>
                <div key={index} className='hidden-content-show zp-font-s justify-between' onClick={() => { setIsExpanded(!isExpanded) }} style={{ width: '100%' }}>
                    <span className='zp-weight-m flex-row-5' style={{ cursor: 'pointer' }}>
                        <span style={{ transition: 'transform 0.3s ease-in-out', transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }}> <FontAwesomeIcon icon={faChevronDown} /></span>
                        <span>{companyName}</span>
                    </span>
                    <span className='zp-weight-m'>${parseFloat(companyTotal).toFixed(2)}</span>
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""} flex-col-10`}>
                    <CheckoutFragmentChild index={index} items={items} closeAll={closeAll} resetCloseAll={resetCloseAll} closeAll2={closeAll2} resetCloseAll2={() => setCloseAll2(false)} images={images} />
                </div>
            </div>

        </>
    )
}

export function CheckoutFragmentChild({ items, closeAll, resetCloseAll, closeAll2, resetCloseAll2, images }) {
    const [expandedItems, setExpandedItems] = useState({});

    const toggleItem = (part_number_supplier) => {
        setExpandedItems((prev) => ({
            ...prev,
            [part_number_supplier]: !prev[part_number_supplier], // Toggle individual item
        }));
    };

    useEffect(() => {
        if (closeAll || closeAll2) {
            setExpandedItems({})
            resetCloseAll()
            resetCloseAll2()
        }
    }, [closeAll, closeAll2])

    return (
        <>
            <div className='flex-col-5' style={{ padding: '5px 0px', }}>
                {items.map((item, index) => (
                    <div className='flex-row-5' key={item.part_number_supplier} >
                        <div className='hide-450' key={index} style={{ position: "relative", height: "50px", width: "50px", display: "flex", alignItems: "center" }}>
                            <div className='image-container' style={{ height: '100%', width: '100%' }}>
                                {item.image_name && images?.[item.image_name] ? (
                                    <img
                                        src={`data:image/jpeg;base64,${images[item.image_name]}`}
                                        alt="Product"
                                        style={{ maxHeight: "100%", border: "solid 1px #ccc", borderRadius: '10px', overflow: 'hidden' }}
                                    />
                                ) : (
                                    <div style={{ height: '50px', width: '50px' }}>
                                        <NoContent label="" borderRadius="0px" />
                                    </div>

                                )}
                            </div>
                            <span
                                className='justify-center align-center zp-font-s'
                                style={{
                                    borderTopRightRadius: '10px',
                                    borderTop: item.image_name ? '1px solid #ccc' : '2px dashed #ccc',
                                    borderRight: item.image_name ? '1px solid #ccc' : '2px dashed #ccc',
                                    background: '#EBF5FF',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '20px',
                                    height: '20px'
                                }}>
                                {item.configurations.length > 0 && <>{item.quantity}</>}
                            </span>
                        </div>
                        <div style={{ width: '100%', minWidth: '0px', maxWidth: '100%' }}>
                            <div className='flex-row-10 zp-font-s justify-between' onClick={() => toggleItem(item.part_number_supplier)} >
                                <div className='flex-row-5' style={{ width: '100%', minWidth: 0 }}>
                                    <span className='zp-font-s align-center' style={{ transition: 'transform 0.3s ease-in-out', transform: expandedItems[item.part_number_supplier] ? "rotate(180deg)" : "rotate(0deg)" }}>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </span>
                                    <span
                                        className='justify-start'
                                        style={{
                                            flexWrap: 'wrap',
                                            whiteSpace: 'normal',
                                            overflowWrap: 'break-word', /* helps with long words or URLs */
                                            wordBreak: 'break-word',
                                            display: 'inline-block',
                                            maxWidth: '100%',  // Adjust width as needed
                                            cursor: 'pointer'

                                        }}>
                                        {item.part_number_supplier} {item.part_name}
                                    </span>

                                </div>
                                <div>
                                    <span className='zp-font-s'>{item.quantity}</span>
                                </div>
                                <div className='flex-row-10 justify-end' style={{ minWidth: '70px' }} >


                                    {expandedItems[item.part_number_supplier] ? (
                                        <span className='zp-font-s'>
                                            ${parseFloat(item.price * item.quantity).toFixed(2)}
                                        </span>
                                    ) : (
                                        <span className='zp-font-s'>
                                            ${parseFloat(item.subtotal).toFixed(2)}
                                        </span>
                                    )}

                                </div>

                            </div>

                            <div className={`hidden-content ${expandedItems[item.part_number_supplier] ? "show" : ""} flex-col-0`}>
                                <CheckoutFragmentChild2 configurations={item.configurations} />
                            </div>
                        </div>
                    </div>
                ))}
            </div >
        </>
    );
}

export function CheckoutFragmentChild2({ configurations }) {

    console.log(configurations)


    return (
        <>
            {configurations.map((config, index) => (
                <div key={index} className="zp-font-s flex-row-10 justify-between" style={{ paddingBottom: '2px', width: '100%' }}>

                    {/* Left Section: Number Picker + Configuration Name */}
                    <div className="flex-row-5" style={{ flex: '1', minWidth: '0' }}>
                        <span style={{ color: '#ccc', width: '12px' }}></span>


                        {/* Wrapping text inside a div for ellipsis to work */}
                        <div style={{ flex: '1', minWidth: '0', overflow: 'hidden' }}>
                            <span
                                className='zp-font-s'
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    display: 'block',  // Use block to ensure it respects width
                                    maxWidth: '100%'
                                }}>
                                {Object.entries(config.configuration)
                                    .map(([key, value]) => value) // Extract values only
                                    .join('-')}
                            </span>
                        </div>
                    </div>
                    <div className='zp-font-s'>
                        <span>{config.quantity}</span>
                    </div>

                    <div className="flex-row-10 zp-font-s justify-end" style={{ minWidth: '70px' }}>
                        <span>${parseFloat(config.price * config.quantity).toFixed(2)}</span>
                    </div>
                </div>
            ))}



        </>
    );
}




export function ActivityStatus({ status, date, paymentTerms }) {
    return (
        <>
            <div className='flex-col-0'>
                <span className='zp-font-m flex-row-5'>
                    <span>{status} {paymentTerms && (
                        <span><BlueChip label={paymentTerms} /></span>
                    )}</span>


                </span>
                <span className='zp-font-s' style={{ color: '#687385' }}>{date}</span>
            </div>
        </>
    )
}

export function SupplierPurchaseOrderTable({ }) {

    return (
        <>


        </>
    )
}





