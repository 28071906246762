import React, { useState, useRef, useEffect } from 'react';

function SelectComponent({ options, selectedValue, onSelect }) {
    const [isOpen, setIsOpen] = useState(false); // State to toggle dropdown
    const dropdownRef = useRef(null); // Ref to detect clicks outside the component

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Handle option click
    const handleOptionClick = (option) => {
        onSelect(option); // Notify parent about the selected value
        setIsOpen(false); // Close dropdown
    };

    return (
        <div
            ref={dropdownRef}
            style={{
                position: 'relative',
                width: '100%',
                fontFamily: 'Arial, sans-serif',
            }}
        >
            {/* Dropdown Header */}
            <div style={{}}>
            <div
                onClick={() => setIsOpen((prev) => !prev)} // Toggle dropdown visibility
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '4px 8px',
                    marginBottom: '4px',
                    marginTop: '4px',
                    fontSize: '14px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                }}
            >
                <span>{selectedValue || 'Select an option'}</span>
                <span>{isOpen ? '▲' : '▼'}</span>
            </div>
            </div>

            {/* Dropdown Options */}
            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        right: 0,
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        fontSize: '14px',
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                        zIndex: 1000,
                        maxHeight: '150px',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin'
                    }}
                >
                    {/* Placeholder option - "Select an option" */}
                    <div
                        onClick={() => handleOptionClick('')}
                        style={{
                            padding: '4px 8px',
                            fontSize: '14px',
                            cursor: 'pointer',
                            backgroundColor: !selectedValue ? '#f0f0f0' : '#fff',
                            //color: '#888',
                        }}
                    >
                        Select an option
                    </div>

                    {/* Options List */}
                    {options.length > 0 && (
                        options.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => handleOptionClick(option)}
                                style={{
                                    padding: '4px 8px',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    backgroundColor: selectedValue === option ? '#f0f0f0' : '#fff',
                                }}
                            >
                                {option}
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
}

export default SelectComponent;
