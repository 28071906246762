import React, { useEffect, useState } from 'react'
import styles from './css/GenericButtons.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
function GenericButtons() {

}

export function Button1({ label, onClick }) {
    return (
        <>
            <span
                className={`${styles.button1} zp-weight-m`}
                style={{ paddingRight: '5px' }}
                onClick={onClick}>
                {label}
            </span>
        </>
    )
}

export function Button2({ label, onClick, padding }) {
    return (
        <>
            <div
                className={`${styles.button2}`}
                style={{ padding: padding ? padding : '4px 8px' }}
                onClick={onClick}
            >
                {label}
            </div>
        </>
    )
}

export function Button3({ label, onClick, padding, borderRadius }) {
    return (
        <>
            <div
                className={`${styles.button3} zp-weight-m`}
                style={{
                    padding: padding ? padding : '4px 8px',
                    borderRadius: borderRadius ? borderRadius : '4px'
                }}
                onClick={onClick}
            >

                {label}


            </div>
        </>
    )
}



export function Button4({ label, onClick, padding, borderRadius }) {
    return (
        <>
            <div
                className={`${styles.button4}`}
                style={{
                    padding: padding ? padding : '4px 8px',
                    borderRadius: borderRadius ? borderRadius : '4px'
                }}
                onClick={onClick}
            >
                {label}


            </div>
        </>
    )
}

export function Button4_1({ label, onClick, padding, borderRadius }) {
    return (
        <>
            <div
                className={`${styles.button4_1}`}
                style={{
                    padding: padding ? padding : '4px 8px',
                    borderRadius: borderRadius ? borderRadius : '4px'
                }}
                onClick={onClick}
            >
                {label}


            </div>
        </>
    )
}

export function Button5({ label, onClick, padding, borderRadius, icon }) {
    return (
        <>
            <div
                className={`${styles.button5}`}
                style={{
                    padding: padding ? padding : '8px 12px',
                    borderRadius: borderRadius ? borderRadius : '6px'
                }}
                onClick={onClick}
            >
                <span>
                    <FontAwesomeIcon icon={icon} />
                </span>
                {label}



            </div>
        </>
    )
}




export default GenericButtons