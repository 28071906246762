import { useState, useEffect, useRef } from 'react';

import { useFormDataContext, useAutocompleteContext } from 'index.js'
import { useCookies } from 'react-cookie';

const apiUrl = process.env.REACT_APP_API_URL;

export const useGoogleMaps = (apiKey, options) => {
    const {
        updateFormData,
        updateFormData2,
    } = useFormDataContext()

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [cookies] = useCookies(['csrf_token']);

    window.initMap = async () => {
        setIsLoaded(true);
        setTimeout(() => {
            initAutocomplete();
        }, 1000);
    };

    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const fetchScriptUrl = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/google-maps-key`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies.csrf_token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                return { success: true, scriptUrl: data.scriptUrl };
            } else {
                throw new Error(data.error || 'Error fetching API key');
            }
        } catch (error) {
            console.error('Error fetching Google Maps API key:', error.message);
            return { success: false, error: error.message };
        }
    };

    //useEffect(() => {
    const loadGoogleMapsScript = async () => {
        const existingScript = document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]');
        if (existingScript) {
            // console.log("the google script is here yay!");
            if (window.google && window.google.maps) {
                // console.log("yes we have window google etc!");
                window.initMap();
            } else {
                console.error('Google Maps script exists but is not loaded correctly');
            }
        } else {
            const response = await fetchScriptUrl();
            // console.log("the google script is missing!");
            if (response.success) {
                const script = document.createElement('script');
                script.src = response.scriptUrl;
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);
                script.onload = () => {
                    //window.initMap();
                    // console.log('Google Maps script loaded');
                }
                script.onerror = () => console.error('Error loading Google Maps script');

            } else {
                setError(response.error);
                console.error('Error:', response.error);
            }
        }
    };

    useEffect(() => {
        const loadGoogleMapsScriptSequentially = async () => {
            await loadGoogleMapsScript(); // First load
        };
        loadGoogleMapsScriptSequentially();
    }, []);


    
    //const autocompleteInitialized = useRef(false);
    const autocompleteInitialized = useAutocompleteContext();
    

    const resetAutocomplete = () => {
        // console.log("RESET THE AUTO");
        autocompleteInitialized.current = false;
    };

    const initAutocomplete = (detailType, inputIds, settings, callback) => {
        if (typeof google === 'undefined') {
            console.error('Google Maps JavaScript API not loaded');
            return;
        }
        if (!isLoaded || autocompleteInitialized.current) return;
        // console.log("test to see if we are reinit");
        const inputs = inputIds.map(id => document.getElementById(id)).filter(input => input);
        
        if (inputs.length === 0) {
            console.error('Input elements not found');
            return;
        }
    
        inputs.forEach((input, index) => {

            input.classList.remove('pac-target-input');
            input.removeAttribute('autocomplete'); // Optional: Google might add this
            input.setAttribute('value', ''); // Optional: Reset input value if necessary
            // console.log("test to see if we are reinit", inputs);
            const autocomplete = new window.google.maps.places.Autocomplete(input, settings);
            //autocompleteInitialized.current = true;
            

            autocompleteInitialized.current = true;
    
            const debouncedPlaceChanged = debounce((place) => {
                if (!place.geometry) {
                    window.alert('No details available for input: \'' + place.name + '\'');
                    return;
                }
                callback(place);
            }, 2000);
    
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const fillInAddress = (place, type) => {
                    const addressNameFormat = {
                        'street_number': 'short_name',
                        'route': 'long_name',
                        'locality': 'long_name',
                        'administrative_area_level_1': 'short_name',
                        'country': 'long_name',
                        'postal_code': 'short_name',
                        'subpremise': 'short_name',
                    };
                    const getAddressComp = (type) => {
                        for (const component of place.address_components) {
                            if (component.types[0] === type) {
                                return component[addressNameFormat[type]];
                            }
                        }
                        return '';
                    };
                    const formData = {
                        suite: getAddressComp('subpremise'),
                        location: `${getAddressComp('street_number')} ${getAddressComp('route')}`,
                        locality: getAddressComp('locality'),
                        administrative_area_level_1: getAddressComp('administrative_area_level_1'),
                        country: getAddressComp('country'),
                        postal_code: getAddressComp('postal_code'),
                        //latitude: place.geometry.location.lat(),
                        //longitude: place.geometry.location.lng(),
                    };
    
                    if (type === 'delivery-details') {
                        updateFormData(formData);
                    } else if (type === 'billing-details') {
                        updateFormData2(formData);
                    }
                };
    
                fillInAddress(place, detailType);
                debouncedPlaceChanged(place);
            });
        });
        
    };
    



    return { isLoaded, error, initAutocomplete, resetAutocomplete };
};





//const handleInputChange = debounce(async (event) => {
//    const inputValue = event.target.value;
//    try {
//        const response = await fetch(`${apiUrl}/some-endpoint?query=${inputValue}`, {
//            method: 'GET',
//            headers: {
//                'Content-Type': 'application/json',
//                'X-CSRFToken': cookies.csrf_token,
//            },
//        });
//        const data = await response.json();
//        if (response.ok) {
//            // handle successful response
//        } else {
//            throw new Error(data.error || 'Error fetching data');
//        }
//    } catch (error) {
//        console.error('Error fetching data:', error.message);
//    }
//}, 1000);

//autocomplete2.addListener('place_changed', () => {
//    const place2 = autocomplete2.getPlace();
//    const fillInAddress2 = (place2) => {
//        const addressNameFormat = {
//            'street_number': 'short_name',
//            'route': 'long_name',
//            'locality': 'long_name',
//            'administrative_area_level_1': 'short_name',
//            'country': 'long_name',
//            'postal_code': 'short_name',
//            'subpremise': 'short_name',
//            };
//        const getAddressComp = function (type) {
//            for (const component of place2.address_components) {
//                if (component.types[0] === type) {
//                return component[addressNameFormat[type]];
//                }
//            }
//            return '';
//        };
//        const suiteNumber = getAddressComp('subpremise');
//        updateFormData2({
//            suite: suiteNumber,
//            location: `${getAddressComp('street_number')} ${getAddressComp('route')}`,
//            locality: getAddressComp('locality'),
//            administrative_area_level_1: getAddressComp('administrative_area_level_1'),
//            country: getAddressComp('country'),
//            postal_code: getAddressComp('postal_code'),
//            latitude: place2.geometry.location.lat(),
//            longitude: place2.geometry.location.lng(),
//        });
//    };
//    fillInAddress2(place2)
//    debouncedPlaceChanged(place2);
//});