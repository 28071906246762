import React, { useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import './MultiEmail.css'
const styles = {
  fontFamily: "sans-serif",
  
 
  
  
};

const MultiEmailInput = () => {
  const [emails, setEmails] = useState([]);

  return (
    <div style={styles} className="zp-font-m">
      
      <ReactMultiEmail
        placeholder="ada@zephyr.com, etc."
        emails={emails}
        className="zp-font-m"
        onChange={(newEmails) => setEmails(newEmails)}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              {email}
              <span
                data-tag-handle
                onClick={() => removeEmail(index)}
                style={{
                  marginLeft: "8px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                ×
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default MultiEmailInput;
