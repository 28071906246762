import React, {useState, useEffect, useRef, useMemo, } from 'react'


function DragAndDropZone({ onDrop, hoveringText, defaultText, maxFileSize, height }) {
    const [isHovering, setIsHovering] = useState(false);
    const fileInputRef = useRef(null);
  
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsHovering(false);
  
      const files = Array.from(e.dataTransfer.files);
      const validFiles = files.filter((file) => file.size <= maxFileSize);
  
      onDrop(validFiles);
    };
  
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsHovering(true);
    };
  
    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const rect = e.currentTarget.getBoundingClientRect();
      const isInside =
        e.clientX >= rect.left &&
        e.clientX <= rect.right &&
        e.clientY >= rect.top &&
        e.clientY <= rect.bottom;
  
      if (!isInside) setIsHovering(false);
    };

    const handleDivClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      };
    
      const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter((file) => file.size <= maxFileSize);
    
        onDrop(validFiles);
      };
  
    return (
      <div
        style={{
          border: "1px solid #ccc",
          borderTop: 'none',
          height: height,
          borderRadius: "0px 0px 8px 8px",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onClick={handleDivClick}
      >
        <div className="file-upload-container">
          <span style={{ display: "flex", flexDirection: "column", minWidth: "100%" }}>
            {isHovering ? (
              <span style={{ fontSize: "14px", fontStyle: "Arial", alignSelf: "center" }}>
                {hoveringText}
              </span>
            ) : (
              <>
                <span style={{ fontSize: "14px", fontStyle: "Arial", alignSelf: "center" }}>
                  {defaultText}
                </span>
               
              </>
            )}
          </span>
        </div>
          <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none"}}
          onChange={handleFileChange}
        />
      </div>
    );
  }
  

  export default DragAndDropZone