






import React, {useState} from 'react'
import NavigationPanelButton from './GenericComponents/NavigationPanelButton'

function NavigationPanelContent({globalNavType, handleMobileNavClick}) {

    return (
        <>
            <div className='navigation-overview-label' style={{borderBottom: '1px solid #d8dee4'}}>PROCUREMENT</div>
              <div>
                <ul className='tw-ul-style' >
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/equipment"}
                        Label={"Equipment"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/checkout"}
                        Label={"Checkout"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/orders"}
                        Label={"Order History"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                </ul>
              </div>
              <div className='navigation-overview-label' style={{borderBottom: '1px solid #d8dee4'}}>FABRICATION</div>
              <div>
                <ul className='tw-ul-style' >
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/fabrication"}
                        Label={"Fabrication"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                </ul>
              </div>
              <div className='navigation-overview-label' style={{borderBottom: '1px solid #d8dee4'}}>MODULES</div>
              <div>
                <ul className='tw-ul-style' >
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/2DModelling"}
                        Label={"2D Modelling"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/3DModelling"}
                        Label={"3D Modelling"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/equipmentselector"}
                        Label={"Equipment Selector"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/safety"}
                        Label={"Hazard Modelling"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/document"}
                        Label={"Document Control"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                </ul>
              </div>
              <div className='navigation-overview-label' style={{borderBottom: '1px solid #d8dee4'}}>ACCOUNT</div>
              <div>
                <ul className='tw-ul-style' >
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={""}
                        Label={"Home"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                    <NavigationPanelButton
                        globalNavType={globalNavType}
                        NavLocation={"/settings"}
                        Label={"Settings"}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                                      
                </ul>
              </div>
        
        
        </>
    )
}

export default NavigationPanelContent