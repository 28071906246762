import React, { useState, useEffect } from 'react'
import { TabNavigation, FitlerBox, useAuth, useToggle, GenericInputBox2, GenericBusinessDetails, Button4, DivWrapper1 } from 'index.js'
import { divWrapper1 } from 'index.js'
import { faPlus, faChevronLeft, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation } from 'react-router-dom';
import './account.css'

export function Fragment0({ handleSaveDetails }) {
    return (
        <>

            <div style={{ maxWidth: '400px' }}>
                <GenericInputBox2
                    label={'Account name'}
                    placeholder={'Company'}
                    defaultValue={'Zephyr Innovations Pty Ltd'}
                />
            </div>
            <div style={{ maxWidth: '400px' }}>
                <GenericInputBox2
                    label={'Account ID'}
                    placeholder={'acct_11111111'}
                    defaultValue={'acct_12345678'}
                />
            </div>
            <GenericBusinessDetails
                label={'Business Address'}
            />

            <div>
                <div
                    onClick={handleSaveDetails}
                    className='save-button-css'
                    style={{ marginBottom: '10px' }}
                >
                    Save
                </div>
                <div
                    className='cancel-button-css'
                >
                    Cancel
                </div>
            </div>

        </>
    )
}

export function Fragment1() {
    const LabelStyle1 = {
        fontSize: '16px',
        fontFamily: 'var(--font-family-1)',
        fontWeight: 500,

    }

    const LabelStyle2 = {
        fontSize: '14px', fontFamily: 'var(--font-family-1)', fontWeight: 500,
    }

    return (
        <>
            <div style={LabelStyle1}>Business details</div>
            <div style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '8px 8px' }}>

                <div className='flex-col-10'>
                    <div className='flex-row-10 justify-between zp-font-medium'>
                        <div style={LabelStyle2}>Zephyr Innovations Pty Ltd</div>
                        <Button4 label={"Edit"} onClick={() => { }} />

                    </div>
                    <div className='flex-col-5'>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>URL</span>
                            <span>https://zephyrinnovations.com.au</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <div style={{ width: '30%' }}>Address</div>
                            <div className='flex-col-0'>
                                <span>Unit 2</span>
                                <span>2-4 Glen Street</span>
                                <span>Marrickville NSW 2204 AU</span>
                            </div>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>ACN</span>
                            <span>676 446 236</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>ABN</span>
                            <span>96 676 446 236</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Phone</span>
                            <span>0439870653</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={LabelStyle1}>Public details</div>
            <div style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '8px 8px' }}>
                <div className='flex-col-10'>
                    <div className='flex-row-10 justify-between zp-font-medium'>
                        <div style={LabelStyle2}>Customer support information</div>
                        <Button4 label={"Edit"} onClick={() => { }} />
                    </div>
                    <div className='flex-col-5'>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Business name</span>
                            <span>Zephyr Innovations Pty Ltd</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Support phone</span>
                            <span>04398870653</span>
                        </div>
                    </div>
                    <div style={LabelStyle2}>Policy information</div>
                    <div className='flex-col-5'>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Customer support URL</span>
                            <span>https://.../customersupport</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Privacy Policy URL</span>
                            <span>https://.../privacypolicy</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Terms of Service URL</span>
                            <span>https://.../termsofservice</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={LabelStyle1}>Management and ownership</div>
            <div style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '8px 8px' }}>
                <div className='flex-col-0'>
                    <div className='flex-row-10 justify-between zp-font-medium'>
                        <div style={LabelStyle2}>Add a different account representative</div>
                        <Button4 label={"Add"} onClick={() => { }} />
                    </div>
                    <div className='zp-font-medium'>Add a person who has the authority to represent your organization</div>
                </div>
            </div>
            <div style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '8px 8px' }}>
                <div className='flex-col-10'>
                    <div className='flex-row-10 justify-between zp-font-medium'>
                        <div style={LabelStyle2}>Declan John Grahame Price</div>
                        <Button4 label={"Edit"} onClick={() => { }} />
                    </div>
                    <div className='flex-col-5'>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Role</span>
                            <span>Account Representative, Owner, Director, and Executive</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Email</span>
                            <span>declan.price@zephyrinnovations.com.au</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Date of brth</span>
                            <span>Born on June 21, 1997</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <div style={{ width: '30%' }}>Address</div>
                            <div className='flex-col-0'>
                                <span>Unit 2</span>
                                <span>2-4 Glen Street</span>
                                <span>Marrickville NSW 2204 AU</span>
                            </div>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Job title</span>
                            <span>CEO / Founder</span>
                        </div>
                        <div className='flex-row-10 zp-font-medium'>
                            <span style={{ width: '30%' }}>Phone</span>
                            <span>0439870653</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Fragment2() {
    return (
        <>
            <div className='border-ddd-8 flex-col-0'>
                <div style={{ padding: '16px' }}>
                    <div className='zp-font-xlarge zp-weight-l'>Bank accounts</div>
                    <div className='zp-font-medium'>Link your nominated bank account with Zephyr to receive payouts to your bank.</div>
                </div>
                <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
                    <thead style={{ borderTop: '1px solid #ddd', backgroundColor: '#f5f5f5' }}>
                        <tr>
                            <td className='team-table-header-2'>CURRENCY</td>
                            <td className='team-table-header-2'>PAYOUT BANK ACCOUNT</td>

                            <td className='team-table-header-2' style={{ justifyContent: 'right', display: 'flex' }}>
                                <div className='new-member-button'>+ MANAGE BANKS</div>
                            </td>
                        </tr>
                    </thead>
                    <tr>
                        <td className='team-table-td-2'>
                            <div className='flex-row-10' style={{ minWidth: '100px' }}>
                                <div>AUD</div>
                                <div style={{ textWrap: 'nowrap', textAlign: 'left', fontSize: '12px', padding: '1px 6px', color: '#0055BC', backgroundColor: '#CFF5F6', fontWeight: 500 }}>Default</div>
                            </div>
                        </td>
                        <td className='team-table-td-2'>
                            <div className='flex-row-10' style={{ padding: '8px' }}>
                                <div className='bank-widget' style={{ backgroundColor: '#ccc', padding: '4px', borderRadius: '4px', maxHeight: '32px' }}>
                                    <FontAwesomeIcon icon={faBuildingColumns} fontSize={'24px'} />
                                </div>
                                <div className='monostyle-text' style={{ display: 'flex', alignItems: 'center' }}>CBA XXXX9988 / 06 2199</div>

                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
}

function Account() {
    const navigate = useNavigate()
    const { isToggled } = useToggle();

    const { user } = useAuth()

    function handleNavClick() {
        let targetPath = '';
        if (user && user.userType === 'supplier') {
            targetPath = `/supplier/settings`;
            navigate(targetPath);
        } else if (user && user.userType === 'customer') {
            targetPath = `/customer/settings`;
            navigate(targetPath);
        }
    }

    const tabs = ['Account details', 'Business details', 'Bank accounts and currencies']
    const [activeTab, setActiveTab] = useState(0)
    function handleTabClick(index) {
        setActiveTab(index)
    }

    function handleSaveDetails() {
        // console.log('details saved');
    }

    const [accountID] = useState('acc_111111111')

    return (
        <>

            <div
                style={{ display: 'flex', fontSize: '14px', fontWeight: 700, fontFamily: 'var(--font-family-1)', color: '#339AF0', }}
            >
                <div onClick={handleNavClick} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faChevronLeft} color={'#545969'} />
                    {' '}Settings
                </div>
            </div>
            <div style={{ fontSize: '24px', fontWeight: 700, marginBottom: '10px' }}>Business</div>
            <TabNavigation
                tabs={tabs}
                onTabClick={handleTabClick}
                fontSize='14px'
            />

            {activeTab === 0 && (
                <>
                    <DivWrapper1 children={
                        <Fragment0 handleSaveDetails={handleSaveDetails} />
                    } />
                </>
            )}
            {activeTab === 1 && (
                <>
                    <DivWrapper1 children={
                        <Fragment1 />
                    } />
                </>
            )}
            {activeTab === 2 && (
                <>
                    <DivWrapper1 children={
                        <Fragment2 />
                    } />
                </>
            )}
        </>
    )
}


export default Account