import React, { createContext, useContext, useState, useEffect } from 'react';

const ToggleContext = createContext();

export const useToggle = () => {
  return useContext(ToggleContext);
};

export const ToggleProvider = ({ children }) => {
  const [isToggled, setIsToggled] = useState(() => {
    // Load the initial state from localStorage, default to false if not found
    const savedState = localStorage.getItem('isToggled');
    return savedState !== null ? JSON.parse(savedState) : false;
  });

  useEffect(() => {
    // Save the current toggle state to localStorage whenever it changes
    localStorage.setItem('isToggled', JSON.stringify(isToggled));
  }, [isToggled]);

  const toggle = () => {
    setIsToggled((prev) => !prev);
  };

  return (
    <ToggleContext.Provider value={{ isToggled, toggle }}>
      {children}
    </ToggleContext.Provider>
  );
};
