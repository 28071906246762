import React, { useState, useEffect } from 'react'
import { TabNavigation, FitlerBox, useAuth, useToggle, GenericInputBox2, GenericBusinessDetails } from 'index.js'
import { faPlus, faChevronLeft, faBuildingColumns, faRotate, faEnvelopesBulk , faLayerGroup} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation } from 'react-router-dom';

function Plans() {

    const navigate = useNavigate()
    const { isToggled } = useToggle();

    const { user } = useAuth()

    function handleNavClick() {
        let targetPath = '';
        if (user && user.userType === 'supplier') {
            targetPath = `/supplier/settings`;
            navigate(targetPath);
        } else if (user && user.userType === 'customer') {
            targetPath = `/customer/settings`;
            navigate(targetPath);
        }
    }

    const LabelStyle1 = {
        fontSize: '16px',
        fontFamily: 'var(--font-family-1)',
        fontWeight: 500,

    }

    const LabelStyle2 = {
        fontSize: '14px', fontFamily: 'var(--font-family-1)', fontWeight: 500,
    }

    return (
        <>
            <div
                style={{ display: 'flex', fontSize: '14px', fontWeight: 700, fontFamily: 'var(--font-family-1)', color: '#339AF0', }}
            >
                <div onClick={handleNavClick} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faChevronLeft} color={'#545969'} />
                    {' '}Settings
                </div>
            </div>
            <div style={{ fontSize: '24px', fontWeight: 700, marginBottom: '10px' }}>Your Plans</div>
            <div className='flex-col-10' style={{ overflowY: 'auto', maxHeight: isToggled ? 'calc(var(--vh) - 238px)' : 'calc(var(--vh) - 194px', scrollbarWidth: 'thin' }}>

                {user.userType === 'supplier' && (
                    <>
                        <div className='flex-row-10' style={{ marginTop: '20px' }}>
                            <div className='flex-row-10' style={{ minWidth: '30%' }}>
                                <div className='plans-widget' style={{ backgroundColor: '#f3f3f3', padding: '8px', maxHeight: '32px', borderRadius: '8px', alginItems: 'center' }}>
                                    <FontAwesomeIcon icon={faRotate} fontSize={'16px'} color={'var(--brand-color-1)'} />
                                </div>
                                <div className='flex-col-5'>
                                    <span className='zp-font-xlarge zp-weight-l'>Sales</span>
                                    <span className='zp-font-medium'>Equipment total sales volume</span>
                                </div>
                            </div>
                            <div className='flex-col-5'>
                                <span className='zp-font-medium'>Pay as you go</span>
                                <span className='zp-font-xlarge'><a className='zp-weight-l'>10%</a> of Sales volume</span>
                                <span className='zp-font-medium'>As purchases are processed, 10% of the sales volume is deducted from the sale and atomatically allocated to Zephyr. No additional actions are necessary.</span>
                            </div>
                        </div>
                        <div className='flex-row-10' style={{ marginTop: '20px' }}>
                            <div className='flex-row-10' style={{ minWidth: '30%' }}>
                                <div className='plans-widget' style={{ backgroundColor: '#f3f3f3', padding: '6px', maxHeight: '32px', borderRadius: '8px', alginItems: 'center' }}>
                                    <FontAwesomeIcon icon={faEnvelopesBulk} fontSize={'16px'} color={'var(--brand-color-1)'} />
                                </div>
                                <div className='flex-col-5'>
                                    <span className='zp-font-xlarge zp-weight-l'>Postage</span>
                                    <span className='zp-font-medium'>Postage fees and handling</span>
                                </div>
                            </div>
                            <div className='flex-col-5'>
                                <span className='zp-font-medium'>Pay as you go</span>
                                <span className='zp-font-xlarge'><a className='zp-weight-l'>Variable</a> per Sale</span>
                                <span className='zp-font-medium'>As sales are processed, a variable fee is charged to the customer based on the order items. The fee is determined by you, and the charge passes through to each customer. The postage configuration can be changed in the <a className='zp-weight-l' style={{ color: 'var(--brand-color-1)', cursor: 'pointer' }}>postage settings</a>. </span>
                            </div>
                        </div>
                    </>
                )}
                {user.userType === 'customer' && (
                    <>
                        <div className='flex-row-10' style={{ marginTop: '20px' }}>
                            <div className='flex-row-10' style={{ minWidth: '30%' }}>
                                <div className='plans-widget' style={{ backgroundColor: '#f3f3f3', padding: '8px', maxHeight: '32px', borderRadius: '8px', alginItems: 'center' }}>
                                    <FontAwesomeIcon icon={faLayerGroup} fontSize={'16px'} color={'var(--brand-color-1)'} />
                                </div>
                                <div className='flex-col-5'>
                                    <span className='zp-font-xlarge zp-weight-l'>Zephyr platform</span>
                                    <span className='zp-font-medium'>Procurement platform and database access</span>
                                </div>
                            </div>
                            <div className='flex-col-5'>
                                <span className='zp-font-medium'>Subscription</span>
                                <span className='zp-font-xlarge'><a className='zp-weight-l'>$2000</a>* / month</span>
                                <span className='zp-font-medium'>Company-wide access to the Zephyr platform. For every dollar spent through Zephyr, the monthly subscription fee is reduced by $0.10. A monthly spend of $20,000 eliminates the subscription cost entirely.</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}


export default Plans
