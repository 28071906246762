import { createContext, useContext, useState, useEffect, useMemo, useRef } from "react";
import { AlertProvider, AlertContext } from 'index.js'


export const FormDataContext = createContext(null)

export const FormDataProvider = ({ children }) => {
    const [formOptions, setFormOptions] = useState({
        annualRevenue: '',
        headcount: '',
        companyType: '',
        jobFunction: '',
        jobLevel: ''
    })

    const [formData, setFormData] = useState({
        email: '',
        given_name: '',
        family_name: '',
        contact_number: '',
        company_website: '',
    });

    const [fragmentIndex, setFragmentIndex] = useState(0)

    const updateFormData = (newData) => {
        setFormData(prev => ({ ...prev, ...newData }));
    };

    const updateFormOptions = (newData) => {
        setFormOptions(prev => ({ ...prev, ...newData }));
    };

    return (
        <FormDataContext.Provider value={{
            formData,
            updateFormData,
            formOptions,
            updateFormOptions,
            fragmentIndex,
            setFragmentIndex
        }}>
            {children}
        </FormDataContext.Provider>
    )
}

export const ContactDetailsProvider = ({ children }) => {
    return (
        <AlertProvider>
            <FormDataProvider>
                <>{children}</>
            </FormDataProvider>
        </AlertProvider>
    )
}

export const useContactDetails = () => {
    const context = useContext(FormDataContext);
    if (!context) throw new Error("useFormDataContext error");
    return context;
};