import React, { useState } from 'react';

const DropdownMenu = ({ options, setSelectedOption, selectedOption, placeholder, labelPosition, label, onChange }) => {

    



    const handleSelect = (event) => {
        const newValue = event.target.value;
        setSelectedOption(newValue)
        if (onChange) {
            onChange(newValue)
        }
//        setSelectedOption(event.target.value);
    };

    const [isFocused, setIsFocused] = useState(false)

    const LabelInputStyle = {
        fontSize: '14px',
        fontFamily: 'var(--font-family-1)',
        fontWeight: 500,
        marginBottom: labelPosition === 'left' ? '0ps' : '5px',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        minWidth: labelPosition === 'left' ? '120px' : '',
        

    }

    return (
        <div className={`${labelPosition === 'left' ? 'flex-row-5' : ''}`} style={{ marginBottom: '24px' }}>
            <div style={LabelInputStyle}>
                {label}
            </div>
            <select
                value={selectedOption}
                onChange={handleSelect}
                onFocus={() => {setIsFocused(true)}}
                onBlur={() => {setIsFocused(false)}}
                style={{
                    fontSize: '12px',
                    fontFamily: 'var(--font-family-1)',
                    width: '100%',
                    padding: '8px 12px',
                    border: isFocused ? 'var(--brand-color-1) 1px solid' : '1px solid #ccc' ,
                    transition: 'border 200ms ease-in-out',
                    borderRadius: '4px',
                    background: '#f9f9f9',
                    cursor: 'pointer',
                    appearance: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                }}
            >
                <option value="" disabled>
                    {placeholder}
                </option>
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownMenu;
