



import React, { useState } from 'react'
import { useToggle } from '../../../../contexts/ToggleContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCircleQuestion, faUser, faBell } from '@fortawesome/free-solid-svg-icons';
import chroma from 'chroma-js'
//import { useAuth } from '../../../../contexts/AuthContext.js'

import { useAuth, SparkLine, Time, DatePicker, DateRangePicker, Button1 } from 'index'

//import LogoOnly from './../../../../assets/Logo Only 2.svg'
import './css/SupplierDashWrapper.css'

export function AnalyticsGrid() {
    return (
        <>
            <div className='grid-container'>
                <div class="grid-item"><Projects /></div>
                <div class="grid-item"><PIDStatusAnalytics /></div>
                <div class="grid-item"><InvoiceStatusAnalytics /></div>
                <div class="grid-item"><Invoices /></div>
                <div class="grid-item"><InvoicePastDue /></div>
                <div class="grid-item"><InvoiceDue /></div>
            </div>
        </>
    )
}

export function Tag({ label, color, backgroundColor }) {

    return (
        <>
            <div className='align-center' style={{ minHeight: '100%' }}>
                <div
                    className='zp-weight-m '
                    style={{
                        height: '20px',
                        textWrap: 'nowrap',
                        textAlign: 'left',
                        fontSize: '12px',
                        padding: '1px 6px',
                        color: color ? color : 'rgb(0, 85, 188)',
                        backgroundColor: backgroundColor ? backgroundColor : 'rgb(207, 245, 246)'
                    }}>
                    {label}
                </div>
            </div>
        </>
    )
}

export function DashBarChart() {

    const tasks = [
        { name: "Process & Instrumentation Diagram", percentage: 30 }, // 30% width
        { name: "Hydraulics", percentage: 20 }, // 20% width
        { name: "Equipment Design", percentage: 20 },
        { name: "Safety Studies", percentage: 20 },
        { name: "Commissioning", percentage: 10 },
    ];

    // Generate colors for the tasks dynamically
    const startColor = "#2d6b64"; // Starting color of the gradient
    const endColor = "#67e7a0"; // Ending color of the gradient
    const numColors = tasks.length;

    // Generate the color gradient
    const colors = chroma.scale([startColor, endColor]).mode('lab').colors(numColors);

    // Map the colors to the tasks
    const updatedTasks = tasks.map((task, index) => ({
        ...task,
        color: colors[index], // Assign a generated color
    }));
    return (
        <>
            <div className='flex-col-10' style={{ background: 'white', width: '100%', borderRadius: '8px' }}>
                <div className='zp-weight-l' style={{ borderBottom: '1px #ccc solid', fontSize: '28px', fontFamily: 'var(--font-family-1)', width: '100%', paddingBottom: '4px' }}>
                    <span>Today</span>
                </div>
                <div className='dash-bar-chart-wrapper' style={{ paddingTop: '10px', width: '100%' }}>


                    <div className='dash-bar-chart'>
                        <div className='flex-row-40'>
                            <div>
                                <div className='zp-weight-l' style={{ fontSize: '14px', fontFamily: 'var(--font-family-1)' }}>Project<span style={{ marginLeft: '10px' }}><FontAwesomeIcon icon={faChevronDown} /></span></div>
                                <div className='zp-weight-m' style={{ fontSize: '16px', fontFamily: 'var(--font-family-1)', marginTop: '4px' }}>PROJ0001</div>
                            </div>
                            <div>
                                <div className='zp-weight-l' style={{ fontSize: '14px', fontFamily: 'var(--font-family-1)' }}>Project Budget<span style={{ marginLeft: '10px' }}><FontAwesomeIcon icon={faChevronDown} /></span></div>
                                <div className='' style={{ fontSize: '16px', fontFamily: 'var(--font-family-1)', marginTop: '4px' }}>A$600,578</div>
                            </div>
                        </div>
                        <div style={{ marginTop: '5px', padding: '5px 0px' }}>
                            <TaskBatteryChart tasks={updatedTasks} width={'100%'} height={'80px'} />
                        </div>
                    </div>



                    <div className='task-info-wrapper'>
                        <div className='task-info-1 flex-col-0' style={{ height: '100%' }}>
                            <div className='zp-weight-m' style={{ fontSize: '12px', fontFamily: 'var(--font-family-1)' }}>Project Completion<span style={{ marginLeft: '10px' }}><FontAwesomeIcon icon={faChevronDown} /></span></div>
                            <div className='flex-row-10' style={{ fontSize: '16px', fontFamily: 'var(--font-family-1)', marginTop: '4px' }}>
                                <span style={{ fontFamily: 'inherit' }}>60%</span>
                                <span
                                    className='zp-weight-m'
                                    style={{
                                        textWrap: 'nowrap',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        padding: '1px 6px',
                                        color: 'rgb(0, 85, 188)',
                                        backgroundColor: 'rgb(207, 245, 246)'
                                    }}>
                                    RevB
                                </span>
                            </div>
                            <span className='zp-font-small' style={{ color: '#aaa' }}>Nov 13 Est. Completion</span>
                        </div>
                        <div className='task-info-2 justify-end flex-col-0' style={{ height: '100%' }}>
                            <div className='zp-weight-m' style={{ fontSize: '12px', fontFamily: 'var(--font-family-1)' }}>Next Task<span style={{ marginLeft: '10px' }}><FontAwesomeIcon icon={faChevronDown} /></span></div>
                            <div className='zp-font-m' style={{marginTop: '4px', marginBottom: '2px' }}>Hydraulics</div>
                            <span className='zp-font-small' style={{ color: '#aaa' }}>Today Est. Completion</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const TaskBatteryChart = ({ tasks, height, width }) => {
    // Ensure each task has a color; fallback to a default if not provided
    const getColor = (task) => task.color || "#ccc";

    const getCurrentTime = () => {
        const now = new Date();
        return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true });
    };


    const getCurrentTimePosition = () => {
        const now = new Date();
        //const start = new Date(startDate);
        //const end = new Date(endDate);

        //if (now < start) return 0;
        //if (now > end) return 100;

        //const totalDuration = end - start;
        //const elapsedDuration = now - start;

        //return (elapsedDuration / totalDuration) * 100; // Position as a percentage
        return (1 / 5) * 100
    };


    return (

        <>
            <div style={{ position: 'relative' }}>
                <span className='zp-font-small' style={{ color: '#aaa' }}>{getCurrentTime()}</span>
                <div className='segment-chart-container' style={{ position: 'relative' }}>
                    <div className='segment-chart' style={{ display: "flex", alignItems: "center", borderRadius: '8px', overflow: 'hidden' }}>
                        {/* Battery Body */}
                        <div
                            style={{
                                width: width ? width : "200px",
                                height: height ? height : "50px",
                                display: "flex",
                                backgroundColor: "#f3f3f3",
                                overflow: "hidden", // Prevent overflow of segments
                                posiiton: "relative",
                            }}
                        >
                            {/* Task Segments */}
                            {tasks.map((task, index) => (
                                <>
                                    <div className='bar-segment' key={index} style={{ width: `${task.percentage}%`, backgroundColor: getColor(task), height: "100%" }} title={task.name}>
                                        <div className='bar-segment-child zp-font-s justify-start' style={{ position: 'absolute', top: '100%', width: '100%', left: 0 }}>
                                            <div className='align-center'>
                                                <span
                                                    style={{
                                                        height: '10px',
                                                        width: '10px',
                                                        borderRadius: '4px',
                                                        backgroundColor: getColor(task),
                                                    }}
                                                ></span>
                                                <span
                                                    className='zp-weight-m zp-font-small'
                                                    style={{
                                                        //margin: 'auto',
                                                        padding: '2px 6px',
                                                        borderRadius: '10px',
                                                        //backgroundColor: 'black',
                                                        overflow: 'hidden',
                                                        //border: '1px solid black'
                                                    }}
                                                >{task.name}</span>
                                            </div>

                                        </div>
                                    </div>

                                </>

                            ))}

                        </div>
                        <div
                            style={{
                                position: "absolute",
                                left: `${getCurrentTimePosition()}%`,
                                top: 0,
                                bottom: 0,
                                width: "2px",
                                backgroundColor: "#aaa", // Marker color
                                pointerEvents: "none", // Prevent interference
                            }}
                        />
                        {/* Battery Terminal */}

                    </div>
                </div>
                <div className='segment-chart-dates justify-between'>
                    <span className='zp-font-small' style={{ color: '#aaa' }}>Nov 5 2024</span>
                    <span className='zp-font-small' style={{ color: '#aaa' }}>Nov 13 2024</span>
                </div>
            </div>
        </>
    );
};

export function Projects() {
    return (
        <>
            <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px', }}>
                <div className='zp-weight-l zp-font-l justify-start' style={{ borderBottom: '1px solid #eee', paddingBottom: '5px', padding: '0px 5px' }}>Projects</div>
                <div className='flex-col-0' style={{ flex: 1 }}>
                    <div className='flex-col-0' >
                        <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                            <div className='flex-col-0'>
                                <div className=' zp-font-m justify-start'>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>PROJ0004</span>
                                </div>
                                <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                        Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                    </span>
                                </div>
                            </div>
                            <Tag label={'100%'} color={'green'} />
                        </div>
                        <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                            <div className='flex-col-0'>
                                <div className=' zp-font-m justify-start'>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>PROJ0003</span>
                                </div>
                                <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                        Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                    </span>
                                </div>
                            </div>
                            <Tag label={'60%'} color={'rgb(0, 85, 188)'} />
                        </div>
                        <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                            <div className='flex-col-0'>
                                <div className=' zp-font-m justify-start'>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>PROJ0002</span>
                                </div>
                                <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                        Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                    </span>
                                </div>
                            </div>
                            <Tag label={'60%'} color={'rgb(0, 85, 188)'} />

                        </div>
                        <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                            <div className='flex-col-0'>
                                <div className=' zp-font-m justify-start'>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>PROJ0001</span>
                                </div>
                                <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                        Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                    </span>
                                </div>
                            </div>
                            <Tag label={'100%'} color={'green'} />
                        </div>
                    </div>
                    <div className='justify-between' style={{ padding: '10px 5px', marginTop: 'auto' }}>
                        <div className='zp-font-m flex-row-5'>
                            <span>4 of </span>
                            <Button1 label={'40 results'} onClick={() => { }} />
                        </div>
                        <div className='zp-font-m' style={{ color: '#aaa' }}>
                            <span>Updated 2:20 AM</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function PIDStatusAnalytics() {
    return (
        <>
            <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px', }}>
                <div className='zp-weight-l zp-font-l justify-start' style={{ borderBottom: '1px solid #eee', paddingBottom: '5px', padding: '0px 5px' }}>P&ID Status</div>
                <div className='flex-col-0' style={{ flex: 1 }}>
                    <div className='flex-col-0' >
                        <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                            <div className='flex-col-0'>
                                <div className=' zp-font-m justify-start'>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>Murray darling WWTP</span>
                                </div>
                                <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                        Nov 18, 2024, 4:56 AM {'\u00B7'} PROJ0004
                                    </span>
                                </div>
                            </div>
                            <Tag label={'As Built'} color={'green'} />
                        </div>
                        <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                            <div className='flex-col-0'>
                                <div className=' zp-font-m justify-start'>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>Ammonia absorption tower</span>
                                </div>
                                <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                        Nov 18, 2024, 4:56 AM {'\u00B7'} PROJ0003
                                    </span>
                                </div>
                            </div>
                            <Tag label={'RevB'} color={'rgb(0, 85, 188)'} />
                        </div>
                        <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                            <div className='flex-col-0'>
                                <div className=' zp-font-m justify-start'>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>HyDAF 400 install</span>
                                </div>
                                <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                        Nov 18, 2024, 4:56 AM {'\u00B7'} PROJ0002
                                    </span>
                                </div>
                            </div>
                            <Tag label={'RevD'} color={'rgb(0, 85, 188)'} />

                        </div>
                        <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                            <div className='flex-col-0'>
                                <div className=' zp-font-m justify-start'>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>Lithium crystallizer design & install</span>
                                </div>
                                <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                    <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                        Nov 18, 2024, 4:56 AM {'\u00B7'} PROJ0001
                                    </span>
                                </div>
                            </div>
                            <Tag label={'As Built'} color={'green'} />
                        </div>
                    </div>
                    <div className='justify-between' style={{ padding: '10px 5px', marginTop: 'auto' }}>
                        <div className='zp-font-m flex-row-5'>
                            <span>4 of </span>
                            <Button1 label={'40 results'} onClick={() => { }} />
                        </div>
                        <div className='zp-font-m' style={{ color: '#aaa' }}>
                            <span>Updated 2:20 AM</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function InvoiceStatusAnalytics() {
    return (
        <>
            <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px', }}>
                <div className='zp-weight-l zp-font-l justify-start' style={{ borderBottom: '1px solid #eee', paddingBottom: '5px', padding: '0px 5px' }}>Invoices</div>
                <div className='flex-col-0' style={{ flex: 1 }}>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $350.45 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0001</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Pending'} color={'#596571'} backgroundColor={'#EBEEF1'} />
                    </div>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $350.45 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0002</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Pending'} color={'#596571'} backgroundColor={'#EBEEF1'} />
                    </div>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $450,000.00 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0003</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Past Due'} color={'#C0123C'} backgroundColor={'#FDE9EE'} />

                    </div>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $65,000.00 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0004</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Paid'} color={'green'} />
                    </div>

                    <div className='justify-between' style={{ padding: '10px 5px', marginTop: 'auto' }}>
                        <div className='zp-font-m flex-row-5'>
                            <span>4 of </span>
                            <Button1 label={'67 results'} onClick={() => { }} />
                        </div>
                        <div className='zp-font-m' style={{ color: '#aaa' }}>
                            <span>Updated 2:20 AM</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Invoices() {
    return (
        <>
            <div className='flex-col-0' style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px' }}>
                <div className='zp-weight-l zp-font-l justify-start' style={{ borderBottom: '1px solid #eee', paddingBottom: '5px', padding: '0px 5px' }}>Invoice Status</div>
                <div className='flex-col-0' style={{ flex: 1 }}>
                    <div className='flex-col-0' >
                        <div className='flex-row-0' style={{ width: '100%', height: '10px', marginTop: '10px', borderRadius: '4px', overflow: 'hidden' }}>
                            <div style={{ width: '40%', height: '100%', backgroundColor: '#96f' }}></div>
                            <div style={{ width: '35%', height: '100%', backgroundColor: '#0073e6' }}></div>
                            <div style={{ width: '25%', height: '100%', backgroundColor: '#00c4c4' }}></div>
                        </div>
                        <div>
                            <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee' }}>
                                <div className='flex-row-0 align-center'>
                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#96f', borderRadius: '4px', overflow: 'hidden' }}></div>
                                    <span className=' zp-font-m' style={{ color: '', marginLeft: '10px' }}>Paid</span>
                                </div>
                                <span className='zp-font-m zp-weight-m'>A$400,000.00</span>
                            </div>
                            <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee' }}>
                                <div className='flex-row-0 align-center'>
                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#0073e6', borderRadius: '4px', overflow: 'hidden' }}></div>
                                    <span className=' zp-font-m' style={{ color: '', marginLeft: '10px' }}>Open</span>
                                </div>
                                <span className='zp-font-m zp-weight-m'>A$250,000.00</span>
                            </div>
                            <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee' }}>
                                <div className='flex-row-0 align-center'>
                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#00c4c4', borderRadius: '4px', overflow: 'hidden' }}></div>
                                    <span className=' zp-font-m' style={{ color: '', marginLeft: '10px' }}>Past Due</span>
                                </div>
                                <span className='zp-font-m zp-weight-m'>A$1,000.00</span>
                            </div>
                            <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee' }}>
                                <div className='flex-row-0 align-center'>
                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#ED6804', borderRadius: '4px', overflow: 'hidden' }}></div>
                                    <span className=' zp-font-m' style={{ color: '', marginLeft: '10px' }}>Uncollectable</span>
                                </div>
                                <span className='zp-font-m zp-weight-m'>A$0.00</span>
                            </div>
                            <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee' }}>
                                <div className='flex-row-0 align-center'>
                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#B3063D', borderRadius: '4px', overflow: 'hidden' }}></div>
                                    <span className=' zp-font-m' style={{ color: '', marginLeft: '10px' }}>Void</span>
                                </div>
                                <span className='zp-font-m zp-weight-m'>A$0.00</span>
                            </div>
                        </div>
                    </div>
                    <div className='justify-between' style={{ padding: '10px 5px' }}>
                        <div className='zp-font-m flex-row-5'>

                            <Button1 label={'View more'} onClick={() => { }} />
                        </div>
                        <div className='zp-font-m' style={{ color: '#aaa' }}>
                            <span>Updated 2:20 AM</span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export function InvoiceDue() {
    return (
        <>
            <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px', }}>
                <div className='zp-weight-l zp-font-l justify-start' style={{ borderBottom: '1px solid #eee', paddingBottom: '5px', padding: '0px 5px' }}>Upcoming invoices</div>
                <div className='flex-col-0' style={{ flex: 1 }}>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $350.45 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0001</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Pending'} color={'#596571'} backgroundColor={'#EBEEF1'} />
                    </div>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $350.45 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0002</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Pending'} color={'#596571'} backgroundColor={'#EBEEF1'} />
                    </div>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $450,000.00 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0003</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Pending'} color={'#596571'} backgroundColor={'#EBEEF1'} />

                    </div>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $65,000.00 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0004</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Pending'} color={'#596571'} backgroundColor={'#EBEEF1'} />
                    </div>

                    <div className='justify-between' style={{ padding: '10px 5px', marginTop: 'auto' }}>
                        <div className='zp-font-m flex-row-5'>
                            <span>4 of </span>
                            <Button1 label={'35 results'} onClick={() => { }} />
                        </div>
                        <div className='zp-font-m' style={{ color: '#aaa' }}>
                            <span>Updated 2:20 AM</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function InvoicePastDue() {
    return (
        <>
            <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px', }}>
                <div className='zp-weight-l zp-font-l justify-start' style={{ borderBottom: '1px solid #eee', paddingBottom: '5px', padding: '0px 5px' }}>Invoices past due</div>
                <div className='flex-col-0' style={{ flex: 1 }}>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $350.45 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0001</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Past Due'} color={'#C0123C'} backgroundColor={'#FDE9EE'} />
                    </div>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $350.45 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0002</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Past Due'} color={'#C0123C'} backgroundColor={'#FDE9EE'} />
                    </div>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $450,000.00 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0003</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Past Due'} color={'#C0123C'} backgroundColor={'#FDE9EE'} />

                    </div>
                    <div className='justify-between hover-style-2 zp-transition-200' style={{ padding: '10px 5px', borderBottom: '1px solid #eee', borderRadius: '8px' }}>
                        <div className='flex-col-0'>
                            <div className=' zp-font-m justify-start'>
                                $65000.00 <a style={{ color: '#aaa' }}><span>&nbsp;</span>{'\u00B7'} PROJ0004</a>
                            </div>
                            <div className=' zp-font-s justify-start flex-row-5' style={{ color: '#aaa' }}>
                                <span style={{ display: 'block', overflowWrap: 'break-word', wordBreak: 'break-word', textAlign: 'start', }}>
                                    Nov 18, 2024, 4:56 AM {'\u00B7'} dpricework2@gmail.com
                                </span>
                            </div>
                        </div>
                        <Tag label={'Past Due'} color={'#C0123C'} backgroundColor={'#FDE9EE'} />
                    </div>

                    <div className='justify-between' style={{ padding: '10px 5px', marginTop: 'auto' }}>
                        <div className='zp-font-m flex-row-5'>
                            <span>4 of </span>
                            <Button1 label={'7 results'} onClick={() => { }} />
                        </div>
                        <div className='zp-font-m' style={{ color: '#aaa' }}>
                            <span>Updated 2:20 AM</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


function SupplierDashWrapper() {
    const { isToggled } = useToggle();

    const handleDateChange = (date) => {
        // console.log("Selected Date:", date);
    };

    return (
        <>
            <div
                style={{
                    height: isToggled ? 'calc(100vh - 62px - 44px)' : 'calc(100vh - 62px)',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    padding: '16px',
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    borderTop: '1px solid #ccc'
                }}
            >
                <div style={{ maxWidth: '1300px', margin: '0 auto', height: '100%', width: '100%' }}>
                    <div className='flex-col-40'>
                        <DashBarChart />
                        <div style={{ borderBottom: '1px solid #ccc' }}></div>
                        <AnalyticsGrid />
                    </div>
                </div>
            </div>




        </>
    )
}


export default SupplierDashWrapper;
//
//<div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
//                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
//                            <span style={{ fontFamily: 'var(--font-family-1)', fontWeight: 700, fontSize: '28px' }}>Welcome back, {user.given_name}!</span>
//                            <span style={{ fontFamily: 'var(--font-family-1)', fontSize: '22px' }}>
//                                Browse your
//                                <span style={{ color: 'var(--brand-color-1)', fontWeight: 500 }}>
//                                    {" "}sales analytics
//                                </span>
//                                <span> or </span>
//                                <span style={{ color: 'var(--brand-color-1)', fontWeight: 500 }}>
//                                    explore all the ways{" "}
//                                </span>
//                                to start using Zephyr.</span>
//                        </div>
//                        <img src={LogoOnly} style={{ maxWidth: '150px', maxHeight: '150px', marginLeft: 'auto' }}></img>
//                    </div>
//
//
//
//<div style={{ borderBottom: 'solid #ccc 1px' }}>
//                            <span style={{ fontFamily: 'var(--font-family-1)', fontWeight: 700, fontSize: '28px' }}>Today</span>
//                        </div>
//                        <div style={{ display: 'flex', flexDirection: 'row' }}>
//
//                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2px' }}>
//                                <div style={{ width: '40%' }}>Gross Volume ${sum} </div>
//                                <div><DatePicker onDateChange={handleDateChange} /></div>
//                                <div><DateRangePicker /></div>
//                                <Time />
//                                <SparkLine data={data} width={'100%'} height={100} color="var(--brand-color-1)" cumulative={true} />
//                                <div style={{ display: 'flex' }}>
//                                    <span style={{ flexGrow: 1, fontSize: '14px', fontFamily: 'var(--font-family-1)', color: '#aaa' }}>12:00 AM</span>
//                                    <span style={{ fontSize: '14px', fontFamily: 'var(--font-family-1)', color: '#aaa' }}>11:59 PM</span>
//                                </div>
//                            </div>
//
//                        </div>
//                        <div style={{ display: 'flex', flexDirection: 'row' }}>
//
//                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2px' }}>
//                                <div style={{ width: '40%' }}>Net Volume ${sum} </div>
//                                <div><DatePicker onDateChange={handleDateChange} /></div>
//                                <Time />
//                                <SparkLine data={data} width={'100%'} height={100} color="var(--brand-color-1)" cumulative={false} />
//                                <div style={{ display: 'flex' }}>
//                                    <span style={{ flexGrow: 1, fontSize: '14px', fontFamily: 'var(--font-family-1)', color: '#aaa' }}>12:00 AM</span>
//                                    <span style={{ fontSize: '14px', fontFamily: 'var(--font-family-1)', color: '#aaa' }}>11:59 PM</span>
//                                </div>
//                            </div>
//
//                        </div>