import React, {useEffect, useState} from 'react'

import ScalingBox from './ScalingBox.js'

import SvgArrow1 from './svgs/SvgArrow1';
import SvgArrow2 from './svgs/SvgArrow2';
import SvgArrow3 from './svgs/SvgArrow3';
import SvgArrow4 from './svgs/SvgArrow4';
import SvgArrow5 from './svgs/SvgArrow5';
import SvgArrow6 from './svgs/SvgArrow6';
import SvgArrow7 from './svgs/SvgArrow7';
import SvgArrow8 from './svgs/SvgArrow8';
import SvgArrow9 from './svgs/SvgArrow9';
import SvgArrow10 from './svgs/SvgArrow10';





function ScalingBoxGridComponent() {

    const gridSize = 54; 
    const grid = Array.from({ length: gridSize });

    const animationTime = 16000
    
    const delays = [
        [20000 , 20000 , 12000, 30000, 40000, 50000],
        [20000 , 12000 , 11600, 6600 , 11600, 12000],
        [600   , 20000 , 6600 , 30000, 6600 , 50000],
        [100   , 20000 , 6300 , 5950 , 11700, 12000],
        [0     , 100   , 6000 , 925  , 40000, 12300],
        [11200 , 20000 , 20000, 5950 , 6350 , 50000],
        [11000 , 6900  , 20000, 30000, 6600 , 50000],
        [11200 , 11000 , 11200, 30000, 40000, 50000],
        [11500 , 11500 , 11500, 30000, 40000, 50000],
    ]

    const durations = [
        [5000, 5000, 3950, 5000,  5000, 5000],
        [5000, 3950, 5000, 9250,  5000, 3950],
        [5000, 5000, 5100, 5000,  9250, 5000],
        [6000, 5000, 5000, 6000,  5000, 4250],
        [5000, 6000, 5000, 9250,  5000, 4250],
        [4400, 5000, 5000, 5000,  4100, 5000],
        [5000, 8600, 5000, 5000,  4200, 5000],
        [4400, 5000, 4400, 5000,  5000, 5000],
        [4400, 5000, 4400, 5000,  5000, 5000],
    ]
    

    const gridObjects = [
        [0, 0, 1,  0, 0, 0],
        [0, 1, 3,  1, 2, 1],
        [1, 0, 1,  0, 1, 0],
        [9, 0, 1,  6, 5, 1],
        [1, 7, 10, 1, 0, 1],
        [1, 0, 0,  5, 1, 0],
        [8, 1, 0, 0, 1, 0],
        [1, 4, 1,  0, 0, 0],
        [1, 0, 1,  0, 0, 0],
    ]

    const animatingTypes = [
        ["animating", "animating", "animating", "animating", "animating", "animating"],
        ["animating", "animating", "animating", "animating", "animating", "animating"],
        ["animating", "animating", "animating", "animating", "animating", "animating"],
        ["animating", "animating", "animating", "animating", "animating", "animating"],
        ["animating", "animating", "animating", "PID"      , "animating", "animating"],
        ["animating", "animating", "animating", "animating", "animating", "animating"],
        ["animating", "animating", "animating", "animating", "animating", "animating"],
        ["animating", "animating", "animating", "animating", "animating", "animating"],
        ["animating", "animating", "animating", "animating", "animating", "animating"]
      ]

    const labels = [
        [''    , '', 'FAT/ SAT', '', '', ''],
        [''    , 'ITPs', '', 'Commission', '', 'Document Control'],
        ['Fabrication'    , '', 'PFDs', '', 'Safety', ''],
        [''    , '', 'BFDs', '', '', 'HAZOP'],
        ['Zephyr Market', '', '', 'P&IDs', '', 'Bowtie Analysis'],
        ['Hydraulics'    , '', '', '', 'Plant Simulation', ''],
        [''    , 'Plant Design', '', '', '3D Modelling', ''],
        ['Economics'    , '', 'EQL', '', '', ''],
        ['Detailed Design'    , '', 'Mass Energy Balance', '', '', ''],
    ];





    return (
        <>
        



            <div className='scaling-box-wrapper' style={{padding: '20px'}}>
                <div className="scaling-box-grid">
                  {grid.map((_, index) => {
                        const row = Math.floor( index / 6 )

                        const col = index % 6 
                        //// console.log(row,col);
                        const value = gridObjects[row][col]





                        switch (value) {
                            case 1:
                              return (
                                <ScalingBox 
                                    key={index}    
                                    delay={delays[row][col]} 
                                    duration={durations[row][col]} 
                                    animationTime={animationTime} 
                                    animatingType={animatingTypes[row][col]}
                                    label={labels[row][col]}
                                />
                              );
                            case 2: 
                                return (
                                    <SvgArrow1 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                            case 3:
                                return (
                                    <SvgArrow2 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                            case 4: 
                                return (
                                    <SvgArrow3 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                            case 5: 
                                return (
                                    <SvgArrow4 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                            case 6: 
                                return (
                                    <SvgArrow5 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                            case 7: 
                                return (
                                    <SvgArrow6 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                                case 8: 
                                return (
                                    <SvgArrow7 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                                case 9: 
                                return (
                                    <SvgArrow8 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                                case 10: 
                                return (
                                    <SvgArrow9 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                                case 11: 
                                return (
                                    <SvgArrow10 key={index} delay={delays[row][col]} duration={durations[row][col]} animationTime={animationTime}/>
                                )
                            default:
                              return <div style={{height: '78px', width: '78px'}}></div>;
                        }
                    })}
                </div>
            </div>
        
        </>
    )
}





export default ScalingBoxGridComponent