


import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation  } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext.js' //


import { useToggle } from '../../../../contexts/ToggleContext.js';

//import ZephyrInnovaitionsLogo2 from './../../../assets/Zephyr Innovations Logo 2 (4).svg'; //
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavigationPanelButton from './GenericComponents/NavigationPanelButton.js';
import NavigationPanelContent from './NavigationPanelContent.js';





function SupplierNavigationPanel() {
    const { user, setUser } = useAuth();
    const { isToggled } = useToggle();
    const navigate = useNavigate()
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [globalNavType, setGlobalNavType] = useState(null)
    useEffect(() => {
        if (user && user.userType === 'supplier') {
            setGlobalNavType('/supplier')
        } else if (user && user.userType === 'customer') {
            setGlobalNavType('/customer')
        }
    },[])

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
      };  

    function handleMobileNavClick(navType) {
        let targetPath = '';
        
        if (user && user.userType === 'supplier') {
          targetPath = `/supplier${navType}`;
        } else if (user && user.userType === 'customer') {
          targetPath = `/customer${navType}`;
        }
      
        if (location.pathname !== targetPath) {
          navigate(targetPath);
        } else {
          toggleMenu();
        }
      }
    
      function handleLogout() {
        setUser(null)
      }
    
      function handleLogin() {
        navigate('/login')
      }

      useEffect(() => {
        // console.log(location.pathname);
      },[location])


    return (
        <>            
            <nav className=" flex-col px-6 bg-white border-r " style={{paddingTop: '17.8px', paddingBottom: '17.8px', height: isToggled ? 'calc(100vh - 44px)': '100vh', display: 'flex'}}>
              <div className="flex items-center mb-4" style={{}}>
                <button className="navbar-burger flex items-center text-blue-600 p-1 bg-transparent border-none cursor-pointer" onClick={toggleMenu}>
                  <FontAwesomeIcon className="block h-4 w-4 fill-current" icon={faBars}/>
                </button>
                <span className='company-element'>
                  {user.company}
                </span>
              </div>
              <div className="overflow-y-auto" style={{scrollbarWidth: 'thin', flexGrow: 1}}>
                <div style={{flexGrow: 1}}>
                    <NavigationPanelContent
                        globalNavType={globalNavType}
                        handleMobileNavClick={handleMobileNavClick}
                    />
                </div>
              </div>






              <div className="mt-auto" style={{borderTop: '1px solid #d8dee4'}}>
                    <div className={`pt-6 ${!user ? '' : 'hidden'}`}>
                        <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-600 hover:bg-blue-700 rounded-xl cursor-pointer" onClick={handleLogin} >Log In</a>
                    </div>
                    <div className={`pt-6 ${user ? '' : 'hidden'}`}>
                        <a className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold bg-gray-50 hover:bg-gray-100 rounded-xl cursor-pointer" onClick={handleLogout}>Log Out</a>
                    </div>
                
                <p className="my-4 text-xs text-center text-gray-400">
                  <span>Copyright © 2024</span>
                </p>
              </div>

            </nav>
         
        </>
    )
}

export default SupplierNavigationPanel

