import React, { useState, useEffect } from 'react'
import { useToggle } from 'index'



function DivWrappers() {

}


export default DivWrappers;

export function DivWrapper1({ children }) {
    const { isToggled } = useToggle();

    return (
        <>
            <div className='flex-col-10' style={{ overflowY: 'auto', maxHeight: isToggled ? 'calc(var(--vh) - 238px)' : 'calc(var(--vh) - 194px', scrollbarWidth: 'thin' }}>
                {children}
            </div>
        </>
    )
}

export function DivWrapper2({ children }) {
    const { isToggled } = useToggle();

    return (
        <>
            <div className='flex-col-10' style={{ overflowY: 'auto', maxHeight: isToggled ? 'calc(var(--vh) - 62px - 44px)' : 'calc(var(--vh) - 62px', scrollbarWidth: 'thin', height: '100%' }}>

                {children}
            </div>
        </>
    )
}










