import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext.js'
const FormDataContext = createContext();

export const useFormData = () => useContext(FormDataContext);

export const FormDataProvider = ({ children }) => {
    const {user} = useAuth()
    const [showBillingDetails, setShowBillingDetails] = useState(false);

    const [formData, setFormData] = useState({
        given_name: `${user?.given_name}`,
        family_name: `${user?.family_name}`,
        company: `${user?.company}`,
        abn: `${user?.abn}`,
        contact_number: `${user?.contact_number}`,
        suite: '',
        location: '',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: '',
        latitude: '',
        longitude: '',
        distanceKm: '',
    });

    const handleUseAddressClick = () => {
        if (user && user.previous_delivery) {
            updateFormData({
                suite: user.previous_delivery.address.line2,
                location: user.previous_delivery.address.line1,
                locality: user.previous_delivery.address.city,
                administrative_area_level_1: user.previous_delivery.address.state,
                postal_code: user.previous_delivery.address.postal_code,
                country: user.previous_delivery.address.country,
                latitude: user.previous_delivery.geolocation.latitude,
                longitude: user.previous_delivery.geolocation.longitude,
            });
        }
    };

    const [formData2, setFormData2] = useState({
        given_name: `${user?.given_name}`,
        family_name: `${user?.family_name}`,
        company: `${user?.company}`,
        contact_number: `${user?.contact_number}`,
        suite: '',
        location: '',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: '',
        latitude: '',
        longitude: '',
        distanceKm: '',
    });

    const handleUseBillingClick = () => {
        if (user && user.previous_billing) {
            updateFormData2({
                suite: user.previous_billing.address.line2,
                location: user.previous_billing.address.line1,
                locality: user.previous_billing.address.city,
                administrative_area_level_1: user.previous_billing.address.state,
                postal_code: user.previous_billing.address.postal_code,
                country: user.previous_billing.address.country,
                latitude: user.previous_billing.geolocation.latitude,
                longitude: user.previous_billing.geolocation.longitude,
            });
        }
    };   
    
    const [formData3, setFormData3] = useState({
        given_name: '',
        family_name: '',
        company: '',
        contact_number: '',
        suite: '',
        location: '',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: '',
        latitude: '',
        longitude: '',
        distanceKm: '',
    });

    

    const updateFormData = (newData) => {
        setFormData(prev => ({ ...prev, ...newData }));
    };

    const updateFormData2 = (newData) => {
        setFormData2(prev => ({ ...prev, ...newData }));
    };

    const updateFormData3= (newData) => {
        setFormData3(prev => ({ ...prev, ...newData }));
    };

    useEffect(() => {
        if (!showBillingDetails) {
            // When checkbox is checked, synchronize formData3 with formData
            updateFormData3(formData);
        } else {
            // When checkbox is unchecked, synchronize formData3 with formData2
            updateFormData3(formData2);
        }
    }, [showBillingDetails, formData, formData2]);  

    return (
        <FormDataContext.Provider value={{ formData, updateFormData, handleUseAddressClick, formData2, updateFormData2, formData3, updateFormData3, handleUseBillingClick, showBillingDetails, setShowBillingDetails }}>
            {children}
        </FormDataContext.Provider>
    );
};

