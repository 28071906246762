

import React, { useState, useEffect, useRef } from 'react'
import { gsap } from "gsap";
import { TabNavigation, FitlerBox, useAuth, useToggle, GenericInputBox2, GenericBusinessDetails, TwoFactorAuth, Button4 } from 'index.js'
import { faPlus, faChevronLeft, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation } from 'react-router-dom';
import Spreadsheet from './Spreadsheet';

function Profile2() {

    const navigate = useNavigate()
    const { isToggled } = useToggle();

    const { user } = useAuth()

    function handleNavClick() {
        let targetPath = '';
        if (user && user.userType === 'supplier') {
            targetPath = `/supplier/settings`;
            navigate(targetPath);
        } else if (user && user.userType === 'customer') {
            targetPath = `/customer/settings`;
            navigate(targetPath);
        }
    }

    return (
        <>
            <div style={{ display: 'flex', fontSize: '14px', fontWeight: 700, fontFamily: 'var(--font-family-1)', color: '#339AF0' }}>
                <div onClick={handleNavClick} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faChevronLeft} color={'#545969'} />
                    {' '}Settings
                </div>
            </div>
            <div style={{ fontSize: '24px', fontWeight: 700, marginBottom: '10px' }}>Profile</div>
            <div className='flex-col-40'>
                <div className='flex-col-10'>
                    <div className='flex-row-10 justify-between' style={{ borderBottom: '1px #ccc solid', paddingBottom: '8px' }}>
                        <div className='zp-font-xlarge zp-weight-l'>User</div>
                        <div className='zp-font-medium'>
                            <Button4 label={"Edit"} onClick={() => {}}/>
                        </div>
                    </div>
                    <div className='flex-col-30 zp-font-medium' style={{ marginTop: '30px' }}>
                        <div className='flex-row-10'>
                            <span className='zp-weight-m' style={{ minWidth: '10%' }}>Email</span>
                            <span>declan.price@zephyrinnovations.com.au</span>
                        </div>
                        <div className='flex-row-10'>
                            <span className='zp-weight-m' style={{ minWidth: '10%' }}>Name</span>
                            <span>Declan John Grahame Price</span>
                        </div>
                    </div>
                </div>
                <div className='flex-col-10'>
                    <div className='flex-row-10 justify-between' style={{  }}>

                        <div className='flex-col-0'>
                            <div className='zp-font-xlarge zp-weight-l'>Two-step Authentication</div>
                            <div className='zp-font-medium'>Increase security on your account by using multiple authentication methods</div>
                        </div>

                        <div className='zp-font-medium align-center' style={{whiteSpace: 'nowrap'}}>
                            <Button4 label={"Add two-step authentication" } onClick={() => {}}/>
                            
                            
                        </div>

                    </div>
                    <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
                        <thead style={{ borderTop: '1px solid #ddd' }}>
                            <tr>
                                <td className='team-table-header'>METHOD</td>
                                <td className='team-table-header'>DEVICE</td>
                                <td className='team-table-header'>DATE ADDED</td>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='team-table-td'>
                                    Authenticator app
                                </td>
                                <td className='team-table-td'>

                                </td>
                                <td className='team-table-td'>
                                    Janurary 20, 2025
                                </td>

                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>










        </>
    )
}

export default Profile2



//<TwoFactorAuth />

//<Spreadsheet/>