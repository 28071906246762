import React, { useState } from 'react';






function ConfigOption({ options }) {

    const [optionFocus, setOptionFocus] = useState(null)
    const [optionValue, setOptionValue] = useState("");




    return (
        <div style={{ border: '1px #ccc solid', borderRadius: '4px', padding: '4px', fontSize: '12px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                <div style={{ border: 'solid 1px #ccc', borderRadius: '4px', width: '30px', boxSizing: 'border-box' }}>
                    <input
                        placeholder="###"
                        style={{
                            resize: 'none',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                            boxSizing: 'border-box',
                            outline: 'none',
                            border: 'none',
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            height: '20px'
                        }}
                    />
                </div>
                <div style={{ border: 'solid 1px #ccc', borderRadius: '4px', width: '100%', boxSizing: 'border-box'}}>
                    <input
                        onFocus ={() => setOptionFocus(true)}
                        onBlur  ={() => setOptionFocus(false)}
                        placeholder="option"
                        style={{
                            resize: 'none',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                            boxSizing: 'border-box',
                            outline: 'none',
                            border: 'none',
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            height: '20px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    />
                </div>
            </div>
        </div>
    );
}



export default ConfigOption