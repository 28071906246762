


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useNavigate, useLocation  } from 'react-router-dom';

function SettingsPanelButton({globalNavType, Label, NavLocation, handleMobileNavClick, description ,icon}) {
 

    const location = useLocation();
    //const description = 'test desc test desctest desctest desctest desctest desctest desctest desctest desctest desc'

    return (
        <>
            <div className='settings-button-wrapper' onClick={() => handleMobileNavClick(NavLocation)}>
                <div className='settings-button-container'>
                    <div style={{height: '100%', minWidth: '31px', display: 'flex',justifyContent: 'center',padding: '5px',borderRadius: '2px',backgroundColor: '#f7f7f7'}}>
                        <div >
                            <FontAwesomeIcon icon={icon} color={'#2563eb'}/>
                        </div>
                    </div>
                    <div>
                        <div style={{color: '#2563eb', fontWeight: 700, fontSize: '16px' }}>
                            {Label}
                        </div>
                        <div style={{fontSize: '14px'}}>
                            {description}
                        </div>
                    </div>
                </div>
                
                
                
                
                
            </div>
        </>
    )
}

export default SettingsPanelButton