import React, { useState, useEffect, useRef } from 'react';
import './ScalingBox.css';

const ScalingBox = ({ delay, duration, animationTime, animatingType, label}) => {
  
  const [isAnimating, setIsAnimating] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(true);


  const [animationType, setAnimationType] = useState('animating')

  useEffect(() => {
    if (animatingType && animatingType === 'PID') {
      setAnimationType('PID')
    }
    if (animatingType && animatingType === 'COM') {
      setAnimationType('COM')
    }
    if (animatingType && animatingType === 'SAFE') {
      setAnimationType('SAFE')
    }
    if (animatingType && animatingType === 'PD') {
      setAnimationType('PD')
    }
  },[animatingType])

  const animationTimeoutRef = useRef(null);
  const startTimeRef = useRef(null);
  const remainingTimeRef = useRef(delay); 

  useEffect(() => {
    // Handle tab visibility change
    const handleVisibilityChange = () => {
      setIsTabVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const startAnimation = () => {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, duration);
    };

    const scheduleAnimation = (delay) => {
      startTimeRef.current = Date.now();
      animationTimeoutRef.current = setTimeout(() => {
        startAnimation();
        remainingTimeRef.current = animationTime; // Reset for the next interval
        scheduleAnimation(remainingTimeRef.current); // Schedule the next animation
      }, delay);
    };

    if (isTabVisible) {
      // Resume animation with remaining time
      scheduleAnimation(remainingTimeRef.current);
    } else {
      // Pause animation
      clearTimeout(animationTimeoutRef.current);
      const elapsed = Date.now() - startTimeRef.current;
      remainingTimeRef.current = Math.max(remainingTimeRef.current - elapsed, 0); // Avoid negative remaining time
    }

    return () => clearTimeout(animationTimeoutRef.current);
  }, [isTabVisible]);

  return (
    <div
      className={`scaling-box ${isAnimating ? `${animationType}` : ''}`}
      style={{
        animationDuration: `${duration/1000}s`, // Dynamically set the duration
      }}
      >
        
        <div 
          className='scaling-box-label zp-font-small zp-weight-s align-center justify-center'
          //style={{height: '100%'}}
        >
          {label}
        </div>
        
      </div>
    
  );
};

export default ScalingBox;
