import React, { useState, useRef, useEffect } from 'react';

function List2({ selection, labels, setSelection, onClicks }) {
  const dropdownRef = useRef(null);

  useEffect(() => {
    let timer;

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        timer = setTimeout(() => {
          setSelection(''); // Ensure `setSelection` is defined or passed as a prop
        }, 100); // Defer clearing selection
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`dropdown ${selection === `${selection}` ? 'active' : ''}`}>
      <div style={{ padding: '8px' }}>
        {labels.map((label, index) => (
          <div className="font-style-1 list-item" key={index} onClick={onClicks[index]}>
            <span >
              {label}
            </span>
          </div>
        ))}
      </div>
      <div className='carret-style'></div>
    </div>
  );
}

export default List2;
