import React from 'react';
import './css/LandingPageComponent.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faMinus, faChevronRight, faChevronDown, faImage } from '@fortawesome/free-solid-svg-icons';
import ZephyrInnovaitionsLogo2 from './../../assets/Zephyr Innovations Logo 2 (1).svg';
import ScalingBoxGridComponent from './ScalingBoxGridComponent';
import PageExampleComponent from './PageExampleComponent';
import DescriptionChip from './DescriptionChip';
import { GenericInputBox2, Button3, StartNow, GenericFooter } from 'index'
function LandingPageComponent() {
  const navigate = useNavigate();

  return (
    <div className="landing-page">
      <main className="main-content">



        <section id='section_wrapper' className='justify-center' style={{ position: 'relative', display: 'flex', width: '100%', height: '900px' }}>
          <div className='test-background' style={{ zIndex: 2 }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <nav style={{ position: 'absolute', top: 0, left: 0, zIndex: 4, width: '100%' }}>
            <div className='justify-center align-center' style={{ width: '100%' }}>
              <div className='align-center justify-between' style={{ padding: '10px', width: '1080px', height: '68px' }}>
                <img style={{ height: '150px' }} src={ZephyrInnovaitionsLogo2}></img>
                <div className='flex-row-20'>

                  <div
                    className='contact-sales align-center zp-font-large zp-weight-m login-button nowrap'
                    style={{ height: '30px', paddingLeft: '10px', paddingRight: '10px', color: 'white' }}
                    onClick={() => { navigate('/contact') }}>
                    Contact sales
                  </div>

                  <div
                    className='align-center zp-font-large zp-weight-m login-button nowrap'
                    style={{ height: '30px', paddingLeft: '10px', paddingRight: '10px', color: 'white' }}
                    onClick={() => { navigate('/login') }}>
                    Sign In
                  </div>
                </div>

              </div>
            </div>
          </nav>




          <div style={{ width: '100%', maxWidth: '1080px', borderLeft: 'solid 1px #eee', borderRight: 'solid 1px #eee', paddingLeft: '10px' }}>
            <div style={{ padding: '100px 0px', minWidth: '100%', zIndex: 3, position: 'relative' }}>
              <div className='flex-row-0 justify-center' style={{ minWidth: '100%' }}>
                <div className='flex-col-40' style={{ width: '100%', fontFamily: 'var(--font-family-2)' }}>
                  <span className='zp-weight-l ' style={{ fontSize: 'clamp(60px, 8.5vw, 94px)', letterSpacing: '-0.04em', lineHeight: '1.04' }}>Engineering infrastructure to streamline your projects</span>
                  <span style={{ color: 'rgb(54, 63, 73)', fontSize: '18px', lineHeight: 1.6 }}>Empower your business with Zephyr – the trusted platform for procurement, drafting, and design. Streamline process engineering, foster collaboration, and boost efficiency across all your project verticals.</span>
                  <StartNow />
                </div>
                <div style={{ width: '100%', position: 'relative' }}>
                  <div style={{
                    position: 'absolute',
                    width: '975px',
                    height: '570px',
                    left: '126px',
                    background: 'linear-gradient(hsla(0,0%,100%,.4),#f6f9fc)',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)',

                  }}>
                    <PageExampleComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='justify-center' style={{ zIndex: 1, position: 'relative', display: 'flex', width: '100%', background: '#F6F9FC' }}>
          <div style={{ width: '100%', maxWidth: '1080px', borderLeft: 'solid 1px #eee', borderRight: 'solid 1px #eee' }}>
            <div className='flex-row-0' style={{ padding: '128px 0px' }}>
              <div style={{ width: '100%', }}>
                <div className='flex-col-10'>
                  <div className='flex-col-30 ' style={{ height: '100%', paddingBottom: '200px' }}>
                    <div className='flex-col-20'>
                      <span className='zp-font-xlarge zp-weight-m' style={{ color: 'var(--brand-color-1)', paddingLeft: '10px' }}>Marketplace</span>
                      <span className='zp-weight-l' style={{ fontSize: 'clamp(32px, 5.5vw, 56px)', letterSpacing: '-0.04em', lineHeight: '1.04', paddingLeft: '10px' }}>
                        A marketplace made for engineers
                      </span>
                    </div>
                    <div className='flex-col-10'>
                      <DescriptionChip
                        title={`Millions of parts`}
                        description={`Access a vast selection of components from trusted vendors. Whether you need standard industrial components or niche engineering parts, Zephyr consolidates offerings from multiple suppliers, ensuring that you always find the right product at competitive prices.`}
                      />
                      <DescriptionChip
                        title={`Reputable suppliers`}
                        description={`Source from industry-leading manufacturers and distributors. We partner with verified suppliers to guarantee product authenticity, reliability, and compliance with industry standards.`}
                      />
                      <DescriptionChip
                        title={`Accelerated procurement`}
                        description={`Reduce sourcing time with real-time availability and pricing. Zephyr eliminates the inefficiencies of traditional procurement by providing up-to-date inventory levels and instant pricing comparisons, allowing engineers to make informed purchasing decisions without the back-and-forth of manual quoting.`}
                      />
                      <DescriptionChip
                        title={`Integrated workflows`}
                        description={`Link procurement seamlessly with design and engineering tools. Unlike conventional marketplaces, Zephyr directly connects component selection with design processes. Engineers can incorporate real-world supplier data into P&IDs, equipment sizing, and cost modeling, ensuring a smooth transition from design to procurement with minimal data entry or redundancy.`}
                      />
                    </div>
                  </div>
                  <div className='flex-col-30 component-explaination'>
                    <div className='flex-col-20'>
                      <span className='zp-font-xlarge zp-weight-m' style={{ color: 'var(--brand-color-1)', paddingLeft: '10px' }}>Frontend Engineering Design</span>
                      <span className='zp-weight-l' style={{ fontSize: 'clamp(32px, 5.5vw, 56px)', letterSpacing: '-0.04em', lineHeight: '1.04', paddingLeft: '10px' }}>
                        Smart Engineering & Design Tools
                      </span>
                    </div>
                    <div className='flex-col-10'>
                      <DescriptionChip
                        title={`Drafting`}
                        description={`Complete P&IDs, PFDs, and BFDs with Zephyr’s advanced drafting tool. Traditional Front-End Engineering Design (FEED) is conducted in sequential stages. Zephyr accelerates this process with a top-down approach that ensures efficiency and consistency across all design phases.`}
                      />
                      <DescriptionChip
                        title={`Detailed design`}
                        description={`Zephyr’s platform integrates supplier part data to automate key engineering processes. As engineers define process conditions in the P&ID module, updates automatically propagate to mass & energy balances, equipment sizing & selection and hydraulics calculations`}
                      />
                      <DescriptionChip
                        title={`Project automation`}
                        description={`No need to redraw plant diagrams in separate software — Zephyr ensures consistency across all phases. Our smart linking system enables real-time equipment selection, automated equipment lists & bill of quantities and integrated cost models`}
                      />
                      <DescriptionChip
                        title={`Enhanced document control`}
                        description={`Zephyr provides an intuitive document management system that tracks revisions & progress to maintain consistency across engineering phases and links documents to equipment, attributing SOPs, mechanical drawings, and technical sheets to specific components. This scheme enhances reusability which ensures designs remain accessible and adaptable for future projects.`}
                      />
                      <DescriptionChip
                        title={`Project management design`}
                        description={`Organize projects efficiently with built-in planning tools.`}
                      />
                    </div>
                  </div>
                  <div className='flex-col-30 component-explaination'>
                    <div className='flex-col-20'>
                      <span className='zp-font-xlarge zp-weight-m' style={{ color: 'var(--brand-color-1)', paddingLeft: '10px' }}>Commissioning</span>
                      <span className='zp-weight-l' style={{ fontSize: 'clamp(32px, 5.5vw, 56px)', letterSpacing: '-0.04em', lineHeight: '1.04', paddingLeft: '10px' }}>
                        Integrated Commissioning & Compliance
                      </span>
                    </div>
                    <div className='flex-col-10'>
                      <DescriptionChip
                        title={`Reuasble templates`}
                        description={`Commissioning is a critical phase in process design, involving extensive testing of delivered components and constructed plants. Zephyr simplifies this phase by allowing clients to create commissioning templates that automate the creation of essential documents.`}
                      />
                      <DescriptionChip
                        title={`Document synchronization`}
                        description={`Zephyr ensures that design modifications automatically update relevant commissioning documents, preserving consistency. SOPs can be applied to specific equipment to promote reusability and efficiency across multiple projects. SATs, FATs, ITPs are updated as project specifications change.`}
                      />
                    </div>
                  </div>
                  <div className='flex-col-30 component-explaination'>
                    <div className='flex-col-20'>
                      <span className='zp-font-xlarge zp-weight-m' style={{ color: 'var(--brand-color-1)', paddingLeft: '10px' }}>Safety and Economics</span>
                      <span className='zp-weight-l' style={{ fontSize: 'clamp(32px, 5.5vw, 56px)', letterSpacing: '-0.04em', lineHeight: '1.04', paddingLeft: '10px' }}>
                        Risk & Economic Analysis
                      </span>
                    </div>
                    <div className='flex-col-10'>
                      <DescriptionChip
                        title={`Safety Studies`}
                        description={`Conduct HAZOP, LOPA, and Bowtie analyses to ensure process safety and risk mitigation. Zephyr's platform facilitates collaborative safety reviews, allowing engineers to systematically identify hazards, assess potential risks, and develop mitigation strategies in a structured manner. By integrating real-time updates and document control, safety studies remain up-to-date and aligned with process modifications.`}
                      />
                      <DescriptionChip
                        title={`Economic Studies`}
                        description={`Evaluate project feasibility and lifecycle costs using Zephyr’s economic modeling tools. Our platform enables engineers to perform cost-benefit analyses, considering factors such as capital expenditure (CAPEX), operating costs (OPEX), and return on investment (ROI). Engineers can dynamically adjust process conditions, equipment selections, and procurement decisions while immediately seeing the financial impact on the overall project.`}
                      />
                    </div>
                  </div>




                </div>









              </div>
              <div className='scaling-box-grid-wrapper'>
                <ScalingBoxGridComponent />
              </div>
            </div>
          </div>

        </section>
        <GenericFooter />



      </main>
    </div>
  );
}

export default LandingPageComponent;

//
//

//<section className="intro" style={{ overflowY: 'none' }}>
//          <div className="intro-content" style={{ overflowY: 'none' }}>
//            <div className="call-to-action" style={{ textAlign: 'left', marginRight: 'auto', fontFamily: 'sans-serif', color: 'white', marginTop: '220px', display: 'flex', fontWeight: 'bold', minHeight: '120px' }}>
//              Engineering infrastructure to streamline your projects
//            </div>
//            <div style={{ textAlign: 'left', marginRight: 'auto', fontFamily: 'sans-serif', color: 'black', flexDirection: 'row', display: 'flex' }}>
//              <div
//                className="button-container"
//                onClick={() => navigate('/login')}
//                style={{ display: 'flex', padding: '10px 30px', background: 'white', border: 'white 1px solid', borderRadius: '20px', cursor: 'pointer', marginRight: '10px' }}
//              >
//                <button
//                  style={{ fontFamily: 'inherit', padding: 0, border: 'none', background: 'transparent', cursor: "pointer", height: '100%', fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: 'medium' }}
//                >
//                  Start browsing
//                </button>
//              </div>
//              <div className="icon-container" style={{ display: 'flex', border: 'white 1px solid', borderRadius: '20px', width: '39px', height: '39px', alignItems: 'center', justifyContent: "center", background: "white", transition: 'margin-left 0.3s ease' }}>
//                <FontAwesomeIcon icon={faChevronRight} />
//              </div>
//            </div>//

//            <div style={{ marginTop: 'auto', marginBottom: '0%', marginRight: 'auto' }}>
//              <div style={{ display: 'flex', flexDirection: 'row', marginRight: 'auto' }}>
//                <img src={ZephyrInnovaitionsLogo2} alt="Zephyr Innovations Logo" style={{ marginRight: 'auto', maxHeight: '200px', maxWidth: '600px' }} />
//              </div>
//            </div>
//          </div>
//        </section>