import React, {useState, useEffect} from 'react'
import './css/deleteparts.css'
import './css/UploadComponent.css'
//import NoContent from './NoContent.tsx'
import DragAndDropZone from './DragAndDropZone.jsx'
//import DragAndDropZone2 from './DragAndDropZone2.jsx'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


function UploadImage({partNumbers, setSelection2}) {


    const [droppedImage, setDroppedImage] = useState(null)
    const [previewUrl, setPreviewUrl] = useState(null)
    const [loading, setLoading] = useState(false)

    const modalStyle = {
        zIndex: 998,
        position: 'absolute',
        backgroundColor: 'var(--modal-background-color)',
        height: '100%',
        width: '100%',
        top : 0,
        left: 0,
    }
    const modalContent = {
        zindex: 999,
        position: 'absolute',
        border: '1px solid white',
        backgroundColor: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        width: '400px',
        borderRadius: '8px',
        padding: '16px'
    }
    const modalWrapper = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
    const modalButtons = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        gap: '10px',
        padding: '8px 0px',
        marginTop: '24px'
    }

    function handleDeleteModal() {
        setSelection2('')
    }
    function handleModalContentClick(event) {
        // Prevent click event from propagating to the parent
        event.stopPropagation();
    }

    function handleDelete() {
        // console.log('delete');
    }

    const handleFileDrop = (files) => {
        
        const file = files[0]; // Assuming single file upload
        if (file && file.size <= MAX_FILE_SIZE && file.type.startsWith('image/')) {
            setPreviewUrl(null)
            setLoading(true)
            setDroppedImage(file);
            const objectUrl = URL.createObjectURL(file);
            setTimeout(() => {
                setLoading(false)
                setPreviewUrl(objectUrl);
            }, 1000)
        } else {
            alert('Please upload a valid image file less than 3MB.');
        }
    };

    useEffect(() => {
        // console.log(loading);
    },[loading])

    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    return (
        <>
            <div style={modalStyle} onClick={handleDeleteModal}>
              <div style={modalContent} onClick={handleModalContentClick}>
                  <div style={modalWrapper}>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <div className='modal-header'>Attach image to part{partNumbers > 1 ? 's' : ''}</div>
                <div className='modal-text-content' style={{fontSize: '14px', fontStyle: 'Arial'}}>Upload your part image here. It will be attached to all the parts selected.</div>
                <div style={{borderBottom: '1px #d8dee4 solid', fontSize: '14px', fontStyle: 'Arial'}}>Upload Part Image</div>
                
                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                
                    <div style={{height: '150px', minWidth: '150px', border: 'solid 1px #ccc', borderRadius:'8px', boxSizing: 'border-box', display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '8px',
                            }}>
                        {loading && (
                            <Skeleton count={1} height={'150px'} width={'150px'}/>
                        )}
                        {previewUrl && (
                            <img
                                src={previewUrl}
                                alt="Preview"
                                style={{
                                    
                                    borderRadius: '8px',
                                    maxHeight: '150px',
                                    maxWidth: '150px',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        )}
                    </div>
                
                
                
                    <DragAndDropZone
                        onDrop={handleFileDrop}
                        hoveringText="Drop your part image here!"
                        defaultText="Drag & Drop a part image here or click to upload"
                        maxFileSize={MAX_FILE_SIZE}
                        height={'150px'}
                    />        

                </div>
                
            </div>
            <div style={modalButtons}>  
                          <span className='cancel-button' onClick={handleDeleteModal}>Cancel</span>
                          <span className='delete-button' onClick={handleDelete}>Upload</span>
                      </div>
            </div>
            </div>
            </div>
        </>
    )
}


export default UploadImage