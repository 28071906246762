import React from "react";

const TaskBatteryChart = ({ tasks, height, width }) => {
  // Ensure each task has a color; fallback to a default if not provided
  const getColor = (task) => task.color || "#ccc";

  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true });
  };


  const getCurrentTimePosition = () => {
    const now = new Date();
    //const start = new Date(startDate);
    //const end = new Date(endDate);

    //if (now < start) return 0;
    //if (now > end) return 100;

    //const totalDuration = end - start;
    //const elapsedDuration = now - start;

    //return (elapsedDuration / totalDuration) * 100; // Position as a percentage
    return (1/5) * 100
};


  return (

    <>
     <span className='zp-font-small' style={{color: '#aaa'}}>{getCurrentTime()}</span>




    <div style={{ display: "flex", alignItems: "center", position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
      {/* Battery Body */}
      <div
        style={{
          width: width ? width : "200px",
          height: height ? height : "50px",
          
          display: "flex",
          backgroundColor: "#f3f3f3",
          overflow: "hidden", // Prevent overflow of segments
          posiiton: "relative",
        }}
      >
        {/* Task Segments */}
        {tasks.map((task, index) => (
          <div
            key={index}
            style={{
              width: `${task.percentage}%`,
              backgroundColor: getColor(task),
              height: "100%",
              transition: "width 0.5s ease",
              position: 'relative',
              
            }}
            title={task.name} // Tooltip to display the task name
          />
        ))}
        
      </div>
      
      {/* Battery Terminal */}
      
    </div>
    <div className='justify-between'>
        <span className='zp-font-small' style={{color: '#aaa'}}>Nov 5 2024</span>
        <span className='zp-font-small' style={{color: '#aaa'}}>Nov 13 2024</span>
    </div>
    </>
  );
};

export default TaskBatteryChart;

//
//
//<div
//            style={{
//              position: "absolute",
//              left: `${getCurrentTimePosition()}%`,
//              top: 0,
//              bottom: 0,
//              width: "2px",
//              backgroundColor: "#aaa", // Marker color
//              pointerEvents: "none", // Prevent interference
//            }}
//          />