import React, { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import { useCookies } from 'react-cookie';
const apiUrl = process.env.REACT_APP_API_URL;

const ChipContext = createContext();

export const useChipContext = () => useContext(ChipContext)

export const ChipProvider = ({ children }) => {
    const [ cookies, setCookies] = useCookies(['cart_cookie', 'csrf_token', 'user']);
    const [ partType, setPartType] = useState('pressuretransducers')
    const [ chipFilters, setChipFilters ] = useState({})
    const [chipFiltersLength, setChipFiltersLength] = useState(0)
    const [hiddenChips, setHiddenChips] = useState([]);
    const [showHiddenChip, setShowHiddenChip] = useState(false);

    useEffect(() => {
        if (hiddenChips.length > 0) {
          // Set a timeout of 300ms to show the hidden chip
          setShowHiddenChip(true);
        } else {
          // Immediately hide the hidden chip if there are no hidden chips
          setShowHiddenChip(false);
        }
      }, [hiddenChips]);

    const fetchChipFilters = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/fetch-chip-filters`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': cookies.csrf_token,
                },
                credentials: 'include',
                body: JSON.stringify({
                    part_type: partType
                })
            })
            const data = await response.json()
            // console.log("CHIP FILTERS", data);
            // console.log("chip filters length?",Object.entries(data).length - 1);
            setChipFilters(data)
            setChipFiltersLength(Object.entries(data).length - 1)
        } catch (error) {
            console.error('Failed the fetch:', error)
        }
    }


    return (
        <ChipContext.Provider value={{fetchChipFilters, chipFilters, setChipFilters, hiddenChips, setHiddenChips, showHiddenChip, chipFiltersLength}}>
            {children}
        </ChipContext.Provider>
    );
}

export const useChip = () => useContext(ChipContext);