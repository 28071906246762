import React, { useState, useEffect, useRef, useMemo, } from 'react'
import { ModalWrapper1 } from 'index.js'
import './css/deleteparts.css'

export function ModalContent({ partNumbers, handleDeleteModal, handleDelete }) {

    const modalWrapper = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
    const modalButtons = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        gap: '10px',
        padding: '8px 16px',
        marginTop: '24px'
    }

    return (
        <>
            <div style={modalWrapper}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div className='modal-header'>Delete {partNumbers > 1 ? 'these' : 'this'} part{partNumbers > 1 ? 's' : ''}?</div>
                    <div className='modal-text-content'>This will also delete attached documents, images and part details. This can't be undone.</div>
                </div>
                <div style={modalButtons}>
                    <span className='cancel-button' onClick={handleDeleteModal}>Cancel</span>
                    <span className='delete-button' onClick={handleDelete}>Delete</span>
                </div>
            </div>
        </>
    )
}

function DeleteParts({ partNumbers, setSelection }) {
    //const [showModal, setShowModal] = useState(false);
    //const [partNumber,] = useState(10);

    function handleDeleteModal() {
        setSelection('')
    }
    function handleDelete() {
        // console.log('delete');
    }

    return (
        <>
            <ModalWrapper1
                children={
                    <ModalContent partNumbers={partNumbers} handleDelete={handleDelete} handleDeleteModal={handleDeleteModal} />
                }
                handleCloseModal={handleDeleteModal}>
            </ModalWrapper1>
        </>
    )
}

export default DeleteParts
