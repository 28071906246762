import React, { useState, useEffect, useRef, useContext } from 'react';
import { useCookies } from 'react-cookie';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faXmark } from '@fortawesome/free-solid-svg-icons';
import NumberInput from '../GenericComponents/NumberInputComponent.jsx';
import { AlertContext } from 'index.js';


import styles from './css/ProductCartButton.module.css'

const ProductCartButton = ({ selectedRowIndex, equipmentData, selectedOption, partType }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const cartButtonRef = useRef(null);
  const modalRef = useRef(null);
  const [isTransitioning, setTransitioning] = useState(false);
  const [isModalShifted, setModalShifted] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(1);
  const { showAlertMessage } = useContext(AlertContext);
  const item = equipmentData[selectedRowIndex];


  useEffect(() => {
    // console.log("item");
    // console.log(item);
  },[item])

  useEffect(() => {
    setCartQuantity(1);
  }, [selectedRowIndex]);

  const handleCloseModal = () => setModalOpen(false);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isModalOpen]);

  const handleCartButtonClick = () => {
    if (isModalOpen) {
      handleCloseModal();
    } else {
      if (cartButtonRef.current) {
        const cartButtonRect = cartButtonRef.current.getBoundingClientRect();
        const newLeft = cartButtonRect.left;
        const windowWidth = window.innerWidth - 20;
        const totalModalLeft = newLeft + 300;
        setModalShifted(windowWidth < 660);
        setModalPosition({ top: cartButtonRect.bottom + 8, left: newLeft });
        setModalOpen(true);
        setTransitioning(true);
      }
    }
  };
  //|| windowWidth < totalModalLeft
  useEffect(() => {
    const handleResize = () => {
      if (modalRef.current && cartButtonRef.current && isModalOpen) {
        const cartButtonRect = cartButtonRef.current.getBoundingClientRect();
        const newLeft = cartButtonRect.left;
        const windowWidth = window.innerWidth - 20;
        const totalModalLeft = newLeft + 300;
        setModalShifted(windowWidth < 660);
        setModalPosition((prevPosition) => ({ ...prevPosition, left: newLeft }));
      }
    };

    //|| windowWidth < totalModalLeft

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isModalOpen]);

  const handleCartQuantityChange = (e) => setCartQuantity(e.target.value);

  const handleIncrementQuantity = () => setCartQuantity((prev) => prev + 1);

  const handleDecrementQuantity = () => setCartQuantity((prev) => Math.max(prev - 1, 1));

  const [cookies, setCookie] = useCookies(['cart_cookie']);

  const handleAddToCartButtonClick = () => {
    if (Object.keys(selectedOption).length !== Object.keys(equipmentData[selectedRowIndex].configurableoptions).length) {
      setTimeout(() => showAlertMessage('warning', 'This item requires configuration!'), 10);
      return;
    }

    const cartItem = {
      id: item.supplierpartnumber,
      company_name: item.supplier,
      part_type: partType,
      quantity: cartQuantity,
      imagename: item.imagename || null,
      configurations: item.configurableoptions ? [{ configuration: selectedOption, quantity: cartQuantity }] : [],
    };

    let cartItems = [];
    if (cookies.cart_cookie) {
      try {
        cartItems = typeof cookies.cart_cookie === 'object' ? cookies.cart_cookie : JSON.parse(cookies.cart_cookie);
      } catch (e) {
        console.error('Error parsing cart data:', e);
        cartItems = [];
      }
    }

    const existingCartItemIndex = cartItems.findIndex(
      (item) => item.id === cartItem.id && item.company_name === cartItem.company_name && item.part_type === cartItem.part_type
    );

    if (existingCartItemIndex >= 0) {
      const existingCartItem = cartItems[existingCartItemIndex];
      existingCartItem.quantity += cartQuantity;
      const existingConfigIndex = existingCartItem.configurations.findIndex(
        (config) => JSON.stringify(config.configuration) === JSON.stringify(selectedOption)
      );
      if (existingConfigIndex >= 0) {
        existingCartItem.configurations[existingConfigIndex].quantity += cartQuantity;
      } else {
        existingCartItem.configurations.push({ configuration: selectedOption, quantity: cartQuantity });
      }
    } else {
      cartItems.push(cartItem);
    }

    setCookie('cart_cookie', JSON.stringify(cartItems), { path: '/', expires: new Date(Date.now() + 259200000), secure: true, httpOnly: false, sameSite: 'lax' });
    setModalOpen(false);
    setTimeout(() => showAlertMessage('success', 'Item/s added to cart!'), 10);
  };

  return (
    <>
      <div className="product-cart-button" onClick={handleCartButtonClick} ref={cartButtonRef}>
        <FontAwesomeIcon icon={faCartShopping} color="#339af0" />
      </div>
      {isModalOpen && (

        <div className={isModalShifted ? 'modal-overlay-2' : `${styles['modal-overlay']}`}>
          <div className={`${isModalShifted ? 'modal-content-2' : 'modal-content'} ${isTransitioning ? 'show' : ''}`} style={{ top: modalPosition.top, left: modalPosition.left }} ref={modalRef}>
            <div className={`${isModalShifted ? 'modal-content-2-header-2' : 'custom-header-3'}`}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span>Add to Cart</span>

                <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ display: 'flex', alignItems: 'center', padding: 0, marginLeft: 'auto' }}>
                  <FontAwesomeIcon
                    className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark}
                    onClick={handleCloseModal}
                    style={{ display: 'flex', alignItems: 'center' }} />
                </button>
              </div>
            </div>
            <div >
              <div
                style={{
                  display: 'Flex',
                  borderBottom: '1px solid #d8dee4',
                  padding: '8px 0px',
                  paddingRight: isModalShifted ? '16px' : '0px',
                  paddingLeft: isModalShifted ? '16px' : '0px'
                }}>
                <span className="equipment-table-span-1">
                  <span className="equipment-table-span-2 monostyle-text">{item.supplierpartnumber}</span>
                  {((Array.isArray(item.configurableoptions) && item.configurableoptions.length > 0) &&
                    (Object.values(selectedOption).length < Object.keys(item.configurableoptions).length)) ? (
                    <span className='zp-font-s' style={{ color: 'red' }}>
                      This item requires configuration.
                    </span>
                  ) : (
                    <span className="equipment-table-span-3">
                      {Object.values(selectedOption).join('-')}
                    </span>
                  )}

                </span>
                <span className="equipment-table-span-4" style={{ fontSize: '14px', display: 'flex', flexDirection: 'row', gap: '10px' }}>
                  <NumberInput
                    Quantity={cartQuantity}
                    handleQuantityChange={handleCartQuantityChange}
                    incrementQuantity={handleIncrementQuantity}
                    decrementQuantity={handleDecrementQuantity}
                    width='20px'
                    height='20px'
                  />
                  <span className="general-center-style">
                    ${item.price}
                  </span>
                </span>
              </div>
              <div style={{ font: 'arial', fontSize: '12px', marginTop: '5px', marginBottom: '5px' }}>
                <span
                  className="general-right-style"
                  style={{
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingRight: isModalShifted ? '16px' : '0px',
                    paddingLeft: isModalShifted ? '16px' : '0px'
                  }}>
                  <span
                    className="add-to-cart-button"
                    onClick={handleAddToCartButtonClick}
                  >
                    Add {cartQuantity} to cart
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>



      )}
    </>
  );
};

export default ProductCartButton;







//
//
//

//<div className={isModalShifted ? 'modal-overlay-2' : `${styles['modal-overlay']}`}>
//<div className={`${isModalShifted ? 'modal-content-2' : 'modal-content'} ${isTransitioning ? 'show' : ''}`} style={{ top: modalPosition.top, left: modalPosition.left }} ref={modalRef}>
//  <div className={isModalShifted ? 'modal-content-2-header-2 justify-between' : 'custom-header-3 justify-between'}>
//    <span className='align-center'>Add to Cart</span>
//    <span className="navbar-close" onClick={handleCloseModal}>
//      <FontAwesomeIcon className="h-5 w-5 text-gray-400 cursor-pointer hover:text-gray-500" style={{ outline: 'none' }} icon={faXmark} />
//    </span>
//  </div>
//  <div>
//    <NumberInput Quantity={cartQuantity} handleQuantityChange={handleCartQuantityChange} incrementQuantity={handleIncrementQuantity} decrementQuantity={handleDecrementQuantity} width='20px' height='20px' />
//  </div>
//  <div className='justify-end'>//

//    <button className="add-to-cart-button " onClick={handleAddToCartButtonClick}>Add {cartQuantity} to cart</button>
//  </div>
//</div>
//</div>