import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';





//import { useAuth } from '../../AuthContext.js'
import './css/HeaderComponent.css';


import ZephyrInnovaitionsLogo2 from './../../../assets/Zephyr Innovations Logo 2 (4).svg';
import { faBars, faXmark, faGear } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ToggleSwitch, useAuth } from 'index'

import { useToggle } from '../../../contexts/ToggleContext.js';
import NavigationPanelContent from '../../body/supplierBody/supplierEquipment/NavigationPanelContent.js';



//<FontAwesomeIcon className='fa-icon' icon={faCheckToSlot} />

function HeaderComponent() {
  const { user, setUser } = useAuth();
  const { isToggled } = useToggle();
  const navigate = useNavigate()
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [globalNavType, setGlobalNavType] = useState(null)
  useEffect(() => {
    if (user && user.userType === 'supplier') {
      setGlobalNavType('/supplier')
    } else if (user && user.userType === 'customer') {
      setGlobalNavType('/customer')
    }
  }, [])



  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  function handleMobileNavClick(navType) {
    let targetPath = '';

    if (user && user.userType === 'supplier') {
      targetPath = `/supplier${navType}`;
    } else if (user && user.userType === 'customer') {
      targetPath = `/customer${navType}`;
    }

    if (location.pathname !== targetPath) {
      navigate(targetPath);
      toggleMenu();
    } else {
      toggleMenu();
    }
  }

  function handleLogout() {
    setUser(null)
  }

  function handleLogin() {
    navigate('/login')
  }

  return (

    <>
      <div className="tailwind">
        <div className="bg-blue-500">

          <nav className="relative flex items-center bg-white header-nav-bar" style={{ maxHeight: '62px', paddingTop: '17.8px', paddingBottom: '17.8px', paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
            <div className="toggle-company-element">
              <button className="navbar-burger flex items-center text-blue-600 p-1 bg-transparent border-none cursor-pointer" onClick={toggleMenu}>
                <FontAwesomeIcon className="block h-4 w-4 fill-current" icon={faBars} />
              </button>
              <span className='company-element'>
                {user.company}
              </span>
            </div>



            <div className=' lg:flex'>
              {user && user.userType === 'supplier' ?
                <>
                  <div>
                    <ToggleSwitch />
                  </div>


                </> : <>
                
                <div style={{minHeight: '62px'}}>
                  
                  </div>
                
                
                </>
              }
            </div>
          </nav>
          <div className={`navbar-menu relative z-50 ${isMenuOpen ? '' : 'hidden'}`} >
            <div className=" navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" onClick={toggleMenu}></div>

            <nav className=" fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm px-6 bg-white border-r " style={{ marginTop: isToggled ? '44px' : '0px', paddingTop: '17.8px', paddingBottom: '17.8px' }}>
              <div className="flex items-center mb-4" style={{ display: 'flex', flexDirection: 'row' }}>
                <button className="navbar-burger flex items-center text-blue-600 p-1 bg-transparent border-none cursor-pointer" onClick={toggleMenu}>
                  <FontAwesomeIcon className="block h-4 w-4 fill-current" icon={faBars} />
                </button>
                <span className='company-element'>
                  {user.company}
                </span>
                <button className="navbar-close bg-transparent border-none cursor-pointer" style={{ marginLeft: 'auto' }} onClick={toggleMenu}>
                  <FontAwesomeIcon className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" icon={faXmark} />
                </button>
              </div>
              <div className="overflow-y-auto" style={{ scrollbarWidth: 'thin' }}>

                <NavigationPanelContent
                  globalNavType={globalNavType}
                  handleMobileNavClick={handleMobileNavClick}
                />

              </div>
              <div className="mt-auto" style={{ borderTop: '1px solid #d8dee4' }}>
                <div className={`pt-6 ${!user ? '' : 'hidden'}`}>
                  <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-600 hover:bg-blue-700 rounded-xl cursor-pointer" onClick={handleLogin} >Log In</a>
                </div>
                <div className={`pt-6 ${user ? '' : 'hidden'}`}>
                  <a className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold bg-gray-50 hover:bg-gray-100 rounded-xl cursor-pointer" onClick={handleLogout}>Log Out</a>
                </div>

                <p className="my-4 text-xs text-center text-gray-400">
                  <span>Copyright © 2024</span>
                </p>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>

  );
}

export default HeaderComponent;


