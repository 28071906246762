import React, { useState, useRef, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './css/SupplierEquipmentBody.css';
import FilterBar from './FilterBar.js';
import { useChipFilterContext } from './ChipComponents/context/ChipFilterContext.tsx';
//import TabNavigation from '../../../../generalComponents/TabNavigation';
import { Skeleton2, Skeleton3, TabNavigation } from 'index.js'
import NoContent from './GenericComponents/NoContent.tsx';

import { Skeleton1 } from 'index.js';

import OperatingConditions from './EquipmentComponents/TableDisplay/operatingConditions.js'
import ProcessConnections from './EquipmentComponents/TableDisplay/processConnections.js';
import MeasuringData from './EquipmentComponents/TableDisplay/measuringData.js'
import MaterialsData from './EquipmentComponents/TableDisplay/materialsData.js'
import ElectricalData from './EquipmentComponents/TableDisplay/electricalData.js';

import NumberInputComponent from './GenericComponents/NumberInputComponent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faSpinner, faPlus, faMinus, faArrowDown, faCartShopping, faArrowRotateRight, faLinkSlash, faRotateRight, faArrowRightToBracket, faUpload, faBoxesPacking } from '@fortawesome/free-solid-svg-icons';
import { faCopy as faCopyRegular, faTrashCan as faTrashCanRegular, faPenToSquare as faPenToSquareRegular } from '@fortawesome/free-regular-svg-icons';
import ProductCartButton from './EquipmentComponents/ProductCartButton';
import CloseButton from './GenericComponents/CloseButton.tsx';
import DocumentComponent from './EquipmentComponents/DocumentComponent.jsx';
import ConfigurationSelections from './ConfigurationSelections.jsx';
import ConfigurationOptions from './ConfigurationOptions.jsx';


import GenericCheckbox from './GenericComponents/GenericCheckbox.tsx'

import { useAuth } from '../../../../contexts/AuthContext.js'
import List2 from './GenericComponents/List2.js';
import DeleteParts from './GenericComponents/deleteparts.js';
import DuplicateParts from './GenericComponents/duplicateParts.js'
import UploadDocuments from './GenericComponents/UploadDocuments.jsx';
import UploadImage from './GenericComponents/UploadImage.jsx';
import ModifyDescription from './GenericComponents/ModifyDescription.jsx';
import ModifyConfig from './GenericComponents/ModifyConfig.jsx';
import ModifyTechnicalDetails from './GenericComponents/ModifyTechnicalDetails.jsx';
import ModifyBasicDetails from './GenericComponents/ModifyBasicDetails.jsx'
import UploadCSV from './GenericComponents/UploadCSV.jsx';

import { useToggle } from '../../../../contexts/ToggleContext';

const apiUrl = process.env.REACT_APP_API_URL;

function SupplierEntrypoint() {
  const [cookies] = useCookies(['cart_cookie', 'csrf_token']);
  const { filterStates, prevFilterStates, setPrevFilterStates, partType, setPrevPartType, prevPartType } = useChipFilterContext();
  const [equipmentData, setEquipmentData] = useState([])
  const [dbOffset, setDbOffset] = useState(0)
  const [dbEnd, setDbEnd] = useState(false)
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [detailsData, setDetailsData] = useState({})
  //const [partType, setPartType] = useState('pressuretransducers')
  const [images, setImages] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [isPanelActive, setPanelActive] = useState(false)
  const [resetTriggered, setResetTriggered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth()

  const { isToggled } = useToggle();


  useEffect(() => {
    setCurrentTabIndex(0);
  }, [selectedRowIndex])

  const resetData = async () => {
    setSelectedRowIndex(null)
    setPanelActive(false);
    setDbOffset(0);
    setEquipmentData([]);
    setPrevPartType(partType);
    setPrevFilterStates(filterStates);
    setResetTriggered(true);
  };

  useEffect(() => {
    if (resetTriggered) {
      fetchData();
      setResetTriggered(false); // Reset the trigger after fetching
    }
  }, [resetTriggered]); // Run effect after states are updated

  const [headers, setHeaders] = useState({})
  const [filterRows, setFilterRows] = useState({})

  const fetchPartTypeHeaders = async () => {
    // console.log(partType);
    try {
      const endpoint = `${apiUrl}/api/part-type-filter-data`
      const payload = {
        part_type: partType,
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token,
        },
        body: JSON.stringify(payload),
        credentials: 'include',
      };
      const response = await fetch(endpoint, requestOptions);
      const data = await response.json()
      // console.log("HEADERS", data);
      // console.log(data.headers, data.rows[0]);
      setHeaders(data.headers)
      setFilterRows(data.rows[0])




    } catch (error) {
      console.error('Error has occured', error)
    }
  }

  useEffect(() => {
    if (equipmentData && partType) {
      fetchPartTypeHeaders()
    }

  }, [equipmentData, partType])


  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  //await delay(5000)

  const isFiltering = Object.values(filterStates).some(value => value !== "");
  const payload = {
    part_type: partType,
    offset: dbOffset,
    filtering: isFiltering,
    ...Object.fromEntries(
      Object.entries(filterStates).filter(([key, value]) => value)
    ),
  };



  const fetchData = async () => {
    // console.log(dbOffset, equipmentData, filterStates);
    const isFiltering = Object.values(filterStates).some(value => value !== "");
    // console.log(isFiltering);
    try {
      
        setIsLoading(true)

      
      //await delay(1000)
      const endpoint = `${apiUrl}/api/get-equipment`
      const payload = {
        part_type: partType,
        offset: dbOffset,
        filtering: isFiltering
      };
      Object.entries(filterStates).forEach(([key, value]) => {
        if (value) payload[key] = value;
      });
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token,
        },
        body: JSON.stringify(payload),
        credentials: 'include',
      };
      const response = await fetch(endpoint, requestOptions);
      const newData = await response.json();
      // console.log(newData);
      setEquipmentData(prevData => [...prevData, ...newData.table_data]);
      setDbOffset(newData.currentDatabaseOffset)
      setDbEnd(newData.dbEnd)
    } catch (error) {
      console.error('Error has occured', error)
    } finally {
      setIsLoading(false)
    }
  }

  const [fetchingDetails, setFetchingDetails] = useState(false)
  const fetchRowDetails = async (index, supplierPartNumber, supplier) => {
    const url = `${apiUrl}/api/details`;  // Assuming details endpoint
    const payload = { partNumber: supplierPartNumber, supplier: supplier };

    try {
      setFetchingDetails(true)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token,
        },
        body: JSON.stringify(payload),
        credentials: 'include'
      });
      const data = await response.json();
      setDetailsData(prev => ({
        ...prev,
        [index]: {
          ...data,
          supplierpartnumber: supplierPartNumber
        }
      }));
    } catch (error) {
      console.error("Failed to fetch details", error);
    } finally {
      setFetchingDetails(false)
    }
  };

  const addImages = (newImages) => {
    setImages((prevImages) => ({
      ...prevImages,
      ...newImages,
    }));
  };

  const [fetchingImage, setFetchingImage] = useState(false)
  const fetchImageForPart = async (imageName) => {
    // console.log(imageName);


    try {
      setFetchingImage(true)
      if (!imageName || images[imageName]) return;
      const response = await fetch(`${apiUrl}/api/get-images`, {  // Assuming a different endpoint for a single image
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': cookies.csrf_token,
        },
        body: JSON.stringify({ image_names: [imageName] }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // console.log(data);
      addImages({ [imageName]: data[imageName] }); // Update state with the specific image
    } catch (error) {
      console.error('Error fetching image:', error);
    } finally {
      setFetchingImage(false)
    }
  };

  const selectEquipmentRow = async (event, index) => {
    setSelectedRowIndex(index)
    setActiveTab(0);
    if (user.userType === 'supplier') {
      handleCheckboxChange(event, index)
    }
    const windowWidth = window.innerWidth
    if (windowWidth <= 490) {
      // console.log("shift gear");
      setPanelActive(true);
    }
    const selectedPart = equipmentData[index]
    await fetchRowDetails(index, equipmentData[index].supplierpartnumber, equipmentData[index].supplier);
    if (selectedPart.imagename) {
      await fetchImageForPart(selectedPart.imagename); // Fetch only the specific image for the selected part
    }
  }

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 490) {
        setPanelActive(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const selectedStyles = {
    color: '#339AF0',
    fontWeight: 'bold',
    display: 'inline-block'
  }

  const handleTabClick = (index) => {
    // console.log(`Tab clicked: ${index}`);
    setCurrentTabIndex(index)
    // Perform additional actions based on the tab index
  };


  const didFetchData = useRef(false);

  useEffect(() => {
    // Ensure fetchData runs only once on component load
    if (!didFetchData.current) {
      fetchData();
      didFetchData.current = true; // Set the ref to true to prevent further calls
    }
  }, []);

  const handleLoadMore = () => {
    // console.log("clicked the load more button!!!");
    fetchData()
  }

  const handleCloseButtonClick = () => {
    setPanelActive(false); // Set the panel state to inactive
  };


  // config section //


  const [selectedOption, setSelectedOption] = useState({});
  const [selectedButton, setSelectedButton] = useState(0);
  const [, setSelectedOptions] = useState({});
  const [, setDisabledOptions] = useState({});
  const [nestedHashmap, setNestedHashmap] = useState({});
  const [activeConfigIndex, setActiveConfigIndex] = useState(0);

  useEffect(() => {
    setSelectedOption({})
    setSelectedButton(0)
    setActiveConfigIndex(0) //maybe?

    const buildHashmap = (data) => {
      const map = {};
      data.forEach(item => {
        const key = item.designation;
        map[key] = {};
        item.option.forEach(option => {
          map[key][option.designation] = option;
        });
      });
      return map;
    };
    // console.log(equipmentData[selectedRowIndex]);
    if ((equipmentData[selectedRowIndex] !== null) && (equipmentData[selectedRowIndex] !== undefined)) {
      const hashmap = buildHashmap(equipmentData[selectedRowIndex].configurableoptions);
      setNestedHashmap(hashmap);
    }

  }, [equipmentData[selectedRowIndex]]);

  const isOptionDisabled = (designation, optionDesignation) => {
    const disabledBy = nestedHashmap[designation]?.[optionDesignation]?.["disabledBy"];
    if (!disabledBy) {
      return false
    }
    for (const [key, value] of Object.entries(disabledBy)) {
      if (Array.isArray(value)) {
        if (value.includes(selectedOption[key])) {
          return true;
        }
      } else {
        if (selectedOption[key] === value) {
          return true;
        }
      }
    }
    return false;
  };

  const handleOptionChange = (designation, optionDesignation) => {
    const updatedOptions = { ...selectedOption, [designation]: optionDesignation };

    // Sort the keys of updatedOptions
    const sortedKeys = Object.keys(updatedOptions).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

    // Reconstruct the newSelectedOptions object with sorted keys
    const newSelectedOptions = sortedKeys.reduce((obj, key) => {
      obj[key] = updatedOptions[key];
      return obj;
    }, {});

    checkOptionState(designation, optionDesignation, newSelectedOptions)
    setSelectedOption(newSelectedOptions);
    // Auto-progress to the next set of options



    if (activeConfigIndex < equipmentData[selectedRowIndex].configurableoptions.length - 1) {
      if (isNarrow) {
        setActiveConfigIndex((prevIndex) => prevIndex);
        setSelectedButton((prevIndex) => prevIndex);
        setShowSelections(true)
      } else {
        setActiveConfigIndex((prevIndex) => prevIndex + 1);
        setSelectedButton((prevIndex) => prevIndex + 1);
      }
    } else {
      setSelectedButton(activeConfigIndex);
      if (isNarrow) {
        setShowSelections(true)
      }
    }
  };

  const handleButtonClick = (designation, optionDesignation, index) => {
    setActiveConfigIndex(index);
    setSelectedButton(index);
    if (isNarrow) {
      setShowSelections(false)
    }
  };

  const checkOptionState = (designation, optionDesignation, newSelectedOptions) => {
    for (const [key, value] of Object.entries(newSelectedOptions)) {
      const disabledBy = nestedHashmap[key]?.[value]?.["disabledBy"];
      if (disabledBy !== undefined) {
        const isMatch = (subset, superset) => {
          for (let subKey in subset) {
            const subsetValue = subset[subKey];
            const supersetValue = superset[subKey];
            if (Array.isArray(subsetValue)) {
              if (supersetValue !== undefined && subsetValue.includes(supersetValue)) {
                return true;
              }
            } else {
              if (subsetValue === supersetValue) {
                return true;
              }
            }
          }
          return false;
        };

        if (isMatch(disabledBy, newSelectedOptions)) {
          delete newSelectedOptions[key];
        }
      }
    }
  }

  const containerRef = useRef(null);
  const [isNarrow, setIsNarrow] = useState(false);
  const [showSelections, setShowSelections] = useState(true);

  useEffect(() => {
    // console.log("observing?");
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width < 400) {
          // console.log("we be less than 400");
          setIsNarrow(true);
        } else {
          // console.log("we be plunderin");
          setIsNarrow(false);
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [currentTabIndex]);


  const [checkedRowIndexes, setCheckedRowIndexes] = useState([]);

  const handleCheckboxChange = (e, index) => {
    setCheckedRowIndexes((prev) => {
      if (checkedRowIndexes.includes(index)) {
        // Add index to the array if not already present
        return prev.filter((i) => i !== index);
      } else {
        // Remove index from the array
        return [...prev, index];
      }
    });
  };

  function handleAllCheckboxChange() {
    if (checkedRowIndexes.length === equipmentData.length) {
      // Deselect all if all rows are already selected
      setCheckedRowIndexes([]);
    } else {
      // Select all rows
      setCheckedRowIndexes(equipmentData.map((_, i) => i));
    }
  }

  useEffect(() => {
    // console.log("CHECKED ROW INDEXes");
    // console.log(checkedRowIndexes);
  }, [checkedRowIndexes])


  function handleCloseSelections() {
    setCheckedRowIndexes([])
  }

  const inputRef = useRef(null);
  const handleTdClick = (event) => {
    if (event.currentTarget.contains(inputRef.current)) {
      // Check if the input is already focused
      if ((document.activeElement === inputRef.current) && inputRef.current.focus()) {
        // Defocus the input}
        inputRef.current.focus();

      }
    } else {
      // Focus the input
      inputRef.current.blur();
      //}
    }
  };


  const [AddPartInputValue, setAddPartInputValue] = useState('')



  const [selection, setSelection] = useState('')



  const handleSelectionChange = (newSelection) => {
    setSelection((prev) => (prev === newSelection ? '' : newSelection));
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    let timer;

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        timer = setTimeout(() => {
          setSelection('');
        }, 100); // Defer clearing selection
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  function onClick1() {
    // console.log("onclick 1");
  }

  const [selection2, setSelection2] = useState('')

  function uploadDocuments() {
    setSelection('')
    setSelection2('uploadDocuments')
  }

  function uploadImages() {
    setSelection('')
    setSelection2('uploadImages')
  }

  function modifyDescription() {
    setSelection('')
    setSelection2('modifyDescription')
  }

  function modifyConfig() {
    setSelection('')
    setSelection2('modifyConfig')
  }

  function modifyTechnicalDetails() {
    setSelection('')
    setSelection2('modifyTechnicalDetails')
  }

  function modifyBasicDetails() {
    setSelection('')
    setSelection2('modifyBasicDetails')
  }

  useEffect(() => {
    // console.log(selection);
  }, [selection])

  useEffect(() => {
    // console.log(equipmentData);
  }, [equipmentData])


  return (
    <div className="supplier-body-entry-container">
      <FilterBar
        triggerSearch={resetData}
      />
      {selection === 'multi-upload' && (
        <UploadCSV
          setSelection={setSelection}
          partNumbers={checkedRowIndexes.length}
        />
      )}
      {selection === 'delete' && (
        <DeleteParts
          setSelection={setSelection}
          partNumbers={checkedRowIndexes.length}
        />
      )}
      {selection === 'copy' && (
        <DuplicateParts
          setSelection={setSelection}
          partNumbers={checkedRowIndexes.length}
        />
      )}
      {selection2 === 'uploadDocuments' && (
        <UploadDocuments
          setSelection2={setSelection2}
          partNumbers={checkedRowIndexes.length}
        />
      )}
      {selection2 === 'uploadImages' && (
        <UploadImage
          setSelection2={setSelection2}
          partNumbers={checkedRowIndexes.length}
        />
      )}
      {selection2 === 'modifyDescription' && (
        <ModifyDescription
          setSelection2={setSelection2}
          partNumbers={checkedRowIndexes.length}
        />
      )}
      {selection2 === 'modifyConfig' && (
        <ModifyConfig
          setSelection2={setSelection2}
          partNumbers={checkedRowIndexes.length}
        />
      )}
      {selection2 === 'modifyTechnicalDetails' && (
        <ModifyTechnicalDetails
          setSelection2={setSelection2}
          partNumbers={checkedRowIndexes.length}
          filterRows={filterRows}
          headers={headers}
        />
      )}
      {selection2 === 'modifyBasicDetails' && (
        <ModifyBasicDetails
          setSelection2={setSelection2}
          partNumbers={checkedRowIndexes.length}
          filterRows={filterRows}
          headers={headers}
          setSelection={setSelection}
        />
      )}
      <div className="supplier-equipment-body">
        <div className={`supplier-equipment-left-panel ${isPanelActive ? 'active' : ''}`}>
          <div className="equipment-table-overflow-container" style={{ maxHeight: isToggled ? 'calc(var(--vh) - 126px - 44px)' : 'calc(var(--vh) - 126px)' }}>
            <div className="equipment-table-positioning-container">
              <table>
                <tbody className='equipment-table-body'>

                  {user.userType === 'supplier' && (
                    //this part is the add new part row at the top
                    <tr style={{ borderBottom: '1px solid transparent' }}>
                      <td onClick={handleTdClick} style={{ border: '1px solid #d8dee4', height: '51px', cursor: 'text' }}>
                        <div onClick={() => { handleAllCheckboxChange() }}>
                          <GenericCheckbox
                            checked={(checkedRowIndexes.length === equipmentData.length) && equipmentData.length > 0}
                          />
                        </div>
                        <span className='equipment-table-span-1' style={{ display: 'inline-block', position: 'relative', width: '100%', maxWidth: '174px' }}>
                          <input
                            ref={inputRef}
                            className='equipment-table-span-2 monostyle-text'
                            style={{
                              border: '1px solid #d8dee4',
                              height: '20px',
                              fontSize: '14px',
                              outline: 'none',
                              width: '100%',
                              maxWidth: '174px'
                            }}
                            placeholder='Add a new part...'
                            onChange={(e) => setAddPartInputValue(e.target.value)}
                          />
                          <span
                            style={{
                              width: '250px',
                              fontSize: '12px',
                              fontFamily: 'Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic, Noto Sans JP, sans-serif',
                              color: '#6C7688',
                              position: 'absolute',
                              top: '21px', /* Pushes the span visually below the input */
                              left: '-26px',
                              display: 'inline-block',
                              opacity: AddPartInputValue ? 1 : 0,
                              transition: 'opacity 100ms ease-in-out',
                              /* Optional for styling */
                            }}
                          >
                            <b>Shift</b> + <b>Enter</b> to add another part
                          </span>
                        </span>
                        <span className='equipment-table-span-4'>
                          <div
                            //style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ccc', borderRadius: '4px',padding: '4px', cursor: 'pointer', gap: '5px'}}

                            //style={{display: 'none'}}
                            //style={{marginLeft: '5px'}}
                            className='multi-upload-button'
                            onClick={() => { handleSelectionChange('multi-upload') }}
                          >
                            <FontAwesomeIcon icon={faUpload} />
                            <span className='upload-text'>Upload</span>
                          </div>
                        </span>
                      </td>
                    </tr>
                  )}
                  {equipmentData.length === 0 && isLoading && dbOffset === 0 && (
                    <>

                      {Array.from({ length: 30 }, (_, index) => (
                        <Skeleton1 key={index} />
                      ))}

                    </>
                  )}
                  {equipmentData.length > 0 &&
                    (
                      <>

                        {equipmentData.map((item, index) => (
                          //this is the equipment data section
                          <tr key={index} style={{ borderBottom: selectedRowIndex === index ? '1px solid transparent' : '1px solid #d8dee4' }}>
                            <td onClick={(e) => selectEquipmentRow(e, index)} style={{ border: selectedRowIndex === index ? '1px solid #339AF0' : '' }} className={(selectedRowIndex === index && user.userType !== 'supplier') ? 'no-hover-style' : ''}>
                              {user.userType === 'supplier' && (
                                <GenericCheckbox checked={checkedRowIndexes.includes(index)} onChange={(e) => { handleCheckboxChange(e, index) }} />
                              )}
                              <span className='equipment-table-span-1'>
                                <span className='equipment-table-span-2 monostyle-text' style={selectedRowIndex === index ? selectedStyles : {}}>
                                  {item.supplierpartnumber}
                                </span>
                                <span className='equipment-table-span-3'>{item.supplier}{" "}{item.partname}</span>
                              </span>
                              <span className='equipment-table-span-4'>${item.price}</span>
                            </td>
                          </tr>
                        ))}
                      </>
                    )
                  }
                </tbody>
              </table>
              {equipmentData.length > 0 && !dbEnd && (
                JSON.stringify(prevFilterStates) === JSON.stringify(filterStates) &&
                  JSON.stringify(prevPartType) === JSON.stringify(partType)
                  ? (
                    <div className='load-more-button' onClick={() => handleLoadMore()}>

                      {(dbOffset > 0 && isLoading) ? (
                        <span className='rotate'><FontAwesomeIcon icon={faSpinner} colo='#339AF0'/></span>
                      ) : (
                        <span><FontAwesomeIcon icon={faArrowDown} color='#339AF0' /></span>
                      )}
                      <span>Show more</span>
                    </div>
                  ) : (
                    <div className='load-more-button' onClick={() => resetData()}>
                      <span><FontAwesomeIcon icon={faArrowRotateRight} color='#339AF0' /></span>
                      <span>Reload</span>
                    </div>
                  )
              )}
              {equipmentData.length === 0 && dbEnd && !isLoading && (
                <div className='load-more-button' style={{ pointerEvents: 'none' }}>
                  <span><FontAwesomeIcon icon={faLinkSlash} color='#339AF0' /></span>
                  <span>No parts found</span>
                </div>
              )}

            </div>
          </div>
        </div>
        <div className={`supplier-equipment-right-panel ${isPanelActive ? 'active' : ''}`}>
          {selectedRowIndex !== null ? (
            <>
              <div className="supplier-equipment-right-panel-overflow-container" style={{ maxHeight: isToggled ? 'calc(var(--vh) - 126px - 44px)' : 'calc(var(--vh) - 126px)' }}>
                <div className="right-panel-positioning-container">
                  <div className='product-cart-container'>
                    <ProductCartButton
                      selectedRowIndex={selectedRowIndex}
                      equipmentData={equipmentData}
                      selectedOption={selectedOption}
                      partType={partType}
                    />
                    <div className='product-description-container'>
                      <span style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className='right-panel-span-1'>Part details: {equipmentData[selectedRowIndex].supplierpartnumber}</span>
                        <span className='custom-header-2'>Product Description</span>
                      </span>
                      {isPanelActive && (
                        <div style={{ marginLeft: 'auto', paddingRight: '16px' }}>
                          <CloseButton
                            onClick={handleCloseButtonClick}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='part-description-container' style={{ position: 'relative' }}>
                    <div className='part-image-container'>
                      {fetchingImage ? (
                        // Display loader while fetching
                        <Skeleton2 />
                      ) : (
                        // Once fetching is complete, check for image
                        images[equipmentData[selectedRowIndex].imagename] ? (
                          // Display image if available
                          <img
                            src={`data:image/jpeg;base64,${images[equipmentData[selectedRowIndex].imagename]}`}
                            alt="Product"
                            style={{ maxHeight: '100%', maxWidth: '100%', border: '1px solid #d8dee4' }}
                          />
                        ) : (
                          // Otherwise, display NoContent as the fallback
                          <NoContent width='100px' height='100px' label='No image' />
                        )
                      )}
                    </div>
                    {fetchingDetails ? (
                      // Show a loader component while fetching the description
                      <div style={{ position: 'absolute', top: 0, left: 110, width: 'calc(100% - 100px - 20px)' }}>
                        <Skeleton3 />
                      </div>
                    ) : detailsData[selectedRowIndex]?.description ? (
                      // Display the description if available after fetching
                      <span
                        style={{
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                          margin: 0,
                          textAlign: 'left'
                        }}
                      >
                        {detailsData[selectedRowIndex].description}
                      </span>
                    ) : (
                      // Fallback when no description is available
                      <div style={{ display: 'flex' }}>
                        <NoContent width='100%' height='100px' label='No description' />
                      </div>
                    )}
                  </div>
                  <TabNavigation
                    tabs={equipmentData[selectedRowIndex]['configurableoptions'] ? ['Details', 'Documents', 'Configuration'] : ['Details', 'Documents']}
                    onTabClick={handleTabClick}
                    selectedRowIndex={selectedRowIndex}
                  />
                  {currentTabIndex === 0 && (
                    <>
                      <OperatingConditions inputArray={equipmentData[selectedRowIndex]['operatingconditions']} />
                      <ProcessConnections connections={equipmentData[selectedRowIndex]['connectiondata']} />
                      <ElectricalData inputArray={equipmentData[selectedRowIndex]['electricaldata']} />
                      <MeasuringData inputArray={equipmentData[selectedRowIndex]['measuringdata']} />
                      <MaterialsData inputArray={equipmentData[selectedRowIndex]['moc']} />
                    </>
                  )}
                  {currentTabIndex === 1 && (
                    <>
                      <DocumentComponent detailsData={detailsData} selectedRowIndex={selectedRowIndex} />
                    </>
                  )}
                  {currentTabIndex === 2 && equipmentData[selectedRowIndex]['configurableoptions'] && (
                    <>
                      <div className="configuration-table-container" ref={containerRef}>
                        <div className="configuration-header"></div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                          {isNarrow ? (
                            <div className="config-container">
                              <div
                                className="config-selections"
                                style={{
                                  transform: showSelections ? "translateX(0)" : "translateX(-100%)",
                                }}
                              >
                                <ConfigurationSelections
                                  equipmentData={equipmentData}
                                  selectedRowIndex={selectedRowIndex}
                                  selectedButton={selectedButton}
                                  selectedOption={selectedOption}
                                  handleButtonClick={handleButtonClick}
                                  isNarrow={isNarrow}
                                  showSelections={showSelections}
                                />
                              </div>
                              <div
                                className="config-options"
                                style={{
                                  transform: showSelections ? "translateX(0)" : "translateX(-100%)",
                                }}
                              >
                                <ConfigurationOptions
                                  equipmentData={equipmentData}
                                  selectedRowIndex={selectedRowIndex}
                                  activeConfigIndex={activeConfigIndex}
                                  isOptionDisabled={isOptionDisabled}
                                  handleOptionChange={handleOptionChange}
                                  selectedOption={selectedOption}
                                  isNarrow={isNarrow}
                                  showSelections={showSelections}
                                />
                              </div>
                            </div>
                          ) : (
                            <>

                              <ConfigurationSelections
                                equipmentData={equipmentData}
                                selectedRowIndex={selectedRowIndex}
                                selectedButton={selectedButton}
                                selectedOption={selectedOption}
                                handleButtonClick={handleButtonClick}
                                isNarrow={isNarrow}
                              />
                              <ConfigurationOptions
                                equipmentData={equipmentData}
                                selectedRowIndex={selectedRowIndex}
                                activeConfigIndex={activeConfigIndex}
                                isOptionDisabled={isOptionDisabled}
                                handleOptionChange={handleOptionChange}
                                selectedOption={selectedOption}
                                isNarrow={isNarrow}
                              />

                            </>
                          )}

                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className='nocontent-positioning-container' style={{ height: isToggled ? 'calc(100% - 44px)' : '100%' }}>
              <NoContent width='100%' height='100%' label='Please select a part' />
            </div>
          )}
        </div>
      </div>
      {checkedRowIndexes.length > 0 && (
        <div style={{ position: 'fixed', bottom: '20px', height: '64px', backgroundColor: 'white', transform: 'translateX(-50%)', left: '50%', display: 'flex', flexDirection: 'row', border: '1px #d8dee4 solid', borderRadius: '5px', width: '100%', maxWidth: '700px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
          <div className={'font-style-1'} style={{ fontSize: '30px', height: '100%', width: '64px', textAlign: 'center', backgroundColor: '#0073ea', color: 'white', borderRadius: '5px 0 0 5px' }}>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '100%' }}>{checkedRowIndexes.length}</div>
          </div>
          <div className={'parts-selected-container'}>
            <span className={'font-style-1'} style={{ fontSize: '24px' }}>Part{checkedRowIndexes.length > 1 ? 's' : ''} Selected</span>
            <span>
              {checkedRowIndexes.slice(0, 12).map((item, index) => (
                <span style={{ width: '7px', height: '7px', borderRadius: '20px', marginRight: '4px', background: 'rgb(87, 155, 252)', display: 'inline-block' }} key={index}></span>

              ))}
              {checkedRowIndexes.length > 12 && (
                <span className={'font-style-1'} style={{ fontSize: '12px' }}>+{checkedRowIndexes.length - 12}</span>
              )}
            </span>
          </div>
          <div className='widget-style-1'>
            <div className={`widget-style-2 ${selection === 'delete' ? 'selected' : ''}`} onClick={() => { handleSelectionChange('delete') }}>
              <FontAwesomeIcon icon={faTrashCanRegular} style={{ fontSize: '20px' }} />
              <div className={'font-style-1 widget-label'} style={{ marginTop: '5px' }}>Delete</div>
            </div>
          </div>
          <div className='widget-style-1'>
            <div className={`widget-style-2 ${selection === 'copy' ? 'selected' : ''}`} onClick={() => { handleSelectionChange('copy') }}>
              <FontAwesomeIcon icon={faCopyRegular} style={{ fontSize: '20px' }} />
              <div className={'font-style-1 widget-label'} style={{ marginTop: '5px' }}>Copy</div>
            </div>
          </div>
          <div className='widget-style-1'>
            <div className={`widget-style-2 ${selection === 'upload' ? 'selected' : ''}`} onClick={() => { handleSelectionChange('upload') }}>
              <FontAwesomeIcon icon={faArrowRightToBracket} className={'fa-rotate-270'} style={{ fontSize: '20px' }} />
              <div className={'font-style-1 widget-label'} style={{ marginTop: '5px' }}>Upload</div>
            </div>
            {selection === 'upload' && (
              <List2
                selection={selection}
                labels={['Images', 'Documents']}
                onClicks={[uploadImages, uploadDocuments]}
                setSelection={setSelection}
              />
            )}
          </div>
          <div className='widget-style-1' >
            <div className={`widget-style-2 ${selection === 'modify' ? 'selected' : ''}`} onClick={() => { handleSelectionChange('modify') }}>
              <FontAwesomeIcon icon={faPenToSquareRegular} className={'fa-rotate-270'} style={{ fontSize: '20px' }} />
              <div className={'font-style-1 widget-label'} style={{ marginTop: '5px' }}>Modify</div>
            </div>
            {selection === 'modify' && (
              <List2
                selection={selection}
                labels={['Configurations', 'Description', 'Basic Details', 'Technical Details']}
                onClicks={[modifyConfig, modifyDescription, modifyBasicDetails, modifyTechnicalDetails]}
                setSelection={setSelection}
              />
            )}
          </div>
          <div className='widget-style-1' style={{ borderRight: '1px solid #d8dee4' }}>
            <div className={`widget-style-2 ${selection === 'copy' ? 'selected' : ''}`} onClick={() => { handleSelectionChange('migrate') }}>
              <FontAwesomeIcon icon={faBoxesPacking} style={{ fontSize: '20px' }} />
              <div className={'font-style-1 widget-label'} style={{ marginTop: '5px' }}>Migrate</div>
            </div>
          </div>
          <div style={{ width: '64px', borderLeft: '1px solid #d8dee4', height: '100%', marginLeft: 'auto', cursor: 'pointer' }} onClick={(e) => { handleCloseSelections() }}>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '100%' }}>
              <FontAwesomeIcon icon={faXmark} style={{ fontSize: '20px' }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SupplierEntrypoint;