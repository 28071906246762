import React, { useEffect, useState } from 'react'
import BatteryChart from './BatteryChart.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCircleQuestion, faUser, faBell } from '@fortawesome/free-solid-svg-icons';



function PageExampleComponent() {


    const tasks = [
        { name: "Task 1", percentage: 20, color: "#2563EB" }, // 30% width
        { name: "Task 2", percentage: 15, color: "#3b7de6" },
        { name: "Task 3", percentage: 10, color: "#5197E1" },
        { name: "Task 4", percentage: 15, color: '#67b1dc' },
        { name: "Task 5", percentage: 5, color: '#7dcbd7' },
        { name: "Task 6", percentage: 10, color: '#93e5d2' },
        { name: "Task 7", percentage: 25, color: "#a6ffcb" }, // 20% width
        
    ];

    return (
        <>
            <div style={{ padding: '24px 16px', height: '100%' }}>
                <div className='flex-col-20' style={{ height: '100%' }}>
                    <div className='flex-row-0 justify-between'>
                        <div className='zp-font-small align-center' style={{ minHeight: '22px', width: '300px', background: 'white', border: 'solid #ccc 1px', borderRadius: '4px', overflow: 'hidden' }}>
                            <span style={{ paddingLeft: '10px' }}>Search</span>
                        </div>
                        <div className='flex-row-20 align-center' style={{ paddingRight: '10px' }}>
                            <FontAwesomeIcon style={{ fontSize: "12px" }} color={'grey'} icon={faUser} />
                            <FontAwesomeIcon style={{ fontSize: "12px" }} color={'grey'} icon={faCircleQuestion} />
                            <FontAwesomeIcon style={{ fontSize: "12px" }} color={'grey'} icon={faBell} />

                        </div>
                    </div>
                    <div className='flex-col-10' style={{ background: 'white', width: '100%', padding: '20px', borderRadius: '8px' }}>
                        <div className='zp-weight-l' style={{ borderBottom: '1px #ccc solid', fontSize: '17px', fontFamily: 'var(--font-family-1)', width: '100%', paddingBottom: '4px' }}>
                            <span>Today</span>
                        </div>
                        <div className='flex-row-20' style={{ paddingTop: '10px', width: '100%' }}>
                            <div style={{ width: '70%' }}>
                                <div className='flex-row-40'>

                                    <div>
                                        <div className='zp-weight-l' style={{ fontSize: '11px', fontFamily: 'var(--font-family-1)' }}>Project Budget<span style={{ marginLeft: '10px' }}><FontAwesomeIcon icon={faChevronDown} /></span></div>
                                        <div className='zp-weight-m' style={{ fontSize: '14px', fontFamily: 'var(--font-family-1)', marginTop: '4px' }}>A$600,578</div>
                                    </div>
                                    <div>
                                        <div className='zp-weight-l' style={{ fontSize: '11px', fontFamily: 'var(--font-family-1)' }}>Project<span style={{ marginLeft: '10px' }}><FontAwesomeIcon icon={faChevronDown} /></span></div>
                                        <div className='' style={{ fontSize: '14px', fontFamily: 'var(--font-family-1)', marginTop: '4px' }}>PROJ0001</div>
                                    </div>
                                </div>

                                <div style={{ marginTop: '5px', padding: '5px 0px' }}>
                                    <BatteryChart tasks={tasks} width={'100%'} height={'80px'} />
                                </div>


                            </div>
                            <div className='flex-col-10' style={{ width: '30%' }}>
                                <div style={{ height: '100%' }}>
                                    <div className='zp-weight-l' style={{ fontSize: '11px', fontFamily: 'var(--font-family-1)' }}>Project Completion<span style={{ marginLeft: '10px' }}><FontAwesomeIcon icon={faChevronDown} /></span></div>
                                    <div className='flex-row-10' style={{ fontSize: '16px', fontFamily: 'var(--font-family-1)', marginTop: '4px' }}>
                                        <span style={{ fontFamily: 'inherit' }}>60%</span>
                                        <span
                                            className='zp-weight-m'
                                            style={{
                                                textWrap: 'nowrap',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                padding: '1px 6px',
                                                color: 'rgb(0, 85, 188)',
                                                backgroundColor: 'rgb(207, 245, 246)'
                                            }}>
                                            RevB
                                        </span>
                                    </div>
                                    <span className='zp-font-small' style={{ color: '#aaa' }}>Nov 13 Est. Completion</span>
                                </div>
                                <div className='justify-end flex-col-0' style={{ height: '100%', borderTop: '1px solid #eee' }}>
                                    <div className='zp-weight-l' style={{ fontSize: '11px', fontFamily: 'var(--font-family-1)' }}>Next Task<span style={{ marginLeft: '10px' }}><FontAwesomeIcon icon={faChevronDown} /></span></div>
                                    <div className='' style={{ fontSize: '14px', fontFamily: 'var(--font-family-1)', marginTop: '4px' }}>Hydraulics</div>
                                    <span className='zp-font-small' style={{ color: '#aaa' }}>Today Est. Completion</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-row-10' style={{ width: '100%', height: '100%' }}>
                        <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px', padding: '20px' }}>
                            <div className='zp-weight-l' style={{ fontSize: '12px', fontFamily: 'var(--font-family-1)', borderBottom: '1px solid #eee', paddingBottom: '5px' }}>P&ID Status</div>
                            <div className='justify-between' style={{ padding: '10px 0px', borderBottom: '1px solid #eee' }}>
                                <div>
                                    <span className=' zp-font-small'>PROJ0003</span>
                                    <span className=' zp-font-small' style={{ color: '#aaa', marginLeft: '10px' }}>Nov 18</span>
                                </div>
                                <span
                                    className='zp-weight-m'
                                    style={{
                                        textWrap: 'nowrap',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        padding: '1px 6px',
                                        color: 'rgb(0, 85, 188)',
                                        backgroundColor: 'rgb(207, 245, 246)'
                                    }}>
                                    RevB
                                </span>
                            </div>
                            <div className='justify-between' style={{ padding: '10px 0px', borderBottom: '1px solid #eee' }}>
                                <div>
                                    <span className=' zp-font-small'>PROJ0002</span>
                                    <span className=' zp-font-small' style={{ color: '#aaa', marginLeft: '10px' }}>Nov 12</span>
                                </div>
                                <span
                                    className='zp-weight-m'
                                    style={{
                                        textWrap: 'nowrap',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        padding: '1px 6px',
                                        color: 'rgb(0, 85, 188)',
                                        backgroundColor: 'rgb(207, 245, 246)'
                                    }}>
                                    RevD
                                </span>

                            </div>
                            <div className='justify-between' style={{ padding: '10px 0px', borderBottom: '1px solid #eee' }}>
                                <div>
                                    <span className=' zp-font-small'>PROJ0001</span>
                                    <span className=' zp-font-small' style={{ color: '#aaa', marginLeft: '10px' }}>Nov 5</span>
                                </div>
                                <span
                                    className='zp-weight-m'
                                    style={{
                                        textWrap: 'nowrap',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        padding: '1px 6px',
                                        color: 'green',
                                        backgroundColor: 'rgb(207, 245, 246)'
                                    }}>
                                    As Built
                                </span>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px', padding: '20px' }}>
                            <div className='zp-weight-l' style={{ fontSize: '12px', fontFamily: 'var(--font-family-1)', borderBottom: '1px solid #eee', paddingBottom: '5px' }}>Order Status</div>
                            <div className='justify-between' style={{ padding: '10px 0px', borderBottom: '1px solid #eee' }}>
                                <div>
                                    <span className=' zp-font-small'>ON0003</span>
                                    <span className=' zp-font-small' style={{ color: '#aaa', marginLeft: '10px' }}>Dec 18</span>
                                </div>
                                <span
                                    className='zp-weight-m'
                                    style={{
                                        textWrap: 'nowrap',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        padding: '1px 6px',
                                        color: 'rgb(0, 85, 188)',
                                        backgroundColor: 'rgb(207, 245, 246)'
                                    }}>
                                    Paid
                                </span>
                            </div>
                            <div className='justify-between' style={{ padding: '10px 0px', borderBottom: '1px solid #eee' }}>
                                <div>
                                    <span className=' zp-font-small'>ON0002</span>
                                    <span className=' zp-font-small' style={{ color: '#aaa', marginLeft: '10px' }}>Dec 12</span>
                                </div>
                                <span
                                    className='zp-weight-m'
                                    style={{
                                        textWrap: 'nowrap',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        padding: '1px 6px',
                                        color: 'rgb(0, 85, 188)',
                                        backgroundColor: 'rgb(207, 245, 246)'
                                    }}>
                                    Paid
                                </span>

                            </div>
                            <div className='justify-between' style={{ padding: '10px 0px', borderBottom: '1px solid #eee' }}>
                                <div>
                                    <span className=' zp-font-small'>ON0001</span>
                                    <span className=' zp-font-small' style={{ color: '#aaa', marginLeft: '10px' }}>Dec 5</span>
                                </div>
                                <span
                                    className='zp-weight-m'
                                    style={{
                                        textWrap: 'nowrap',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        padding: '1px 6px',
                                        color: 'green',
                                        backgroundColor: 'rgb(207, 245, 246)'
                                    }}>
                                    Delivered
                                </span>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '100%', background: 'white', borderRadius: '8px', padding: '20px' }}>
                            <div className='zp-weight-l' style={{ fontSize: '12px', fontFamily: 'var(--font-family-1)', borderBottom: '1px solid #eee', paddingBottom: '5px' }}>Invoices</div>
                            <div className='flex-row-0' style={{ width: '100%', height: '10px', marginTop: '10px', borderRadius: '4px', overflow: 'hidden' }}>
                                <div style={{ width: '40%', height: '100%', backgroundColor: '#96f' }}></div>
                                <div style={{ width: '35%', height: '100%', backgroundColor: '#0073e6' }}></div>
                                <div style={{ width: '25%', height: '100%', backgroundColor: '#00c4c4' }}></div>
                            </div>
                            <div className='justify-between' style={{ padding: '10px 0px', borderBottom: '1px solid #eee' }}>
                                <div className='flex-row-0 align-center'>
                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#96f', borderRadius: '4px', overflow: 'hidden' }}></div>
                                    <span className=' zp-font-small' style={{ color: '', marginLeft: '10px' }}>Paid</span>
                                </div>
                                <span className='zp-font-small zp-weight-m'>A$400,000.00</span>
                            </div>
                            <div className='justify-between' style={{ padding: '10px 0px', borderBottom: '1px solid #eee' }}>
                                <div className='flex-row-0 align-center'>
                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#0073e6', borderRadius: '4px', overflow: 'hidden' }}></div>
                                    <span className=' zp-font-small' style={{ color: '', marginLeft: '10px' }}>Open</span>
                                </div>
                                <span className='zp-font-small zp-weight-m'>A$250,000.00</span>
                            </div>
                            <div className='justify-between' style={{ padding: '10px 0px', borderBottom: '1px solid #eee' }}>
                                <div className='flex-row-0 align-center'>
                                    <div style={{ height: '10px', width: '10px', backgroundColor: '#00c4c4', borderRadius: '4px', overflow: 'hidden' }}></div>
                                    <span className=' zp-font-small' style={{ color: '', marginLeft: '10px' }}>Past Due</span>
                                </div>
                                <span className='zp-font-small zp-weight-m'>A$1,000.00</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </>
    )
}



export default PageExampleComponent
//
//

//<div className='flex-row-5'>
//                        <span>Pressure Transducers</span>
//                        <span>Part Details</span>
//                        <span>Process Connections</span>
//                        <span>Operating Conditions</span>
//                        <span>Search</span>
//                    </div>
//
//                    <div className='flex-row-5'>
//                        <div className='flex-col-5' style={{width: '100%'}}>
//                            <span><a className='monostyle-text zp-font-small'>PN0002</a> Pressure Transducer $400</span>
//                            <span>PN0002 Pressure Transducer</span>
//                            <span>PN0002 Pressure Transducer</span>
//                            <span>PN0002 Pressure Transducer</span>
//                        </div>
//                        <div className='flex-col-5' style={{width: '100%'}}>
//                            <span>test</span>
//                        </div>
//                    </div>