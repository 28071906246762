import React, { useState } from 'react';

const DropdownMenu = () => {
  const [selectedState, setSelectedState] = useState('New South Wales');

  const statesOfAustralia = [
    'Australian Capital Territory',
    'New South Wales',
    'Northern Territory',
    'Queensland',
    'South Australia',
    'Tasmania',
    'Victoria',
    'Western Australia',
  ];

  const handleSelect = (event) => {
    setSelectedState(event.target.value);
  };

  return (
    <div style={{ }}>
      <select
        value={selectedState}
        onChange={handleSelect}
        style={{
          fontSize: '14px',
          fontFamily: 'var(--font-family-1)',
          width: '100%',
          padding: '8px 12px',
          
          border: '1px solid #ccc',
          borderRadius: '4px',
          background: '#f9f9f9',
          cursor: 'pointer',
          appearance: 'none', // Removes default arrow for styling consistency
          outline: 'none',
          boxShadow: 'none',
        }}
      >
        {statesOfAustralia.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownMenu;
