import React, {useEffect, useState} from 'react'


function TestPartComponent() {

    

return (
    <>
    <div style={{height: '44px', justifyContent: 'center', display: 'flex', alignItems: 'center', padding: '0px 20px', backgroundColor: '#339AF0', gap: '40px'}}> 
        <span className='test-data-test-mode'>Test mode</span>
        <span className='test-data-description'>You're using test data. No real parts will be processed.</span>
      </div>
    
    </>
)

}

export default TestPartComponent;

