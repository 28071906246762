import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useShipmentData } from './ShipmentDataContext.js'
import { useFormData } from './FormDataContext.js'
import { useAuth } from '../../../contexts/AuthContext.js'
import { BreadCrumbContext } from "./BreadCrumbContext";
import { loadStripe } from '@stripe/stripe-js';
import { useCookies } from 'react-cookie';
import { AlertContext } from "./../../body/customerBody/AlertContext"

const StripeContext = createContext()
const apiUrl = process.env.REACT_APP_API_URL;
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);

export const useStripeContext = () => useContext(StripeContext)

export const StripeContextProvider = ({ children }) => {
    const {user} = useAuth()
    const {formData, formData3 } = useFormData()
    const {shippingData} = useShipmentData()
    const { goToNextStep, setOrderNumber } = useContext(BreadCrumbContext);
    const [, setIsProcessing] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [cookies, setCookies] = useCookies(['cart_cookie', 'csrf_token', 'user']);
    const [stripe, setStripe] = useState(null);
    const [elements, setElements] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const { showAlertMessage } = useContext(AlertContext);
    const handleSetStripeElements = (stripeInstance, elementsInstance) => {
      setStripe(stripeInstance);
      setElements(elementsInstance)
    }

    useEffect(() => {
        // Fetch the client secret from the server
        const fetchClientSecret = async () => {
          try {
            const response = await fetch(`${apiUrl}/api/create-setup-intent`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': cookies.csrf_token,
              },
              //body: JSON.stringify({ amount: 1000 }),
              credentials: 'include',
            });
            const { clientSecret } = await response.json();
            setClientSecret(clientSecret);
          } catch (error) {
            console.error('Failed to fetch client secret:', error);
          }
        };
        fetchClientSecret();
    }, []);

    const handleOrderSubmit = async (e) => {
      setIsLoading(true);
      e.preventDefault();
      const [ zephyrSuccess, orderNumber ] = await handleZephyrSubmit();
      // console.log(zephyrSuccess, orderNumber);
      if (!zephyrSuccess) {
        // Stop the process if handleZephyrSubmit fails
        // console.log('zephyr success failed');
        return;
      }
      // console.log("HERE??");
      await updateSetupIntentMetadata(clientSecret, orderNumber)
      const setupIntent = await handleSetupIntent(e);
      
      setIsLoading(false);
      goToNextStep()
      
    }

    const updateSetupIntentMetadata = async (clientSecret, orderNumber) => {
      try {
        const response = await fetch(`${apiUrl}/api/update-setup-intent`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': cookies.csrf_token,
          },
          credentials: 'include',
          body: JSON.stringify({
            clientSecret,
            metadata: { orderid: orderNumber }
          }),
        });
    
        if (!response.ok) {
          throw new Error('Failed to update SetupIntent metadata');
        }
      } catch (error) {
        console.error('Error updating SetupIntent metadata:', error);
      }
    };

    const handleSetupIntent = async (e) => {
      try {
        if (!stripe || !elements) {
          console.error("Stripe or Elements not initialized.");
          return;
        }
        // console.log("here????");
        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
          confirmParams: {
            payment_method_data: {
              billing_details: {
                name: 'Test name',
                email: 'Testemail@test.com'
              }
            }
          }
        });
        // console.log("here?", setupIntent);
    
        if (error) {
          // console.log("Error during setup intent confirmation:", error.message);
          // Optionally, handle the error in the UI (e.g., show a message to the user)
          return;
        }
    
        // Successfully obtained setupIntent
        return setupIntent;
      } catch (err) {
        console.error("Unexpected error occurred:", err);
        // Optionally, handle the error in the UI (e.g., show a message to the user)
      }
    };
    

    let stripeItems = [];
    if (cookies.cart_cookie !== null && cookies.cart_cookie !== undefined) {
        stripeItems = cookies.cart_cookie.map(item => ({
            id: item.id,
            company_name: item.company_name,
            part_type: item.part_type,
            quantity: item.quantity,
            configurations: item.configurations
        }));
    }

    const handleZephyrSubmit = async () => {
          
          if (!cookies.cart_cookie || cookies.cart_cookie.length < 1) {
              showAlertMessage('warning', 'You need to add items to your cart!')
              setIsLoading(false);
              return [false, null]
          }
           const deliveryDetails = {
              given_name: `${formData.given_name}`, 
              family_name: `${formData.family_name}`,
              email: `${user.email}`,
              phone: `${formData.contact_number}`,
              address: {
                  city: `${formData.locality}`,
                  country: `${formData.country}`,
                  line2: `${formData.suite}`,
                  line1: `${formData.location}`,
                  postal_code: `${formData.postal_code}`,
                  state: `${formData.administrative_area_level_1}`,
              },
              geolocation: {
                  latitude: `${formData.latitude}`,
                  longitude: `${formData.longitude}`
              },
              shipping_info: {
                  delivery_method: shippingData.delivery_method,
                  shipping_type: shippingData.shipping_type
              }
          }
          const billingDetails = {
              given_name: `${formData3.given_name}`, 
              family_name: `${formData3.family_name}`,
              email: `${user.email}`,
              phone: `${formData3.contact_number}`,
              address: {
                  city: `${formData3.locality}`,
                  country: `${formData3.country}`,
                  line2: `${formData3.suite}`,
                  line1: `${formData3.location}`,
                  postal_code: `${formData3.postal_code}`,
                  state: `${formData3.administrative_area_level_1}`,
              },
              geolocation: {
                  latitude: `${formData3.latitude}`,
                  longitude: `${formData3.longitude}`
              }
          }
          try {
              const response = await fetch(`${apiUrl}/api/create-order`, {
                  method: 'POST',
                  headers: { 
                      'Content-Type': 'application/json',
                      'X-CSRFToken': cookies.csrf_token
                  },
                  body: JSON.stringify({ 
                      delivery_details: deliveryDetails,
                      billing_details: billingDetails,
                      user_details: user,
                      items: stripeItems
                  })
              });
              const responseData = await response.json();
              if (responseData.success) {
                  // Payment succeeded without additional actions
                  setOrderNumber(responseData.orderNumber)
                  setCookies('cart_cookie', [], { 
                      path: '/',
                      expires: new Date(Date.now() + 259200000),
                      secure: true,
                      httpOnly: false,
                      sameSite: 'lax' 
                  });
                  // console.log("we have success???");
                  return [true, responseData.orderNumber]
                  
              } else {
                  // Payment failed
                  console.error("Order submission failed", responseData.error);
                  //alert('An error occured processing your order.');
                   // reset the setupintent here
                  showAlertMessage('warning', responseData.error)
                  return [false, null]
              }
          } catch (error) {
              console.error('Error:', error);
              //reset the setup intent here
              showAlertMessage('warning', 'An error occured processing your order.')  
              return [false, null]
          } finally {
              
          }
      }




    
    // Use useMemo to prevent re-creating the options object on every render
    const stripeOptions = useMemo(() => ({
        clientSecret,
    }), [clientSecret]);

    return (
        <StripeContext.Provider value={{stripePromise, stripeOptions, clientSecret, handleOrderSubmit, handleSetStripeElements, isLoading, stripe, elements }}>
            {children}
        </StripeContext.Provider>
    )
}