import React, { useEffect, useState } from 'react'
import styles from './css/modalWrappers.module.css'


export function ModalWrapper1({ children, handleCloseModal, width, maxWidth, height, maxHeight, padding }) {

    function handleModalContentClick(event) {
        // Prevent click event from propagating to the parent
        event.stopPropagation();
    }

    return (
        <>
            <div className={styles['modal-wrapper']} style={{}} onClick={handleCloseModal}>
                <div
                    className={styles['modal-container']}
                    style={{
                        width: width ? width : null,
                        height: height ? height : null,
                        padding: padding ? padding : null,
                        maxWidth: maxWidth ? maxWidth : null,
                        maxHeight: maxHeight ? maxHeight : null,
                    }}
                    onClick={handleModalContentClick}
                >
                    {children}
                </div>
            </div>
        </>
    )
}

export function ModalWrapper2({ children, handleCloseModal, width, maxWidth, height, maxHeight, padding, overflow }) {

    function handleModalContentClick(event) {
        // Prevent click event from propagating to the parent
        event.stopPropagation();
    }

    return (
        <>
            <div className={styles['modal-wrapper-2']} style={{}} onClick={handleCloseModal}>
                <div
                    className={styles['modal-container-2']}
                    style={{
                        width: width ? width : null,
                        height: height ? height : null,
                        padding: padding ? padding : null,
                        maxWidth: maxWidth ? maxWidth : null,
                        maxHeight: maxHeight ? maxHeight : null,
                        overflow: overflow ? overflow : null,
                    }}
                    onClick={handleModalContentClick}
                >
                    {children}
                </div>
            </div>
        </>
    )
}





function ModalWrappers() {

}


export default ModalWrappers