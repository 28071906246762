import React, {useState, useEffect} from 'react'
import Papa from 'papaparse';
import DragAndDropZone from './DragAndDropZone';
import SelectComponent from './SelectComponent';
import './css/uploadCSV.css'

function UploadCSV({setSelection, partNumbers}) {

    const MAX_FILE_SIZE = 3 * 1024 * 1024;
    
    const modalStyle = {
        zIndex: 998,
        position: 'absolute',
        backgroundColor: 'var(--modal-background-color)',
        height: '100%',
        width: '100%',
        top : 0,
        left: 0,
    }
    const modalContent = {
        zindex: 999,
        position: 'absolute',
        border: '1px solid white',
        backgroundColor: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        width: 'min(100% - 100px, 800px)',
        borderRadius: '8px',
        padding: '16px'
    }
    const modalWrapper = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
    const modalButtons = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        gap: '10px',
        padding: '8px 0px',
        marginTop: '24px'
    }
    function handleDeleteModal() {
        setSelection('')
        setDroppedCSV([]);
        setParsedData([]);
    }
    function handleModalContentClick(event) {
        // Prevent click event from propagating to the parent
        event.stopPropagation();
    }
    
    const [droppedCSV, setDroppedCSV] = useState([]);
    const [parsedData, setParsedData] = useState([]);

    const handleCSVUpload = (file) => {
        // console.log(file[0]);
        setDroppedCSV([file[0]]);

        // Parse the CSV file
        Papa.parse(file[0], {
            header: true, // Parse as JSON with column headers
            skipEmptyLines: true,
            complete: (result) => {
                // console.log('Parsed Data:', result.data);
                setParsedData(result.data);
            },
            error: (error) => {
                console.error('Error parsing CSV:', error);
            },
        });
    };

    const [CSVUploadStep, setCSVUploadStep] = useState(0)
    const [selectedOptions, setSelectedOptions] = useState({});

    //const handleSelectChange = (fieldKey, value) => {
    //    setSelectedOptions((prev) => ({
    //        ...prev,
    //        [fieldKey]: value,
    //    }));
    //};


    const getAvailableOptions = (currentFieldKey) => {
        const allOptions = Object.keys(parsedData[0] || {}); // Get all column names
        const usedOptions = Object.values(selectedOptions).filter(Boolean);
        return allOptions.filter((option) => option === selectedOptions[currentFieldKey] || !usedOptions.includes(option));
    };

    const handleOptionSelect = (key, value) => {
        setSelectedOptions((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    useEffect(() => {
        // console.log(selectedOptions);
    },[selectedOptions])

    return (
        <>
            <div style={modalStyle} onClick={handleDeleteModal}>
                <div style={modalContent} onClick={handleModalContentClick}>
                    <div style={modalWrapper}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <div className='modal-header'>CSV Upload</div>
                            <div className='modal-text-content' style={{fontSize: '14px', fontStyle: 'Arial'}}>Upload your CSV here.</div>
                            {CSVUploadStep === 0 && (
                                <div>
                                    <div>
                                        {droppedCSV.length === 0 && (
                                          <DragAndDropZone
                                            onDrop={handleCSVUpload}
                                            hoveringText="Drop your CSV here!"
                                            defaultText="Drag & Drop your CSV here or click to upload"
                                            maxFileSize={MAX_FILE_SIZE}
                                            height={'150px'}
                                          />
                                        )}
                                        {droppedCSV.length > 0 && (
                                        <div style={{maxHeight: '400px', overflow: 'auto', scrollbarWidth: 'thin'}}>
                                            <table className="csv-table">
                                                <tr class="table-header-row">
                                                    {Object.keys(parsedData[0] || {}).map((key) => (
                                                        <th key={key}>{key}</th>
                                                    ))}
                                                </tr>
                                                {parsedData.map((row, index) => (
                                                    <tr className="table-row" key={index}>
                                                        {Object.values(row).map((value, idx) => (
                                                            <td key={idx} style={{}}>{value}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                        )}
                                    </div>
                                    {droppedCSV.length > 0 && (
                                        <div style={{height: '1px', borderTop: '1px solid #ccc'}}></div>
                                    )}
                                </div>
                            )}
                            {CSVUploadStep === 1 && (() => {
                                const pressureTransducerFields = {
                                    'operatingpressure': {
                                        'pressureOPMax': 'Max Operating Pressure',
                                        'pressureOPMin': 'Min Operating Pressure'
                                    },
                                    'operatingtemperature': {
                                        'temperatureOPMax': 'Max Operating Temperature',
                                        'temperatureOPMin': 'Min Operating Temperature'
                                    },
                                    'processConnections': {
                                        'processConnection1': 'Process Connection 1',
                                        'processConnection2': 'Process Connection 2'
                                    }
                                }

                                //should build in the ability to add new process connections
                                return (
                                    <div style={{padding: '8px', borderRadius: '8px', border: 'solid 1px #ccc'}}>
                                    <table>
                                        <tbody>
                                            {Object.entries(pressureTransducerFields).map(([key, value]) => (
                                                typeof value === 'object' ? (
                                                    Object.entries(value).map(([subKey, subLabel]) => (
                                                        <tr key={subKey} className="responsive-row">
                                                            <td className="responsive-cell-2" style={{ fontSize: '14px', width: '100%', alignSelf: 'center' }}>
                                                                {subLabel}
                                                            </td>
                                                            <td className="responsive-cell" style={{  }}>
                                                                <SelectComponent
                                                                    options={getAvailableOptions(subKey)}
                                                                    selectedValue={selectedOptions[subKey]}
                                                                    onSelect={(subLabel) => handleOptionSelect(subKey, subLabel)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr key={key}>
                                                        <td>{value}</td>
                                                        <td>
                                                            <select>
                                                                <option>Select a column</option>
                                                                {Object.keys(parsedData[0] || {}).map((key) => (
                                                                    <option key={key}>{key}</option>
                                                                ))}
                                                                
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                )
                            })()}
                                
                            
                        </div>
                        <div style={modalButtons}>
                            <span className="cancel-button" onClick={() => setCSVUploadStep((prevStep) => Math.max(prevStep - 1, 0))}>Previous Step</span>
                            <span className="delete-button" onClick={() => setCSVUploadStep((prevStep) => prevStep + 1)}>Next Step</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}


export default UploadCSV
//
//

//
//
//<td style={{border: '1px solid #ccc', display: 'flex', borderRadius: '4px', justifyContent: 'center', alignItems: 'center'}}>
//                                                            
//                                                            
//                                                            
//                                                            <select
//                                                                style={{width: '100%', outline:'none',border: 'hidden'}}
//                                                                value={selectedOptions[subKey] || ''}
//                                                                onChange={(e) => handleSelectChange(subKey, e.target.value)}
//                                                            >
//                                                                <option value="">Select a column</option>
//                                                                {getAvailableOptions(subKey).map((option) => (
//                                                                    <option key={option} value={option}>{option}</option>
//                                                                ))}
//                                                            </select>
//                                                            </td>
//
//<div style={modalButtons}>  
//                            <span className='cancel-button' onClick={handleDeleteModal}>Cancel</span>
//                            <span className='delete-button' onClick={handleDelete}>Upload</span>
//                        </div>

//{droppedCSV.length > 0 && (
//    <div id="wrapper" >
//        <table className="CSV-table">
//            <thead style={{ backgroundColor: 'grey' }}>
//                <tr>
//                    {Object.keys(parsedData[0] || {}).map((key) => (
//                        <th key={key}
//                        >
//                            {key}
//                        </th>
//                    ))}
//                </tr>
//            </thead>
//            <tbody>
//                {parsedData.map((row, index) => (
//                    <tr key={index}>
//                        {Object.values(row).map((value, idx) => (
//                            <td key={idx} style={{}}>{value}</td>
//                        ))}
//                    </tr>
//                ))}
//            </tbody>
//        </table>
//    </div>
//)}