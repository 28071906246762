import React, {useEffect, useState} from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth, useToggle } from 'index.js'
//import Settings from './Settings.js'
import { Profile, Security, Settings, Plans, Account, Profile2 } from '../pagesindex.js' 
import { useNavigate, useLocation  } from 'react-router-dom';


function SettingsEntrypointWrapper() {
    const { isToggled } = useToggle();

    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        // console.log("current location,", location);
    },[location])

    return (
        <>
            <div style={{height: isToggled ? 'calc(-44px - 62px + var(--vh))' : 'calc(-62px + var(--vh))', display: 'flex',flexDirection: 'column',gap: '20px',width: '100%',padding:'16px',overflowY:'auto', scrollbarWidth: 'thin'}}>
                <div style={{maxWidth: '1300px', margin: '0px auto', height: '100%', width: '100%'}}>
                    <Routes>
                        <Route path="" element={
                            <Settings/>
                        }/>
                        <Route path="profile" element={
                            <Profile2/>
                        }/>
                        <Route path="security" element={
                            <Security/>
                        }/>
                        <Route path="plans" element={
                            <Plans/>
                        }/>
                        <Route path="account" element={
                            <Account/>
                        }/>
                    </Routes>





                </div>
            </div>
        </>
    )
}


export default SettingsEntrypointWrapper