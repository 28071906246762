import React, { useState, useEffect, forwardRef } from 'react';

const GenericInputBox2 = forwardRef(({ id, label, placeholder, labelPosition, defaultValue, disabled, onChange, borderRadius }, ref) => {
    const [inputValue, setInputValue] = useState(defaultValue || '');
    const [isDisabled] = useState(disabled || false);
    const [isFocused, setIsFocused] = useState(false);
    const [showWarn, setShowWarn] = useState(false);

    useEffect(() => {
        setInputValue(defaultValue || '');
    }, [defaultValue]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        if (onChange) {
            onChange(newValue); // Returns an object
        }
    };

    const InputStyles = {
        border: isFocused ? '1px solid var(--brand-color-1)' : '1px solid #ccc',
        padding: '8px 12px',
        outline: 'none',
        transition: 'border 0.15s ease-in',
        borderRadius: borderRadius ? borderRadius : '4px',
        width: '100%',
        background: isDisabled ? '#eee' : '',
    };

    const LabelInputStyle = {
        fontSize: '14px',
        fontFamily: 'var(--font-family-1)',
        fontWeight: 500,
        marginBottom: labelPosition === 'left' ? '0px' : '5px',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        minWidth: labelPosition === 'left' ? '120px' : '',
    };

    const WarningStyle = {
        display: 'relative',
        top: '0px',
        paddingTop: '5px',
        fontSize: '14px',
        fontFamily: 'var(--font-family-1)',
    };

    return (
        <>
            <div style={{ marginBottom: showWarn ? '0px' : '24px' }}>
                <div className={`${labelPosition === 'left' ? 'flex-row-5' : ''}`}>
                    <div style={LabelInputStyle}>{label}</div>
                    <input
                        id={id ? id : ''}
                        ref={ref ? ref : null}
                        style={InputStyles}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        placeholder={placeholder}
                        value={inputValue}
                        onChange={handleInputChange}
                        disabled={isDisabled}
                    />
                </div>

                {showWarn && <div style={WarningStyle}>Show warning lol</div>}
            </div>
        </>
    );
})

export default GenericInputBox2;
