import react, { useContext, useEffect, useState, useRef } from 'react'
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { isEqual, mapValues } from 'lodash';
import { useToggle, useAuth } from 'index'
import CheckoutProvider2, { useFormDataContext, useStripeContext, useGoogleMaps, useCartDataContext, useCheckoutImageContext } from 'index';
import { GenericInputBox2, Button1, Button2, Button3, DropdownMenu, RadioGroup, GenericCheckbox, NoContent, NumberPicker } from 'index.js'
import './Checkout.css'
import CheckoutConfirmation from './CheckoutConfirmation';
import SmallCheckout from './smallCheckout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faExpand, faXmark } from '@fortawesome/free-solid-svg-icons';


export function Fragment0({ setDirection, setFragment, resetAutocomplete }) {
    const { formData, updateFormData } = useFormDataContext()


    useEffect(() => {
        // console.log(formData);
    }, [formData])
    const { user } = useAuth()


    return (
        <>
            <div className='justify-center flex-col-10' style={{ backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
                <div className='flex-col-10' style={{ padding: '20px' }}>
                    <span className='zp-font-xlarge zp-weight-m'>Contact details</span>
                    <span className='zp-font-medium'>Contact details to be used for delivery and billing</span>
                </div>
                <div className='flex-col-0 justify-center' style={{ padding: '0px 20px', paddingBottom: '50px' }}>
                    <GenericInputBox2 label={'Work Email'} placeholder={'jane.doe@example.com'} defaultValue={user.email} disabled={true} />
                    <div className='flex-row-20'>
                        <div style={{ width: '100%' }}>
                            <GenericInputBox2 label={'First name'} placeholder={'Jane'} defaultValue={formData.given_name} onChange={(newValue) => updateFormData({ given_name: newValue })} />
                        </div>
                        <div style={{ width: '100%' }}>
                            <GenericInputBox2 label={'Family name'} placeholder={'Doe'} defaultValue={formData.family_name} onChange={(newValue) => updateFormData({ family_name: newValue })} />
                        </div>
                    </div>
                    <GenericInputBox2 label={'Company'} placeholder={'Example Pty Ltd'} defaultValue={formData.company} onChange={(newValue) => updateFormData({ company: newValue })} />
                    <GenericInputBox2 label={'ABN'} placeholder={'00123456789'} defaultValue={formData.abn} onChange={(newValue) => updateFormData({ abn: newValue })} />
                    <GenericInputBox2 label={'Contact number'} placeholder={'0412345678'} defaultValue={formData.contact_number} onChange={(newValue) => updateFormData({ contact_number: newValue })} />
                </div>


                <div className='zp-font-l justify-center align-center' style={{ padding: '20px 15px', margin: '4px', backgroundColor: 'rgb(246, 249, 252)', borderRadius: '10px' }}>
                    <div className='justify-end' style={{ width: '100%', padding: '0px 0px' }} >

                        <Button1 label={'Continue to delivery'} onClick={() => {
                            setFragment(1)
                            resetAutocomplete()
                        }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export function Fragment1({ setDirection, setFragment, initAutocomplete, isLoaded, resetAutocomplete }) {
    const { formData, updateFormData, handleUseAddressClick, isPrevDelivery, setIsPrevDelivery, shippingMethod, setShippingMethod, prevFormData } = useFormDataContext()
    const addressRef = useRef(null);

    const normalizeStrings = (obj) =>
        mapValues(obj, (value) => (typeof value === 'string' ? value.toLowerCase() : value));

    useEffect(() => {
        if (isEqual(normalizeStrings(formData), normalizeStrings(prevFormData))) {
            // console.log("formData and prevFormData are identical (case insensitive)");
            setIsPrevDelivery(true)
        } else {
            // console.log("formData has changed");
            setIsPrevDelivery(false)
        }
    }, [formData, prevFormData]);

    useEffect(() => {
        // console.log("Google Maps API Loaded:", isLoaded);
        setTimeout(() => {
            if (isLoaded && addressRef.current) {
                // console.log("Initializing Autocomplete for Address Line 1...");
                initAutocomplete('delivery-details', [addressRef.current.id],
                    {
                        fields: ['address_components', 'geometry', 'name'],
                        types: ['address'],
                        componentRestrictions: { country: ['AU'] }
                    }, place => {
                        // console.log("Place selected:", place);
                    }
                );
            } else {
                console.warn("Google Maps API is not loaded yet.");
            }
        }, 1100); // ⏳ Delay execution by 1 second
    }, [initAutocomplete, isLoaded]);

    const [state, setState] = useState("")

    const states = [
        "NSW",
        "VIC",
        "QLD",
        "WA",
        "SA",
        "TAS",
        "ACT",
        "NT"
    ]

    const shippingOptions = [
        { id: 'express-shipping', value: "Express Shipping", label: "Express shipping" },
        { id: 'standard-shipping', value: "Standard Shipping", label: "Standard shipping" }
    ];

    useEffect(() => {
        if (formData.administrative_area_level_1 !== state) {
            setState(formData.administrative_area_level_1);
        }
    }, [formData.administrative_area_level_1]);

    return (
        <>
            <div className='justify-center flex-col-10' style={{ backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
                <div className='flex-col-10' style={{ padding: '20px' }}>
                    <span className='zp-font-xlarge zp-weight-m'>Delivery details</span>
                    <span className='zp-font-medium'>Please fill out your delivery details below.</span>
                    <span className='zp-font-m'>
                        <GenericCheckbox
                            label="Use previous delivery details?"
                            checked={isPrevDelivery}
                            onChange={(e) => {
                                const checked = e.target.checked
                                handleUseAddressClick(checked)
                                if (checked) {
                                    setIsPrevDelivery(true)
                                } else {
                                    setIsPrevDelivery(false)
                                }
                            }}
                        />
                    </span>
                </div>
                <div className='flex-col-0 justify-center' style={{ padding: '0px 20px', paddingBottom: '50px' }}>
                    <GenericInputBox2 id={'delivery-address-line-1'} ref={addressRef} label={'Address line 1'} placeholder={'01 Example Ave'} defaultValue={formData.location} onChange={(newValue) => updateFormData({ location: newValue })} />
                    <GenericInputBox2 label={'Address line 2'} placeholder={'Unit 01'} defaultValue={formData.suite} onChange={(newValue) => updateFormData({ suite: newValue })} />
                    <div className='flex-row-20'>
                        <span style={{ width: '100%' }}><GenericInputBox2 label={'City'} placeholder={'Sydney'} defaultValue={formData.locality} onChange={(newValue) => updateFormData({ locality: newValue })} /></span>
                        <span style={{ width: '100%' }}>
                            <DropdownMenu
                                options={states}
                                placeholder={'State'}
                                selectedOption={state}
                                setSelectedOption={setState}
                                label={'State'}
                                onChange={(newValue) => {
                                    updateFormData({ administrative_area_level_1: newValue })
                                    // console.log(newValue);
                                    setState(newValue.toUpperCase());
                                }} /></span>
                        <span style={{ width: '100%' }}><GenericInputBox2 label={'Postal code'} placeholder={'1111'} defaultValue={formData.postal_code} onChange={(newValue) => updateFormData({ postal_code: newValue })} /></span>
                    </div>
                    <GenericInputBox2 label={'Country'} placeholder={'Australia'} defaultValue={formData.country} onChange={(newValue) => updateFormData({ country: newValue })} />
                    <div className='flex-col-10'>
                        <span className='zp-font-xlarge zp-weight-m'>Shipping details</span>
                        <RadioGroup options={shippingOptions} name="deliverymethod" selectedValue={shippingMethod} onChange={setShippingMethod} />
                    </div>
                </div>


                <div className='zp-font-l justify-center align-center' style={{ padding: '20px 15px', margin: '4px', backgroundColor: 'rgb(246, 249, 252)', borderRadius: '10px' }}>
                    <div className='justify-between' style={{ width: '100%', padding: '0px 0px' }} >
                        <Button1 label={'Back'} onClick={() => {
                            setFragment(0)
                            resetAutocomplete()
                        }} />
                        <Button1 label={'Continue to billing'} onClick={() => {
                            resetAutocomplete()
                            setFragment(2)
                        }} />
                    </div>
                </div>

            </div>
        </>
    )
}

export function Fragment2({ setDirection, setFragment, initAutocomplete, isLoaded, resetAutocomplete }) {
    const [state, setState] = useState("")

    const {
        formData,
        formData2,
        updateFormData2,
        isPrevBilling,
        setIsPrevBilling,
        handleUseBillingClick,
        isDeliverySame,
        setIsDeliverySame,
        handleSameDeliveryClick,
        prevFormData2
    } = useFormDataContext()



    const normalizeStrings = (obj) =>
        mapValues(obj, (value) => (typeof value === 'string' ? value.toLowerCase() : value));

    useEffect(() => {
        if (isEqual(normalizeStrings(formData2), normalizeStrings(prevFormData2))) {
            // console.log("formData2 and prevFormData2 are identical (case insensitive)");
            setIsPrevBilling(true)
        } else {
            // console.log("formData2 has changed");
            setIsPrevBilling(false)
        }

        if (isEqual(normalizeStrings(formData), normalizeStrings(formData2))) {

            setIsDeliverySame(true)
        } else {
            setIsDeliverySame(false)
        }

    }, [formData2, formData, prevFormData2]);

    const addressRef = useRef(null);

    useEffect(() => {
        // console.log("Google Maps API Loaded:", isLoaded);
        setTimeout(() => {
            if (isLoaded && addressRef.current) {
                // console.log("Initializing Autocomplete for Address Line 1...");
                initAutocomplete('billing-details', [addressRef.current.id],
                    {
                        fields: ['address_components', 'geometry', 'name'],
                        types: ['address'],
                        componentRestrictions: { country: ['AU'] }
                    }, place => {
                        // console.log("Place selected:", place);
                    }
                );
            } else {
                console.warn("Google Maps API is not loaded yet.");
            }
        }, 1100); // ⏳ Delay execution by 1 second
    }, [initAutocomplete, isLoaded]);

    const states = [
        "NSW",
        "VIC",
        "QLD",
        "WA",
        "SA",
        "TAS",
        "ACT",
        "NT"
    ]

    useEffect(() => {
        if (formData2.administrative_area_level_1 !== state) {
            setState(formData2.administrative_area_level_1);
        }
    }, [formData2.administrative_area_level_1]);

    return (
        <>
            <div className='justify-center flex-col-10' style={{ backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
                <div className='flex-col-10' style={{ padding: '20px' }}>
                    <span className='zp-font-xlarge zp-weight-m'>Billing details</span>
                    <span className='zp-font-medium'>Please fill out your billing details below.</span>
                    <span className='zp-font-m'>
                        <GenericCheckbox
                            label="Same as delivery details?"
                            checked={isDeliverySame}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                setIsDeliverySame(checked);
                                if (checked) {
                                    setIsPrevBilling(false);
                                }
                                handleSameDeliveryClick(checked);
                            }} />
                    </span>
                    <span className='zp-font-m'>
                        <GenericCheckbox
                            label="Use previous billing details?"
                            checked={isPrevBilling}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                setIsPrevBilling(checked);
                                if (checked) {
                                    setIsDeliverySame(false);
                                }
                                handleUseBillingClick(checked);
                            }} />
                    </span>
                </div>
                <div className='flex-col-0 justify-center' style={{ padding: '0px 20px', paddingBottom: '50px' }}>
                    <GenericInputBox2 id={'billing-details-address-line-1'} ref={addressRef} label={'Address line 1'} placeholder={'01 Example Ave'} defaultValue={formData2.location} onChange={(newValue) => updateFormData2({ location: newValue })} />
                    <GenericInputBox2 label={'Address line 2'} placeholder={'Unit 01'} defaultValue={formData2.suite} onChange={(newValue) => updateFormData2({ suite: newValue })} />
                    <div className='flex-row-20'>
                        <span style={{ width: '100%' }}><GenericInputBox2 label={'City'} placeholder={'Sydney'} defaultValue={formData2.locality} onChange={(newValue) => updateFormData2({ locality: newValue })} /></span>
                        <span style={{ width: '100%' }}><DropdownMenu
                            options={states}
                            placeholder={'State'}
                            selectedOption={state}
                            setSelectedOption={setState}
                            label={'State'}
                            onChange={(newValue) => {
                                updateFormData2({ administrative_area_level_1: newValue })
                                // console.log(newValue);
                                setState(newValue.toUpperCase());
                            }} /></span>
                        <span style={{ width: '100%' }}><GenericInputBox2 label={'Postal code'} placeholder={'1111'} defaultValue={formData2.postal_code} onChange={(newValue) => updateFormData2({ postal_code: newValue })} /></span>
                    </div>
                    <GenericInputBox2 label={'Country'} placeholder={'Australia'} defaultValue={formData2.country} onChange={(newValue) => updateFormData2({ country: newValue })} />
                </div>
                <div className='zp-font-l justify-center align-center' style={{ padding: '20px 15px', margin: '4px', backgroundColor: 'rgb(246, 249, 252)', borderRadius: '10px' }}>
                    <div className='justify-between' style={{ width: '100%', padding: '0px 0px' }} >
                        <Button1 label={'Back'} onClick={() => {
                            setFragment(1)
                            resetAutocomplete()
                        }} />
                        <Button1 label={'Continue to payment'} onClick={() => {
                            setFragment(3)
                            resetAutocomplete()
                        }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export function Fragment3({ setDirection, setFragment, resetAutocomplete }) {
    const { handleOrderSubmit, stripePromise, stripeOptions } = useStripeContext()
    const { paymentTerm, setPaymentTerm } = useFormDataContext()

    const paymentTerms = [
        { id: 'immediate-payment', value: "Pay immediately", label: "Pay immediately" },
        { id: 'delayed-payment', value: "Delayed payment", label: "Delayed payment" }
    ];

    return (
        <>
            <div className='justify-center flex-col-10' style={{ backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '10px', width: '100%' }}>
                <div className='flex-col-10' style={{ padding: '20px 20px 0px 20px' }}>
                    <span className='zp-font-xlarge zp-weight-m'>Confirmation</span>
                    <CheckoutConfirmation setFragment={setFragment} />
                </div>
                <div className='flex-col-10' style={{ padding: '0px 20px 20px 20px' }}>
                    <span className='zp-font-xlarge zp-weight-m'>Payment terms</span>
                    <span>
                        <RadioGroup options={paymentTerms} name="deliverymethod" selectedValue={paymentTerm} onChange={setPaymentTerm} />
                        <div className='zp-font-s' style={{
                            color: '#6d6e78',
                            transition: 'opacity 0.3s ease-in-out',
                            opacity: paymentTerm === 'Delayed payment' ? 1 : 0
                        }}>By selecting delayed payment, your card details will be saved securely with our payment provider and will be debited when the delay period ends in accordance to the client agreement</div>

                    </span>
                    <span className='zp-font-xlarge zp-weight-m'>Payment</span>
                    <Elements stripe={stripePromise} options={stripeOptions}>
                        <StripePaymentForm handleOrderSubmit={handleOrderSubmit} />
                    </Elements>
                </div>
                <div className='zp-font-l justify-center align-center' style={{ padding: '20px 15px', margin: '4px', backgroundColor: 'rgb(246, 249, 252)', borderRadius: '10px' }}>
                    <div className='justify-between' style={{ width: '100%', padding: '0px 0px' }} >
                        <Button1 label={'Back'} onClick={() => {
                            setFragment(2)
                            resetAutocomplete()
                        }} />
                        <Button1 label={'Complete payment'} onClick={handleOrderSubmit} />
                    </div>
                </div>
            </div>
        </>
    )
}

export function StripePaymentForm({ handleOrderSubmit }) {
    const stripe = useStripe();
    const elements = useElements();

    const { stripePromise, clientSecret, handleSetStripeElements } = useStripeContext()

    useEffect(() => {
        if (!stripe || !elements) {
            return;
        }
        handleSetStripeElements(stripe, elements)
        const cardNumberElement = elements.create('payment', {
            fields: {
                billingDetails: {
                    name: 'never',
                    email: 'never',
                }
            }
        }
        );
        cardNumberElement.mount('#card-number-element-container');

    }, [stripe, elements])

    if (!stripePromise || !clientSecret) return null;

    return (
        <form id="payment-form" onSubmit={handleOrderSubmit}>
            {/* Show any error or success messages */}
            <div id="card-number-element-container"></div>
        </form>
    )
}

export function CheckoutRightPanel({ }) {
    const { checkoutData, cartLoading } = useCartDataContext()
    const { images } = useCheckoutImageContext()

    const [closeAll, setCloseAll] = useState(false)

    const cartItems = Object.values(checkoutData.cartItems ?? {})

    const groupedData = cartItems.reduce((acc, item) => {
        if (!acc[item.company]) {
            acc[item.company] = [];
        }
        acc[item.company].push(item);
        return acc;
    }, {});

    const groupedDataLength = Object.keys(groupedData).length

    const parentContainer = useRef(null)
    const childContainer = useRef(null)
    const [height, setHeight] = useState(0);


    useEffect(() => {
        if (!childContainer.current) return;

        const resizeObserver = new ResizeObserver(() => {
            if (childContainer.current) {
                setHeight(childContainer.current.clientHeight);
            }
        });

        resizeObserver.observe(childContainer.current);

        return () => resizeObserver.disconnect();
    }, [groupedData]);

    useEffect(() => {
        // console.log("childContainer ref:", childContainer.current);
    }, [groupedData]);

    useEffect(() => {
        // console.log(height);
    }, [height])


    return (
        <>
            <div className='checkout-right-panel-wrapper flex-col-10'>
                <div className='justify-center'>
                    <div className='flex-col-10' style={{ width: '100%', maxWidth: '450px', }}>
                        <span className='zp-font-xlarge zp-weight-m'>Cart Items</span>
                        {cartLoading ? (
                            <span className='zp-font-m'>Your cart is loading...</span>
                        ) : (
                            <>
                                {Object.keys(groupedData || {}).length === 0 && (
                                    <span className='zp-font-m'>Your cart is empty, add items to your cart to checkout!</span>
                                )}
                                <div ref={parentContainer} style={{ maxHeight: '450px', overflow: 'auto', scrollbarWidth: 'thin', scrollbarGutter: 'stable', borderTop: height > 450 ? '1px #ccc solid' : '', borderBottom: height > 450 ? '1px solid #ccc' : '' }}>
                                    <div ref={childContainer}>
                                        {Object.entries(groupedData).map(([companyName, items], index) => (
                                            <CheckoutFragment index={index} companyName={companyName} items={items} closeAll={closeAll} resetCloseAll={() => setCloseAll(false)} images={images} groupedDataLength={groupedDataLength} height={height} />
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className='justify-center'>
                    <div className='flex-col-10' style={{ width: '100%', maxWidth: '450px', padding: '20px 12px 20px 0px' }}>
                        <div className='flex-row-10' style={{ paddingRight: '0px' }}>
                            <span className='zp-font-m' style={{ width: '70%' }}>Subtotal (ex GST)</span>
                            <span className='zp-font-m  justify-end' style={{ width: '30%' }}>${checkoutData.subtotal ? checkoutData.subtotal : '0.00'}</span>
                        </div>
                        <div className='flex-row-10'>
                            <span className='zp-font-m' style={{ width: '70%' }}>Postage</span>
                            <span className='zp-font-m  justify-end' style={{ width: '30%' }}>${checkoutData.postage ? checkoutData.postage : '0.00'}</span>
                        </div>
                        <div className='flex-row-10'>
                            <span className='zp-font-m' style={{ width: '70%' }}>GST</span>
                            <span className='zp-font-m justify-end' style={{ width: '30%' }}>${checkoutData.gst ? checkoutData.gst : '0.00'}</span>
                        </div>
                        <div className='flex-row-10'>
                            <span className='zp-font-m' style={{ width: '70%' }}>Fees</span>
                            <span className='zp-font-m  justify-end' style={{ width: '30%' }}>${checkoutData.fees ? checkoutData.fees : '0.00'}</span>
                        </div>
                        <div className='flex-row-10'>
                            <span className='zp-font-m' style={{ width: '70%' }}>Total</span>
                            <span className='zp-font-m zp-weight-m justify-end' style={{ width: '30%' }}>${checkoutData.grandTotal ? checkoutData.grandTotal : '0.00'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function CheckoutFragment({ index, companyName, items, closeAll, resetCloseAll, images, groupedDataLength, height }) {
    const [isExpanded, setIsExpanded] = useState(false)
    const [closeAll2, setCloseAll2] = useState(false)

    const sumSubtotals = (items) => {
        return items.reduce((acc, item) => acc + parseFloat(item.subtotal), 0);
    };

    const companyTotal = sumSubtotals(items);

    useEffect(() => {
        if (closeAll) {
            setIsExpanded(false)
            resetCloseAll()
        }
    }, [closeAll])

    useEffect(() => {
        if (!isExpanded) {
            setCloseAll2(true)
        }
    }, [isExpanded])

    useEffect(() => {
        // console.log(groupedDataLength, index);
    }, [groupedDataLength, index])

    return (
        <>
            <div className='flex-col-0' style={{ padding: '0px 0px 0px 0px', borderBottom: ((index === groupedDataLength - 1) && (groupedDataLength > 0) && (height < 450)) ? '1px solid #ccc' : '' }}>
                <div
                    key={index}
                    className='hidden-content-show zp-font-s justify-between'
                    onClick={() => { setIsExpanded(!isExpanded) }}
                    style={{
                        width: '100%',
                        backgroundColor: '#EBF5FF',
                        padding: '8px 8px',
                        borderTop: (index === 0
                            ? (groupedDataLength > 0 && height < 450 ? '1px solid #ccc' : '')
                            : (groupedDataLength > 0 ? '1px solid #ccc' : '')
                        ),
                        cursor: 'pointer'
                    }}>
                    <div className='flex-row-10' >
                        <span className='zp-font-s ' style={{ transition: 'transform 0.3s ease-in-out', transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }}>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                        <span className=''>{companyName}</span>
                    </div>
                    <span className=''>${parseFloat(companyTotal).toFixed(2)}</span>
                </div>
                <div className={`hidden-content ${isExpanded ? "show" : ""} flex-col-10`}>
                    <CheckoutFragmentChild index={index} items={items} closeAll={closeAll} resetCloseAll={resetCloseAll} closeAll2={closeAll2} resetCloseAll2={() => setCloseAll2(false)} images={images} />
                </div>
            </div>
        </>
    )
}

export function CheckoutFragmentChild({ items, closeAll, resetCloseAll, closeAll2, resetCloseAll2, images }) {
    const [expandedItems, setExpandedItems] = useState({});

    const toggleItem = (part_number_supplier) => {
        setExpandedItems((prev) => ({
            ...prev,
            [part_number_supplier]: !prev[part_number_supplier], // Toggle individual item
        }));
    };

    useEffect(() => {
        // console.log(expandedItems);

    }, [expandedItems])

    useEffect(() => {
        if (closeAll || closeAll2) {
            setExpandedItems({})
            resetCloseAll()
            resetCloseAll2()
        }
    }, [closeAll, closeAll2])

    return (
        <>
            <div className='flex-col-5' style={{ padding: '5px 0px', paddingRight: '8px', borderTop: '1px solid #ccc' }}>
                {items.map((item, index) => (
                    <div className='flex-row-5' key={item.part_number_supplier} >
                        <div key={index} style={{ position: "relative", height: "50px", width: "50px", display: "flex", alignItems: "center" }}>
                            <div className='image-container' style={{height: '100%', width: '100%'}}>
                                {item.image_name && images?.[item.image_name] ? (
                                    <img
                                        src={`data:image/jpeg;base64,${images[item.image_name]}`}
                                        alt="Product"
                                        style={{ maxHeight: "100%", border: "solid 1px #ccc", borderRadius: '10px', overflow: 'hidden' }}
                                    />
                                ) : (
                                    <div style={{ height: '50px', width: '50px' }}>
                                        <NoContent label="" borderRadius="0px" />
                                    </div>

                                )}
                            </div>
                            <span className='remove-part-container justify-center align-center flex-row-5' style={{cursor: 'pointer'}}>

                                <FontAwesomeIcon icon={faXmark} fontSize={'20px'}/>
                               
                            </span>
                            <span
                                className='justify-center align-center zp-font-s'
                                style={{
                                    borderTopRightRadius: '10px',
                                    borderTop: item.image_name ? '1px solid #ccc' : '2px dashed #ccc',
                                    borderRight: item.image_name ? '1px solid #ccc' : '2px dashed #ccc',
                                    background: '#EBF5FF',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: '20px',
                                    height: '20px'
                                }}>
                                {item.configurations.length > 0 && <>{item.quantity}</>}
                            </span>
                        </div>
                        <div style={{ width: '100%', minWidth: '0px', maxWidth: '100%' }}>
                            <div className='flex-row-10 zp-font-s justify-between' onClick={() => toggleItem(item.part_number_supplier)} >
                                <div className='flex-row-5' style={{ width: '100%', minWidth: 0 }}>
                                    <span className='zp-font-s ' style={{ transition: 'transform 0.3s ease-in-out', transform: expandedItems[item.part_number_supplier] ? "rotate(180deg)" : "rotate(0deg)" }}>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </span>
                                    <span style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        display: 'inline-block',
                                        maxWidth: '100%',  // Adjust width as needed
                                        cursor: 'pointer'

                                    }}>
                                        {item.part_number_supplier} {item.part_name}
                                    </span>

                                </div>
                                <div className='flex-row-10' >


                                    {expandedItems[item.part_number_supplier] ? (
                                        <span className='zp-font-s'>
                                            ${parseFloat(item.price * item.quantity).toFixed(2)}
                                        </span>
                                    ) : (
                                        <span className='zp-font-s'>
                                            ${parseFloat(item.subtotal).toFixed(2)}
                                        </span>
                                    )}

                                </div>

                            </div>

                            <div className={`hidden-content ${expandedItems[item.part_number_supplier] ? "show" : ""} flex-col-0`}>
                                <CheckoutFragmentChild2 configurations={item.configurations} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

// <FontAwesomeIcon icon={faExpand} frontSize={'20px'}/>

export function CheckoutFragmentChild2({ configurations }) {
    return (
        <>
            {configurations.map((config, index) => (
                <div key={index} className="flex-row-10 justify-between" style={{ width: '100%' }}>

                    {/* Left Section: Number Picker + Configuration Name */}
                    <div className="flex-row-5" style={{ flex: '1', minWidth: '0' }}>
                        <span style={{cursor: 'pointer', color: '#ccc'}}><FontAwesomeIcon icon={faXmark}/></span>
                        
                        <NumberPicker
                            Quantity={config.quantity}
                            width={'16px'}
                            height={'16px'}
                        />

                        {/* Wrapping text inside a div for ellipsis to work */}
                        <div className='align-center' style={{ flex: '1', minWidth: '0', overflow: 'hidden' }}>
                            <span
                                className='zp-font-s align-center'
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    
                                    maxWidth: '100%',
                                    height: '100%'
                                }}>
                                {Object.entries(config.configuration)
                                    .map(([key, value]) => value) // Extract values only
                                    .join('-')}
                            </span>
                        </div>
                    </div>

                    {/* Right Section: Price */}
                    <div className="flex-row-10 zp-font-s">
                        <span>${parseFloat(config.price).toFixed(2)}</span>
                    </div>
                </div>
            ))}
        </>
    );
}


function Checkout() {
    const { isToggled } = useToggle();
    const [fragmentIndex, setFragmentIndex] = useState(0);
    const [direction, setDirection] = useState('');
    const [exiting, setExiting] = useState(false);
    const timeoutRef = useRef(null)
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSmall, setIsSmall] = useState(null);

    const { initAutocomplete, isLoaded, resetAutocomplete } = useGoogleMaps('AIzaSyBKrYgDlQdiuin7l4VFs5WEU6nz0mRQywc', {
        fields: ['address_components', 'geometry', 'name'],
        types: ['address'],
        componentRestrictions: { country: ['AU'] }
    });

    function setFragment(index) {
        resetAutocomplete()
        if (index !== fragmentIndex) {
            setExiting(true); // Mark as exiting
            setDirection(index > fragmentIndex ? 'next' : 'prev');
            // Clear any existing timeout before setting a new one
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                setFragmentIndex(index);
            }, 10);
        }
    }

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(min-width: 901px)");
        // Function to handle changes
        const handleResize = (e) => {
            if (e.matches) {
                setIsSmall(false)
                setIsExpanded(false); // Reset to false if window width > 900px
            } else {
                setIsSmall(true)
            }
        };
        // Attach listener
        mediaQuery.addEventListener("change", handleResize);
        // Cleanup listener on component unmount
        return () => mediaQuery.removeEventListener("change", handleResize);
    }, []);

    const fragments = [
        <Fragment0 key={0} setFragment={setFragment} resetAutocomplete={resetAutocomplete} />,
        <Fragment1 key={1} setFragment={setFragment} resetAutocomplete={resetAutocomplete} initAutocomplete={initAutocomplete} isLoaded={isLoaded} />,
        <Fragment2 key={2} setFragment={setFragment} resetAutocomplete={resetAutocomplete} initAutocomplete={initAutocomplete} isLoaded={isLoaded} />,
        <Fragment3 key={3} setFragment={setFragment} resetAutocomplete={resetAutocomplete} />
    ];

    return (
        <div
            style={{
                height: isToggled ? 'calc(var(--vh) - 62px - 44px)' : 'calc(var(--vh) - 62px)',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                width: '100%',
                overflowY: 'auto',
                borderTop: '1px solid #ccc',
                position: 'relative',
                overflowX: 'hidden',
                scrollbarWidth: 'thin',
            }}
        >
            <div style={{ maxWidth: '1300px', margin: '0 auto', height: '100%', width: '100%' }}>
                <div className='flex-row-0' style={{ width: '100%', height: '100%' }}>
                    <div style={{ width: '100%', height: '100%' }}>
                        <div className='is-small-900'>
                            <SmallCheckout />
                        </div>
                        <div className='checkout-padding' >
                            <div className='justify-center' style={{ width: '100%', position: 'relative' }}>
                                <TransitionGroup component={null}>
                                    <CSSTransition
                                        key={fragmentIndex}
                                        classNames={`fragment-transition-${direction}`}
                                        timeout={{ enter: 800, exit: 300 }}
                                        unmountOnExit
                                    >
                                        {fragments[fragmentIndex]}
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>
                        </div>
                    </div>
                    <div className='checkout-right-panel'>
                        <div style={{ padding: '16px 16px 16px 16px', height: '100%' }}>
                            <CheckoutRightPanel />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
