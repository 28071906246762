import React, { useState, useEffect, useRef } from 'react';
//import './ScalingBox.css';

const SvgArrow1 = ({ delay, duration, animationTime}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(true);

  const animationTimeoutRef = useRef(null);
  const startTimeRef = useRef(null);
  const remainingTimeRef = useRef(delay); // Default animation interval minus the delay

  useEffect(() => {
    // Handle tab visibility change
    const handleVisibilityChange = () => {
      setIsTabVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const startAnimation = () => {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, duration); // Duration of the animation (2 seconds)
    };

    const scheduleAnimation = (delay) => {
      startTimeRef.current = Date.now();
      animationTimeoutRef.current = setTimeout(() => {
        startAnimation();
        remainingTimeRef.current = animationTime; // Reset for the next interval
        scheduleAnimation(remainingTimeRef.current); // Schedule the next animation
      }, delay);
    };

    if (isTabVisible) {
      // Resume animation with remaining time
      scheduleAnimation(remainingTimeRef.current);
    } else {
      // Pause animation
      clearTimeout(animationTimeoutRef.current);
      const elapsed = Date.now() - startTimeRef.current;
      remainingTimeRef.current = Math.max(remainingTimeRef.current - elapsed, 0); // Avoid negative remaining time
    }

    return () => clearTimeout(animationTimeoutRef.current);
  }, [isTabVisible]);

  return (
    <div className={`arrow ${isAnimating ? 'line-animation-1' : ''}`}>
        {isAnimating && (
            <div style={{position: 'relative', left: '-20px', zIndex: '1'}}>
                <svg width="118" height="78" xmlns="http://www.w3.org/2000/svg">
                    <line 
                        x1="0" 
                        y1="39" 
                        x2="118" 
                        y2="39" 
                        stroke="var(--brand-color-1)" 
                        strokeWidth={2}
                        stroke-linecap="round"
                        style={{
                            strokeDasharray: '300',
                            strokeDashoffset: '300',
                            animation: `draw-line ${Math.floor(duration / 1000)}s linear forwards`,
                          }}
                    />
                </svg>
            </div>
        )}
    </div>
  );
};

export default SvgArrow1;
