import React from 'react';
import { useOrders } from './OrdersContext';
import Skeleton from 'react-loading-skeleton';
import PulseLoader from 'react-spinners/PulseLoader';
import 'react-loading-skeleton/dist/skeleton.css';
import './css/customerOrders.css';
import { useNavigate } from 'react-router-dom';

import useIdleActivity from './../useIdleActivity'; // Import the custom hook
import { useAuth } from '../../contexts/AuthContext.js'
function OrdersComponent() {
  const {user, setUser} = useAuth()

  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate()
  const handleLogout = () => {
      setUser(null);
      navigate('/login')
      // Optionally, you can also remove cookies or perform other logout actions here
  };
  useIdleActivity(handleLogout);

  const {
    files,
    isLoadingInitial,
    isLoadingMore,
    searchTerm,
    setSearchTerm,
    loadingOrderAgain,
    reorderItems,
    lastOrderElementRef,
    hasMore,
  } = useOrders();

  const OrderAgainButtonStyle = {
    width: "90px",
    height: "100%",
    backgroundColor: "white",
    color: "black",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box",
    padding: "7px",
    minWidth: "7px",
    cursor: "pointer",
    textAlign: "center",
  };

  const loaderContainerStyle = {
    width: '100%',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: "border-box",
    height: "100%",
    padding: "2px",
    minWidth: "7px",
  };

  const filteredFiles = searchTerm
    ? files.filter(file => file.invoiceid.toLowerCase().includes(searchTerm.toLowerCase()))
    : files;

  return (
    <div className="customer-orders">
      <h2>Order History</h2>
      <input
        type="text"
        className="orders-search-input"
        placeholder="Search by Order Number..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className='table-container'>
        <table className="orders-table">
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Order Date</th>
              <th>Order Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoadingInitial ? (
              <tr>
                <td colSpan="6">
                  <Skeleton count={5} />
                </td>
              </tr>
            ) : (
              filteredFiles.map((file, index) => (
                <tr key={index} ref={filteredFiles.length === index + 1 ? lastOrderElementRef : null}>
                  <td className="invoice-link">
                    <a href={`${apiUrl}/api/download/invoices/${file.invoiceid}`} download>
                      <i className="fas fa-file-pdf"></i>{file.invoiceid}
                    </a>
                  </td>
                  <td>{file.orderTime || 'N/A'} {file.orderDate || 'N/A'}</td>
                  <td>${file.totalAmount || 'N/A'}</td>
                  <td>
                    <button
                      onClick={() => reorderItems(file.orderid)}
                      style={OrderAgainButtonStyle}
                      disabled={loadingOrderAgain[file.orderid]}
                    >
                      {loadingOrderAgain[file.orderid] ?
                        <div style={loaderContainerStyle}>
                          <PulseLoader size={6} color={"#808080"} margin={3} />
                        </div> : 'Order Again'}
                    </button>
                  </td>
                </tr>
              ))
            )}
            {isLoadingMore && (
              <tr>
                <td colSpan="6" style={{ textAlign: 'center' }}>
                  <PulseLoader size={10} color={"#808080"} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!hasMore && !isLoadingMore && (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>No more orders to load.</div>
        )}
      </div>
    </div>
  );
}

export default OrdersComponent;

