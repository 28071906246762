

import React, {useState, useEffect} from 'react'
//import TabNavigation from '../../../../../generalComponents/TabNavigation.js'
import ConfigOption from './ConfigOption.jsx'
import ConfigSelection from './ConfigSelection.jsx'

import { TabNavigation } from 'index.js'

function ModifyConfig({partNumbers, setSelection2}) {

    const modalStyle = {
        zIndex: 998,
        position: 'absolute',
        backgroundColor: 'var(--modal-background-color)',
        height: '100%',
        width: '100%',
        top : 0,
        left: 0,
    }
    const modalContent = {
        zindex: 999,
        position: 'absolute',
        border: '1px solid white',
        backgroundColor: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        width: '400px',
        height: '600px',
        borderRadius: '8px',
        padding: '16px'
    }
    const modalWrapper = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
    const modalButtons = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        gap: '10px',
        padding: '8px 0px',
        marginTop: '24px'
    }

    function handleDeleteModal(event) {
        //if (isFocused) {
        //    event.stopPropagation()
        ////} else {
            setSelection2('')
       // }
        
    }
    function handleModalContentClick(event) {
        // Prevent click event from propagating to the parent
        event.stopPropagation();
    }

    function handleDelete() {
        // console.log('delete');
    }


    const [activeTabIndex, setActiveTabIndex] = useState(0)
    
    function handleTabClick(index) {
        setActiveTabIndex(index)
    }






    
    //const [options, setOptions] = useState([1]);
    //const [selections, setSelections] = useState([1]);
    

    const [selection, setSelection] = useState(1); // Active selection
    const [selections, setSelections] = useState([
        { id: 1, options: [1] },
    ]); // Initial selections with unique IDs

    // Add a new selection with a unique ID and initial option
    const handleSelectionAdd = () => {
        setSelections([...selections, { id: Date.now(), options: [1] }]);
    };

    // Add an option to the selected selection
    const handleOptionAdd = (id) => {
        setSelections(selections.map((sel) =>
            sel.id === id
                ? { ...sel, options: [...sel.options, sel.options.length + 1] }
                : sel
        ));
    };
    

    return (
        <>
        <div style={modalStyle} onClick={(e) => {handleDeleteModal(e)}}>
            <div style={modalContent} onClick={handleModalContentClick}>
                <div style={modalWrapper}>         
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px', height: '100%', overflow: 'auto'}}>
                        <div className='modal-header'>Modify part{partNumbers > 1 ? 's' : ''} configuration{partNumbers > 1 ? 's' : ''}</div>
                        <div className='modal-text-content' style={{fontSize: '14px', fontStyle: 'Arial'}}>Modify part configurations here. They will be applied to all the parts selected.</div>
                        
                        <TabNavigation
                            tabs={['Default Properties','Custom Properties']}
                            fontSize='14px'
                            onTabClick={handleTabClick}
                        />
                        <div style={{overflowY: 'auto', scrollbarWidth: 'thin' }}>
                            {activeTabIndex === 0 && (
                                <>
                                    
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', height: '100%' }}>
                                            <div style={{ height: '100%', overflow: 'auto', scrollbarWidth: 'thin' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                    {selections.map((sel) => (
                                                        <div 
                                                            key={sel.id}
                                                            onClick={() => setSelection(sel.id)}
                                                            style={{
                                                                background: selection === sel.id ? 'blue' : 'transparent',
                                                                //padding: '5px',
                                                                cursor: 'pointer',
                                                            }}>
                                                                <ConfigSelection/>
                                                        </div>
                                                        
                                                    ))}
                                                    <button onClick={handleSelectionAdd}>Add More Selection</button>
                                                </div>
                                            </div>
                                                
                                            {/* Options List */}
                                            <div style={{ height: '100%', overflow: 'auto', scrollbarWidth: 'thin' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                    {selections
                                                        .find((sel) => sel.id === selection)
                                                        ?.options.map((option, index) => (
                                                            <div key={index}><ConfigOption/></div>
                                                        ))}
                                                    <button onClick={() => handleOptionAdd(selection)}>Add Option</button>
                                                </div>
                                            </div>
                                        </div>
                                    
                                </>
                            )}
                            {activeTabIndex === 1 && ('test2')}
                        </div>
                    </div>
                    <div style={modalButtons}>  
                      <span className='cancel-button' onClick={handleDeleteModal}>Cancel</span>
                      <span className='delete-button' onClick={handleDelete}>Save</span>
                    </div>
                </div>
            </div>
        </div>
        </>
    )   
}

export default ModifyConfig



//{selections.map((selection, index) => (
//    <ConfigSelection key={index}/>
//))}
//<button onClick={handleSelectionAdd}>add more selection</button>
//</div>
//</div>
//<div style={{height: '100%', overflow: 'auto',scrollbarWidth: 'thin'}}>
//<div style={{ display: 'flex', flexDirection: 'column', gap: '5px'}}>
//{options.map((option, index) => (
//    <ConfigOption key={index} /> // Render ConfigOption for each option
//))}
//<button onClick={handleOptionAdd}>add more option</button>
//</div>
//</div>


//<div style={{borderBottom: '1px #d8dee4 solid', fontSize: '14px', fontStyle: 'Arial'}}>Modify Part Configuration</div>
//
//<div style={{height: '150px', minWidth: '100%', border: isFocused ? 'solid 1px #339AF0' : 'solid 1px #ccc', transition: 'border 0.1s ease-in-out',borderRadius:'8px', boxSizing: 'border-box', display: 'flex',justifyContent: 'center',alignItems: 'center',borderRadius: '8px',}}>
//                            <textarea
//                              value={partDescription}
//                              onChange={handleInputChange}
//                              placeholder="Enter part description"
//                              style={{
//                                width: '100%',
//                                height: '100%',
//                                borderRadius: '8px',
//                                border: 'none',
//                                outline: 'none',
//                                textAlign: 'left',
//                                padding: '8px', // Adds padding around the text
//                                paddingRight: isOverflowing ? '0px' : '8px',
//                                resize: 'none',
//                                scrollbarWidth:'thin',
//                                overflow: isOverflowing ? 'auto' : 'hidden',
//                                fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
//                                fontSize: '14px'
//                              }}
//                              onFocus={() => setIsFocused(true)}
//                              onBlur={() => setIsFocused(false)}
//                              
//                            />
//                        </div>


//const [isOverflowing, setIsOverflowing] = useState(false);
//    const [partDescription, setPartDescription] = useState('');
//    const handleInputChange = (e) => {
//        const element = e.target;
//        setPartDescription(element.value);
//
//        if (element.scrollHeight > element.clientHeight) {
//            setIsOverflowing(true);
//          } else {
//            setIsOverflowing(false);
//          }
//      };
//
//    const [isFocused, setIsFocused] = useState(false);
