import React, { InputHTMLAttributes, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
//import './GenericCheckbox.css';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  
}

const GenericCheckbox: React.FC<CheckboxProps> = ({ label, ...props }) => {
  return (
    <div>
      <input type="checkbox" className="checkbox-input" {...props} id={props.id || label} />
      <label className="checkbox-input-label" htmlFor={props.id || label}>
        <span className="checkbox-icon">
            <FontAwesomeIcon icon={faCheck} style={{padding: 0, height: '10px', width: '10px', color: 'white'}}/>
        </span>
        {label}
      </label>
    </div>
  );
};

export default GenericCheckbox;



