import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

interface FilterState {
  [key: string]: object; // Key-value pair where key is the chip type or identifier
}

interface ChipFilterContextProps {
  filterStates: FilterState;
  setFilterState: (chipType: string, newState: object) => void;
  clearFilterStates: () => void;
  totalFilterStateLength: number;
  prevFilterStates: FilterState;
  setPrevFilterStates: React.Dispatch<React.SetStateAction<FilterState>>
  partType: string;
  setPartType: React.Dispatch<React.SetStateAction<string>>;
  prevPartType: string;
  setPrevPartType: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const ChipFilterContext = createContext<ChipFilterContextProps | undefined>(undefined);

export const ChipFilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filterStates, setFilterStates] = useState<FilterState>({});
  const [prevFilterStates, setPrevFilterStates] = useState<FilterState>({});
  const [totalFilterStateLength, setTotalFilterStateLength] = useState(0)
  const [partType, setPartType] = useState('pressuretransducers')
  const [prevPartType, setPrevPartType] = useState('pressuretransducers')
  const [inputValue, setInputValue] = useState('');
  //const [hasFilterChanged, setHasFilterChanged] = useState(false);


  useEffect(() => {
    // console.log("test", inputValue);
    setFilterState('part_number_supplier', { ...filterStates['part_number_supplier'], input: inputValue })
  }, [inputValue])



  useEffect(() => {
    // console.log(filterStates);
    // console.log(prevFilterStates);
  },[filterStates])

  const setFilterState = useCallback((chipType: string, newState: object) => {
    setFilterStates((prev) => {
      // Ensure prev is always an object
      const previousState = prev || {};
  
      // Ensure newState is always an object
      const currentState = newState || {};
  
      // Remove any keys in the newState object that have empty string values
      const filteredState = Object.fromEntries(
        Object.entries(currentState).filter(([_, value]) => value !== '')
      );
  
      // Check if filteredState is identical to the previous state
      if (Object.keys(filteredState).length === 0) {
        // If the state is empty and is already empty in the previous state, do nothing
        if (!previousState[chipType] || Object.keys(previousState[chipType]).length === 0) {
          return previousState;
        }
  
        // Otherwise, remove the key from filterStates
        const { [chipType]: _, ...rest } = previousState; // Destructure to remove the key
        return rest;
      }
  
      // Only update the state if there is a change
      if (JSON.stringify(previousState[chipType]) !== JSON.stringify(filteredState)) {
        return {
          ...previousState,
          [chipType]: filteredState,
        };
      }
  
      return previousState; // Return previous state if no change
    });
  }, []);
  
  
  

  const clearFilterStates = () => {
   setFilterStates({}) 
  };

 
  useEffect(() => {  
    // Initialize the total count for non-empty properties
    let totalFilteredLength = 0;
    // Iterate over all keys in the parent filterStates object
    Object.values(filterStates).forEach((childState) => {
      if (childState && typeof childState === 'object') {
        // Count the number of non-empty objects within the current child state
        const filteredLength = Object.values(childState).filter((value) => {
          return (
            value && typeof value === 'object' &&
            Object.values(value).some((innerValue) => innerValue !== '' && innerValue !== null)
          );
        }).length;
  
        // Add the filtered length of the current child state to the total count
        totalFilteredLength += filteredLength;
      }
    });
  
    // Set the total filtered length count
    setTotalFilterStateLength(totalFilteredLength);
    // console.log('Total Filtered Length for supplier eq body:', totalFilteredLength);
  }, [filterStates]);

  return (
    <ChipFilterContext.Provider value={{ filterStates, setFilterState, prevFilterStates, setPrevFilterStates, clearFilterStates, totalFilterStateLength, partType, setPartType, prevPartType, setPrevPartType, inputValue, setInputValue }}>
      {children}
    </ChipFilterContext.Provider>
  );
};

export const useChipFilterContext = () => {
  const context = useContext(ChipFilterContext);
  if (!context) {
    throw new Error('useChipFilterContext must be used within a ChipFilterProvider');
  }
  return context;
};
