
import styles from './css/GenericButtons.module.css'
import React, { useState, useEffect, forwardRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContactDetails } from 'index'

function StartNow() {

    const { formData, updateFormData } = useContactDetails();

    return (
        <>
            <div className='flex-row-5' style={{ maxWidth: '400px' }}>
                <GenericInputBox2
                    defaultValue={formData.email}
                    onChange={(newValue) => updateFormData({ email: newValue })}
                    placeholder={'Email Address'}
                />

            </div>
        </>
    )
}

export default StartNow


export function Button3({ label, onClick, padding, borderRadius }) {
    return (
        <>
            <div
                className={`${styles.button3} zp-weight-m nowrap zp-font-m`}
                style={{
                    padding: padding ? padding : '4px 8px',
                    borderRadius: borderRadius ? borderRadius : '4px'
                }}
                onClick={onClick}
            >
                {label}
            </div>
        </>
    )
}

export const GenericInputBox2 = forwardRef(({ id, label, placeholder, labelPosition, defaultValue, disabled, onChange, borderRadius }, ref) => {




    const { setFragmentIndex } = useContactDetails()

    const [inputValue, setInputValue] = useState(defaultValue || '');
    const [isDisabled] = useState(disabled || false);
    const [isFocused, setIsFocused] = useState(false);
    const [showWarn, setShowWarn] = useState(false);
    const navigate = useNavigate();

    function handleNavClick() {
        let targetPath = '';
        targetPath = `/contact`;
        setFragmentIndex(0);
        navigate(targetPath)
    }

    useEffect(() => {
        setInputValue(defaultValue || '');
    }, [defaultValue]);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        if (onChange) {
            onChange(newValue); // Returns an object
        }
    };

    const startNowWrapper = {
        minWidth: '300px',
        border: isFocused ? '1px solid var(--brand-color-1)' : '1px solid #ccc',
        padding: '6px',
        transition: 'border 0.15s ease-in',
        borderRadius: '20px', 
        overflow: 'hidden',
        background: '#f6f9fb'
    }

    const InputStyles = {
        //border: isFocused ? '1px solid var(--brand-color-1)' : '1px solid #ccc',
        border: 'none',
        
        //padding: '8px 12px',
        outline: 'none',
        //transition: 'border 0.15s ease-in',
        //borderRadius: borderRadius ? borderRadius : '4px',
        width: '100%',
        background: '#f6f9fb'
    };

    const LabelInputStyle = {
        fontSize: '14px',
        fontFamily: 'var(--font-family-1)',
        fontWeight: 500,
        marginBottom: labelPosition === 'left' ? '0px' : '5px',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        minWidth: labelPosition === 'left' ? '120px' : '',
    };

    const WarningStyle = {
        display: 'relative',
        top: '0px',
        paddingTop: '5px',
        fontSize: '14px',
        fontFamily: 'var(--font-family-1)',
    };

    return (
        <>
            <div style={{ marginBottom: showWarn ? '0px' : '24px' }}>
                <div className={`${labelPosition === 'left' ? 'flex-row-5' : ''} align-center`} style={startNowWrapper}>
                    <div style={LabelInputStyle}>{label}</div>
                    <div className='flex-row-5' style={{width: '100%'}}>
                        <input
                            id={id ? id : ''}
                            ref={ref ? ref : null}
                            style={InputStyles}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            placeholder={placeholder}
                            value={inputValue}
                            onChange={handleInputChange}
                            disabled={isDisabled}
                        />
                        <Button3
                            label={'Start Now'}
                            onClick={handleNavClick}
                            borderRadius={'20px'}
                        />

                    </div>
                </div>

                {showWarn && <div style={WarningStyle}>Show warning lol</div>}
            </div>
        </>
    );
})
